import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Modal from 'react-modal';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import v01 from '../IMG_MSG/edmsg_eng.jpeg';
import v02 from '../IMG_MSG/edmsg_hindi.jpg';

const EDMessageCard1 = () => {

  const [zoomed, setZoomed] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');

  // Settings for the react-slick slider
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const handleDoubleClick = (e) => {
    e.preventDefault();
    setZoomed(!zoomed);
  };
  
  const openModal = (image) => {
    setModalImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage('');
  };
  return (
    <motion.div
      className="max-w-lg mx-auto bg-gradient-to-r from-blue-500 via-indigo-500 to-purple-500 text-white p-2 rounded-xl shadow-lg  transform transition-all hover:scale-105 duration-500"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
    >
      {/* Header Section */}
      {/* <div className="bg-gradient-to-r from-blue-500 to-purple-600 p-6">
        <h2 className="text-2xl font-bold text-white">Message from the ED</h2>
      </div> */}

      {/* Image Slider */}
      <Slider {...settings} className='mt-0'>
      <div  onDoubleClick={() => openModal(`${v01}`)}>

          <img src={v01} alt="Image 1" 
                                          className={`slider-image ${zoomed ? 'double-zoom' : ''} w-full object-cover h-auto py-4 px-2 rounded-2xl`}
                                          onDoubleClick={() => openModal(`${v01}`)}/>
        </div>
        <div  onDoubleClick={() => openModal(`${v02}`)}>
          <img src={v02} alt="Image 2" className={`slider-image ${zoomed ? 'double-zoom' : ''} w-full object-cover h-auto py-4 px-2 rounded-2xl`}
                                          onDoubleClick={() => openModal(`${v02}`)}/>
        </div>
        
      </Slider>

      {/* Message Section */}
      {/* <div className="p-6">
        <p className="text-gray-700 text-lg leading-relaxed">
          Dear [Recipient Name],
          <br /><br />
          [Your message content goes here.]
          <br /><br />
          Best Regards,
          <br />
          <strong>Executive Director</strong>
        </p>
      </div> */}
      <Modal isOpen={isModalOpen} onRequestClose={closeModal} contentLabel="Zoom Image">
        <div className="flex justify-end">
          <button onClick={closeModal} className="text-red-500">
            Close
          </button>
        </div>
        <div className="flex justify-center">
          <img src={modalImage} alt="Zoomed" className="max-w-full max-h-full" />
        </div>
      </Modal>
    </motion.div>
  );
};

export default EDMessageCard1;
