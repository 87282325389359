import React from 'react';

const FCIObjectives = () => {
    return (
        <div className="bg-gray-100 min-h-screen p-8">
            <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
                <div className="bg-green-700 text-white p-6">
                    <h1 className="text-3xl font-bold text-center uppercase underline">Objectives</h1>
                    <p className="text-center mt-2">
                        Welcome to the official website of Bharatiya Khadya Nigam Karmachari Sangh (BKNKS), Regd. No. 2217/ND,
                        the sole nationally recognized employees' union of the Food Corporation of India.
                        Our objectives are dedicated to enhancing the welfare, rights, and professional growth of all FCI employees.
                    </p>
                </div>
                <div className="p-6 space-y-6 text-gray-800">
                    <div className="space-y-4">
                        <h2 className="text-xl font-semibold">Defend Employee Rights</h2>
                        <p>
                            We vigorously protect and promote the rights and interests of FCI employees at both national, zonal, regional & field levels.
                            This includes engaging in negotiations with FCI management to secure fair wages, benefits, and working conditions for our members.
                            We also influence labour policies to ensure they reflect the needs and welfare of FCI employees.
                        </p>
                    </div>

                    <div className="space-y-4">
                        <h2 className="text-xl font-semibold">Improve Work Environment</h2>
                        <p>
                            We advocate for improvements in workplace conditions to ensure a safe, healthy, and productive work environment for all FCI employees.
                            We work towards implementing policies that benefit the workforce and enhance operational efficiency.
                        </p>
                    </div>

                    <div className="space-y-4">
                        <h2 className="text-xl font-semibold">Comprehensive Welfare Programs</h2>
                        <p>
                            We develop and implement programs that enhance the health, safety, and financial security of the employees.
                            We also provide legal assistance, counselling, and other support services to help members navigate workplace challenges.
                        </p>
                    </div>

                    <div className="space-y-4">
                        <h2 className="text-xl font-semibold">Unity</h2>
                        <p>
                            We build a strong sense of community and solidarity among FCI employees to strengthen our collective voice.
                        </p>
                    </div>

                    <div className="space-y-4">
                        <h2 className="text-xl font-semibold">Promote Equality</h2>
                        <p>
                            We advocate for equal opportunities and fair treatment for all FCI employees, regardless of their background.
                            We uphold and promote ethical practices within both FCI and BKNK Sangh,
                            and implement fair and efficient mechanisms for resolving workplace disputes and grievances.
                        </p>
                    </div>

                    <div className="space-y-4">
                        <h2 className="text-xl font-semibold">Transparent Operations</h2>
                        <p>
                            We ensure that all union activities and decisions are conducted transparently and with accountability to our members.
                            We encourage active member participation in union activities and decision-making processes,
                            and maintain open lines of communication with members by providing regular updates on union activities, negotiations, and achievements.
                        </p>
                    </div>
                </div>
                <div className="bg-green-700 text-white p-6">
                    <p className="text-center">
                        By adhering to these objectives, BKNK Sangh aims to create a fair, supportive, and empowering environment for all FCI employees,
                        ensuring their rights are protected and their professional growth is nurtured.
                        Join us in our mission to build a stronger, more equitable workplace for everyone.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default FCIObjectives;
