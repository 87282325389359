import React, { useState, useEffect,useContext } from 'react';
import axios from 'axios';
import { AuthContext } from './AuthContext';
import { useUser } from './AuthContext';


import './style.css'; // Ensure this file is properly included
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;



const CrudPage = () => {
  const { user } = useUser();

  const [members, setMembers] = useState([]);
  const [filter, setFilter] = useState('');
  const [expandedIndex, setExpandedIndex] = useState(null); // State to manage accordion expansion
  const [selectedPosted, setSelectedPosted] = useState([]);
  const [selectedZone, setSelectedZone] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const { user1, logout } = useContext(AuthContext);
  var adminName = "NA"; // Replace this with actual admin name if available
//const { user: user11 } = useContext(AuthContext);
// Set a default value for adminflag if user11 or adminflag is undefined or null

const adminFlag = user?.adminflag ?? '2';
const names = user?.name ?? 'no name';
const role = user?.role ?? 'normal';
const approveRejectStatus = user?.approvereject_status ?? 'not approved'; // You can set a default status if needed
// alert(role);
  useEffect(() => {
    if(user.name==='Admin'){adminName=user.name;}else{adminName='Na';}
    
    fetchMembers();
  }, []);

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const handleSelectChange = (e, setSelectedFunction) => {
    const value = e.target.value;
    setSelectedFunction(prev => prev.includes(value) ? prev.filter(v => v !== value) : [...prev, value]);
  };

  const filteredData = members.filter(item =>
    item.name.toLowerCase().includes(filter.toLowerCase()) ||
    item.email.toLowerCase().includes(filter.toLowerCase()) ||
    (selectedPosted.length === 0 || selectedPosted.includes(item.posted)) &&
    (selectedZone.length === 0 || selectedZone.includes(item.zone)) &&
    (selectedRegion.length === 0 || selectedRegion.includes(item.region)) &&
    (selectedStatus.length === 0 || selectedStatus.includes(item.approvereject_status)) &&
    (!selectedDate || formatDate(item.created_at) === selectedDate)
  );

  const fetchMembers = async () => {
    try {
     // const token = localStorage.getItem('token');  // Retrieve the token from localStorage
      const role='Admin';
      const response = await axios.get(`${apiBaseUrl}/members`,{role}
      , { withCredentials: true,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-Auth-Password': 'bknksorg@321' // Include the password in the headers
        }
      }
      
      );
      setMembers(response.data);
    } catch (err) {
      alert(err.message, ' , ', err.response.data.message);
    }
  };

  const handleUpdateStatus = async (id, action, action_work, name1, email1, mobile1, mem_id) => {
    try {
      const response = await axios.put(`${apiBaseUrl}/members/${id}/action`, { action, adminName, action_work, name1, email1, mobile1, mem_id });
      if (response.data.success) {
        alert(response.data.message);
        fetchMembers();
      }
    } catch (error) {
      if (error.response && error.response.data) {
        alert(error.response.data.error);
      } else {
        alert('An error occurred.');
      }
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB');
  };

  const toggleAccordion = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({ html: '#members-table' });
    doc.save('members.pdf');
  };

  // const downloadExcel = () => {
  //   const worksheet = XLSX.utils.json_to_sheet(filteredData);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, 'Members');
  //   XLSX.writeFile(workbook, 'members.xlsx');
  // };
  const downloadExcel = () => {
    // Map the filtered data to only include selected fields
    const selectedFields = filteredData.map((member,index) => ({
        SlNo: index+1,
        Date: formatDate(member.created_at),
        Status: member.status,
        Name: member.name,
        EmpNo: member.empno,

        Email: member.email,
        Mobile: member.mobile,
        Gender: member.gender,
        Posted: member.posted,
        Zone: member.zone,
        Region: member.region,
        DO_Depot: member.dodepo,
        ApproveReject_Status: member.approvereject_status,
    }));

    
    // Create worksheet from selected fields
    const worksheet = XLSX.utils.json_to_sheet(selectedFields);

    // Add custom header using sheet_add_aoa
    // XLSX.utils.sheet_add_aoa(worksheet, [["Member Report"]], { origin: 'A1' });
    // XLSX.utils.sheet_add_aoa(worksheet, [["Member Report", "", "", "", "", "", "", "", "", "", ""]], { origin: 'A1' });

    // Merge cells for the title row (optional)
    // worksheet['!merges'] = [
    //     { s: { r: 0, c: 0 }, e: { r: 0, c: 12 } } // Merge A1 to K1
    // ];

    // Format column widths (optional)
    worksheet['!cols'] = [
        { wch: 5 },   // SlNo
        { wch: 12 },  // Date
        { wch: 15 },  // Status
        { wch: 25 },  // Name
        { wch: 25 },  // Email
        { wch: 15 },  // Mobile
        { wch: 10 },  // Gender
        { wch: 12 },  // Posted
        { wch: 12 },  // Zone
        { wch: 12 },  // Region
        { wch: 20 },  // DO_Depot
    ];

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Members');
    // Write the file with a background image (using a 3rd party library like ExcelJS if needed)
    XLSX.writeFile(workbook, 'members.xlsx');
};


  return (
    <div className="  -mt-12 dark:bg-gray-900 transition-colors duration-500 ">
      {user.role === 'Admin' && (
      <div className="flex mt-32 mb-2 bg-transparent flex-col items-center justify-center bg-gray-400">
        <div className="mt-8 p-12 rounded shadow-md w-full max-w-full z-10 mb-8 overflow-x-auto">
          <h2 className="text-xl font-bold mb-4 text-white">Members List</h2>
          
          <div className="mb-4">
            <input
              type="text"
              className="p-2 w-72 border border-gray-300 rounded glass"
              placeholder="Search by name or email"
              value={filter}
              onChange={handleFilterChange}
            />
          </div>

          <div className="mb-4">
            <label>Posted:</label>
            <select onChange={(e) => handleSelectChange(e, setSelectedPosted)}>
              <option value="">All</option>
              <option value="HQ">HQ</option>
              <option value="ZO">ZO</option>
            </select>
            <label>Zone:</label>
            <select onChange={(e) => handleSelectChange(e, setSelectedZone)}>
              <option value="">All</option>
              <option value="Zone1">HQ</option>
              
            </select>
            <label>Region:</label>
            <select onChange={(e) => handleSelectChange(e, setSelectedRegion)}>
              <option value="">All</option>
              {/* <option value="Region1">Region1</option>
              <option value="Region2">Region2</option> */}
            </select>
            <label>Status:</label>
            <select onChange={(e) => handleSelectChange(e, setSelectedStatus)}>
              <option value="">All</option>
              <option value="approved">Approved</option>
              <option value="rejected">Rejected</option>
            </select>
            <label>Created Date:</label>
            <input type="date" onChange={(e) => setSelectedDate(e.target.value)} />
          </div>

          <div className="table-container overflow-y-auto overflow-x-auto max-w-full max-h-full">
            <table id="members-table">
              <thead className="bg-gray-200 sticky top-0 z-10">
                <tr>
                  <th className="p-2 text-left">SlNo.</th>
                  <th className="p-2 text-left">Date</th>
                  <th className="p-2 text-left">Status</th>
                  <th className="p-2 text-left">Name</th>
                  <th className="p-2 text-left">Empno</th>
                  <th className="p-2 text-left">Cat.</th>
                  <th className="p-2 text-left">Cadre</th>
                  <th className="p-2 text-left">Des.</th>
                  <th className="p-2 text-left">Gender</th>
                  <th className="p-2 text-left">Posted</th>
                  <th className="p-2 text-left">Zone</th>
                  <th className="p-2 text-left">Region</th>
                  <th className="p-2 text-left">DO/Depot</th>
                  <th className="p-2 text-left">Email</th>
                  <th className="p-2 text-left">Mobile</th>
                  <th className="p-2 text-left">Profile Photo</th>
                  <th className="p-2 text-left">Member_ID</th>
                  <th className="p-2 text-left">Member_status</th>
                  <th className="p-2 text-left">Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((member, index) => (
                  <React.Fragment key={member.id}>
                    <tr className={`border-b ${(member.approvereject_status).trim() === "disabled" ? 'bg-gray-700' : ''}`}>
                      <td className="p-1">{index + 1}</td>
                      <td className="p-1">{formatDate(member.created_at)} </td>
                      <td className="p-1">{member.status}</td>
                      <td className="p-1"><ExpandableText text={member.name} /></td>
                      <td className="p-1">{member.empno}</td>
                      <td className="p-1">{member.category}</td>
                      <td className="p-1">{member.cadre}</td>
                      <td className="p-1">{member.post}</td>
                      <td className="p-1">{member.gender}</td>
                      <td className="p-1">{member.posted}</td>
                      <td className="p-1">{member.zone}</td>
                      <td className="p-1">{member.region}</td>
                      <td className="p-1">{member.dodepo}</td>
                      <td className="p-1"><ExpandableText text={member.email} /></td>
                      <td className="p-1">{member.mobile}</td>
                      <td className="p-2">
                        <img
                          src={`${apiBaseUrl.substring(0, apiBaseUrl.length - 4)}/uploads/${member.profilePhoto}`}
                          alt="Profile"
                          className="rounded w-16 h-16 object-cover"
                        />
                      </td>
                      <td className="p-1">{member.mem_id}</td>
                      <td className="p-1">{member.approvereject_status}</td>
                      <td className="p-1">
                      <button
                          onClick={() => handleUpdateStatus(member.id, 'approve', member.approvereject_status, member.name, member.email, member.mobile, member.mem_id)}
                          className="bg-green-500 text-white px-1 py-1 rounded glass mr-1"
                        >
                          Approve
                        </button>
                        <button
                          onClick={() => handleUpdateStatus(member.id, 'disabled', member.approvereject_status, member.name, member.email, member.mobile)}
                          className="bg-red-500 text-white px-1 py-1 rounded glass mr-1"
                        >
                          
                          Disabled
                        </button>
                        <button
                          onClick={() => handleUpdateStatus(member.id, 'delete',member.approvereject_status, member.name, member.email, member.mobile)}
                          className="bg-red-500 text-white px-1 py-1 rounded glass"
                        >
                          Delete
                        </button>
                        <button
                          onClick={() => toggleAccordion(index)}
                          className="ml-1 bg-blue-500 text-white px-1 py-1 rounded glass"
                        >
                          {expandedIndex === index ? 'Hide History' : 'Show History'}
                        </button>
                      </td>
                    </tr>
                    {expandedIndex === index && (
                      <tr className="expanded-row">
                        <td colSpan="18" className="p-2">
                          <div className="bg-white p-4 rounded shadow-md">
                            <h3 className="text-lg font-bold mb-2">History Details</h3>
                            <div className="table-container overflow-y-auto overflow-x-auto max-w-full max-h-full">
                              <table className="w-full">
                                <thead className="bg-gray-200">
                                  <tr>
                                    <th className="p-2 text-left">Modified Date</th>
                                    <th className="p-2 text-left">Status</th>
                                    <th className="p-2 text-left">ApproveBy</th>
                                    <th className="p-2 text-left">RejectBy</th>
                                    <th className="p-2 text-left">Modify_At</th>
                                    <th className="p-2 text-left">DeleteRemarks</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {member.history && member.history.length > 0 ? (
                                    member.history.map((history, historyIndex) => (
                                      <tr key={historyIndex} className="border-b">
                                        <td className="p-1">{formatDate(history.created_at)}</td>
                                        <td className="p-1">{history.approvereject_status}</td>
                                        <td className="p-1">{history.approveby}</td>
                                        <td className="p-1">{history.rejectby}</td>
                                        <td className="p-1">{history.modify_at}</td>
                                        <td className="p-1">{history.deleteremarks}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan="6" className="p-1">No history available.</td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>

          <div className="mt-4 flex">
            <button onClick={downloadPDF} disabled className="px-4 py-2 bg-red-500 text-white rounded">
              Download PDF
            </button>
            <button onClick={downloadExcel} className="ml-2 px-4 py-2 bg-green-500 text-white rounded">
              Download Excel
            </button>
          </div>
        </div>
      </div>
            )}

    </div>
  );
};

const ExpandableText = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 20; // Set a limit for the text length before truncation

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  
  return (
    <div onClick={handleToggleExpand} className="cursor-pointer text-left text-wrap">
      {isExpanded ? text : `${text.slice(0, maxLength+5)}${text.length > maxLength+5 ? '..' : ''}`}
      {text.length > maxLength+5 && (
        <span className="text-blue-500 ml-2">
          {isExpanded ? ' Less' : ' More'}
        </span>
      )}
    </div>
  );
};

export default CrudPage;
