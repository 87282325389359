import React, { useState, useEffect, useContext  } from 'react';
import { Navbar, Nav, Form, FormControl, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import NavDropdown from "react-bootstrap/NavDropdown";

// import { AuthContext } from './components/AuthContext';
// import { Navigate } from 'react-router-dom';


import { Link } from 'react-router-dom';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
//import { useAuth } from './components/AuthContext';
import { FiCircle, FiSearch, FiSun } from 'react-icons/fi';
import { AuthContext } from './AuthContext';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faContao, faYoutubeSquare, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import bg from './logo.png';
import styles from './Navbar.module.css'; // Import CSS Module
import ProfilePage from './ProfilePage';
import { useUser } from './AuthContext';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const NavbarComponent1 = ({ darkMode, setDarkMode }) => {
  const [isOpen, setIsOpen] = useState(false);
  // const { user, logout } = useAuth();
  // const { user, logout } = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const { user, logout } = useContext(AuthContext);
  // const { user: user11 } = useContext(AuthContext);
  const { user11 } = useUser();


  // Set a default value for adminflag if user11 or adminflag is undefined or null
const adminFlag = user11?.adminflag ?? 2;
const approveRejectStatus = user11?.approvereject_status ?? 'not approved';
const names = user11?.name ?? 'no name';
const role = user11?.role ?? 'normal';


  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const [scrolled, setScrolled] = useState(true);
  const navigate = useNavigate();

  const handleProfileClick = () => {
     const userData = {
      // id: 3,
      // status: '1',
      // empno: 111124,
      // name: 'Admin',
      // posted: 'NA',
      // zone: 'HQ',
      // region: 'HQ',
      // dodepo: 'NA',
      // mobile: '1111111111',
      // email: 'fcibknk@gmail.com',
      adminflag: adminFlag,
      approvereject_status: approveRejectStatus,
      // profilePhoto: null,
    };
    
    navigate('/profile', { state: { userData } });
  };
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const offset = window.scrollY;
  //     setScrolled(offset > 2000);
  //   };

  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  // useEffect(() => {
  //   notify();
  // }, []);
  // const notify = () =>
  //   toast.success("Logged in Successfully.", {
  //     position: toast.POSITION.TOP_CENTER,
  //     autoClose: 3000,
  //   });
  // const { user1 } = useContext(AuthContext);
 
      

  // if (user1.adminflag === 1) {
  //   return (
  //     <div>
  //       <h1>Admin Panel</h1>
  //       <ul>
  //         <li><a href="/admin-dashboard">Dashboard</a></li>
  //         <li><a href="/admin-settings">Settings</a></li>
  //       </ul>
  //     </div>
  //   );
  // }

  // if (!user) {
  //   alert(user.name);
    // return <Navigate to="/" />;
  return (
    // <Navbar expand="lg" className={'bg-transparent fixed-top '}>
    <Navbar expand="lg" className={`border-b border-orange-300 items-center mt-10 mb-0 bg-top`} style={{ backgroundColor: '#e0e5ec' }}>



    {/* // user verified */}

    

   

    <div className="container-fluid">
        <Navbar.Brand  className="hover:animate-moveBackground text-sm text-gray-700 no-underline">
        <a href="https://www.facebook.com/share/Fu26WKtXwvC6ovYS/?mibextid=qi2Omg" target="_blank" rel="noopener noreferrer" className="hover:text-blue-500 no-underline">
                <FontAwesomeIcon icon={faFacebook} size="1x" /> &nbsp;&nbsp;
              </a>
              <a href="https://twitter.com/Bknk_sangh" className="hover:text-blue-500 no-underline" target="_blank" rel="noopener noreferrer">
    <FontAwesomeIcon icon={faXTwitter} size="1x" /> &nbsp; &nbsp;
</a>
<a href="https://www.instagram.com/bknksanghfci?igsh=MXh3dGUwd3RuNjhndg==" target="_blank" rel="noopener noreferrer" className="hover:text-blue-500 no-underline">
                <FontAwesomeIcon icon={faInstagram} size="1x" />
              </a>
              <a href="https://youtube.com/@tradeunionpulse?si=p3UZhvbunv7EsG93" className="hover:text-blue-500 no-underline mx-3">
                <FontAwesomeIcon icon={faYoutubeSquare} size="1x" />
              </a>
       {/* <p className="text-sm text-center mt-2"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Registration No.2217/ND</p> */}
        </Navbar.Brand>
       
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={toggleMenu}
          className={styles.toggleButton}
        >
          {isOpen ? (
            <AiOutlineClose className={`${styles.icon} ${styles.iconOpen}`} />
          ) : (
            <AiOutlineMenu className={`${styles.icon} ${styles.iconClosed}`} />
          )}
        </Navbar.Toggle>

        <Navbar.Collapse id="basic-navbar-nav" className={`${isOpen ? 'show' : ''}`}>
          <Nav className="px-0 me-auto" >
            {/* {user1.adminflag===1  || user1.adminflag === 2 || user1.adminflag === 3 ? ( */}
            {/* {user && user.adminflag === 1 && ( */}
            {/* <NavDropdown   title="Admin" id="basic-nav-dropdown" className='text-white transition duration-300 ease-in-out transform hover:scale-105 hover:glass' style={{ color: 'white'  }}>
            </NavDropdown> */}
            {/* )} */}
             {/* ) : ('')} */}
          </Nav>
          
          
          {user ? (
            // <div className="flex items-center space-x-4">
            //   <img src={user.profileImage} alt="User" className="w-8 h-8 rounded-full" />
            //   <Button variant="outline-light" onClick={logout}>
            //     Logout
            //   </Button>
            // </div>
            <>
            <Navbar.Text className="text-sm text-gray-700">
          <div className = " items-center  px-4 py-0  text-gray-700">
          
            {(user.profilePhoto)?(
               <img src= {`${apiBaseUrl.substring(0, apiBaseUrl.length - 4)}/uploads/${user.profilePhoto}`} alt=""  className="pt-0 py-0 w-10 h-8 rounded-full object-cover" />):(''
            )
            }
            
             </div>
             </Navbar.Text>
            <NavDropdown title={user.name} id="basic-nav-dropdown" className="text-gray-700 flex mr-2">
              
              {/* <NavDropdown.Item as={Link} to='profile' className='text-gray-700 no-underline hover:bg-red-600 rounded-md hover:text-white hover:shadow-lg hover:shadow-green-300'>Profile</NavDropdown.Item> */}

              <NavDropdown.Item
                onClick={handleProfileClick}
                className="text-gray-700 no-underline hover:bg-red-600 rounded-md hover:text-white hover:shadow-lg hover:shadow-green-300"
              >
                Profile
              </NavDropdown.Item>

              <NavDropdown.Item onClick={logout} className='text-gray-700 no-underline hover:bg-red-600 rounded-md hover:text-white hover:shadow-lg hover:shadow-green-300'>Logout</NavDropdown.Item>
            </NavDropdown>       
              </>
          ) : (
            // <Button as={Link} to="/login" variant="outline-light" className="ms-2">
            //   Login
            // </Button>
             <Nav.Link as={Link} to="/login" className="text-green-800 mr-1 outline-success no-underline rounded-md hover:text-red-600 hover:shadow-lg hover:shadow-green-300">
              Login
            </Nav.Link>
            
          )}
          
          <Nav.Link as={Link} to="/jf" className="text-green-800 mr-1 mx-1 no-underline rounded-md hover:text-red-600 hover:shadow-lg hover:shadow-green-300 outline-success">
            Register
          </Nav.Link>
        </Navbar.Collapse>
        
            {/* <Nav.Link as={Link} to="/jf" className="text-green-800 mr-1 mx-1 no-underline rounded-md hover:text-red-600 hover:shadow-lg hover:shadow-green-300 outline-success">
            Register
          </Nav.Link> */}
      </div>
    </Navbar>
  );
  // return <div>Loading...</div>;

  // }


};

export default NavbarComponent1;
