import React, { createContext, useState, useEffect } from 'react';
import Header1 from './Header1';
import NewsList from './NewsList';
import FeaturePage from './FeaturePage';
import GoalsPage from './GoalsPage';
import AboutUs from './Aboutus';
import NewsSwiper from './NewsSwiper';
import PartyEvents from './PartyEvents';
import LatestNews from './LatestNews';
import WeatherCard from './WeatherCard';
import NewsList1 from './NewsList1';
import DateCalendarCard from './DateCalendarCard';
import JoinForm from './JoinFrom';
import LatestNews1 from './LatestNews1';
import GallerySlider from './GallerySlider';
import YouTubeSlider from './YouTubeSlider';
import Bulletindemand from './Bulletindemand';
import Gallery from './Gallery';
import ImageSlider from './ImageSlider';
import Header_main from './Header_main';
import Team from './Team';
import GoogleMap from './GoogleMap';
import Visit from './Visit';
import NewsletterForm from './NewsletterForm';
import NavbarComponent from './NavbarComponent';
import Separator from './Separator';
import Meeting from './Meeting';

import Bulletin_final from './Bulletin_final';
import { motion, useScroll } from "framer-motion";
import HomePage from './HomePage';
import MeetingManager from './MeetingManager';
import Latestnews2_accordion from './Latestnews2_accordian';
import HeroSection1 from './HeroSection1';
import HeroSection from './HeroSection';
import HeaderSlider from './HeaderSlider';
import HeroSection2 from './HeroSection2';import Meeting_big from './Meeting_big';
import Loader from './Loader'; // Your loader component
import EDMessageCard from './EDMessageCard';
import EDMessageCard1 from './EDMessageCard1';
import Dashboard from './Dashboard';



const Home = () => {
  const channelId = 'UC684sRYvj2Yl4d00gAtl0Jg'; // Replace with your YouTube channel ID
  const apiKey = 'AIzaSyCRptM-DNzdOnnLrXYrSc2_EfS1ACfSsno'; // Replace with your YouTube Data API key


  const images = [
    '/veteran/1(1).jpeg',
    '/veteran/1 (2).jpeg',
    '/veteran/1 (3).jpeg',
    '/veteran/1 (4).jpeg',
    '/veteran/1 (5).jpeg',
    '/veteran/1 (6).jpeg',
    '/veteran/1 (7).jpeg',
    '/veteran/1 (8).jpeg',
    '/veteran/1 (9).jpeg',
    '/veteran/1 1 (11).jpeg',
  
  ];

  const { scrollYProgress } = useScroll();
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);
    setTimeout(() => setLoading(false), 1000); // Simulate loading time
  };
  return (
    <div>
              {loading && <Loader />}
              {/* <Dashboard/> */}

      {/* <motion.div
    className="progress-bar"
    style={{ scaleX: scrollYProgress }}
  /> */}
      {/* <Header1 className=" text-white text-center py-1 bg-transparent container "/> */}
      {/* <h1 className="text-5xl font-bold">Welcome to Our News Site</h1>Upcoming Events by BKNKs */}
      {/* <p className="text-2xl mt-4">Stay updated with the latest news</p> */}
      {/* <Marquee /> */}
      {/* <main className="p-0 bg-gradient-to-r from-blue-500 to-purple-600 glass"> */}
      <HeroSection2 onClick={handleClick}/>
      {/* <EDMessageCard/> */}
      {/* <HeroSection1/> */}
      <br/>
      {/* <HeroSection/> some features */}
      <br/>
      {/* <HeaderSlider/> */}
      {/* <Header1/> */}
      {/* <Header_main/> */}
      <main className="p-0">
      {/* <!-- Stylish Break --> */}
        
        {/* Limit to top 4 news */}
        {/* <MovableSection /> */}
        {/* <FeaturePage/> */}
        {/* <HomePage/> */}

       
        <Latestnews2_accordion onClick={handleClick}/>
        {/* <LatestNews1/> */}
        
        <br/>
        {/* <EDMessageCard1/> */}

<br/><br/>
<br/>
        <Meeting_big onClick={handleClick}/>
<br/>
<br/><br/>
<br/>
        <Bulletin_final onClick={handleClick}/>
<br/>
<br/><br/>
<br/>
        <YouTubeSlider channelId={channelId} apiKey={apiKey} onClick={handleClick} />
        
        {/* <LatestNews limit={5}/> */}
        {/* <Bulletindemand/> */}
        {/* <NewsList limit={4} />  */}
        {/* <GallerySlider images={images} /> */}
        <br/>
<br/>
        <ImageSlider onClick={handleClick}/>
        <br/>
<br/>
        <Team onClick={handleClick}/>
        <Separator/>
        <br/>
<br/>
        <GoogleMap/>
        <br/>
<br/>
        {/* <NewsletterForm/> */}
        {/* <Visit/> */}
        {/* <Gallery/> */}
        {/* <GoalsPage/> */}
        {/* <NewsSwiper /> */}
        {/* <PartyEvents/> */}
        {/* <GoalsPage/> */}
        {/* <WeatherCard/> */}
        {/* <NewsList1/> */}
        {/* <NewsList/> */}
        {/* <DateCalendarCard/> */}
        {/* <AboutUs/> */}
        {/* <JoinForm/> */}
        {/* <JoinPage/> */}
      </main>
      
    </div>
    
  );
}

export default Home;
