import React, { useState } from 'react';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Link } from 'react-router-dom';
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const NewsForm = () => {
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    url: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleContentChange = (content) => {
    setFormData({
      ...formData,
      content
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiBaseUrl}/news`, formData);
      alert(response.data.message);
      setFormData({ title: '', content: '' , url:'BKNKS.ORG'});
    } catch (error) {
      console.error('There was an error submitting the form!', error);
    }
  };

  return (
    <div className=" mt-20 glass max-w-lg mx-auto bg-white p-8 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6">Submit News</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
            Title
          </label>
          <input
            className="w-full h-max scroll-m-1  px-3 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-blue-500"
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="content">
            Content
          </label>
          <ReactQuill
            theme="snow"
            value={formData.content}
            onChange={handleContentChange}
            className="bg-white"
            required
          />
        </div>
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg mt-6 hover:bg-blue-700 focus:outline-none"
        >
          Submit
        </button>

        <Link to="/nedit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 my-1 mb-1 px-1 rounded focus:outline-none focus:shadow-outline"
            type="button"
          >Click Here To Edit Latest News</Link>
      </form>
    </div>
  );
};

export default NewsForm;
