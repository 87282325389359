import React from 'react';
import { motion } from 'framer-motion';
import { FaUserCircle } from 'react-icons/fa';

const ProfilePage = ({ user11 }) => {
  const {
    name,
    empno,
    mobile,
    email,
    posted,
    zone,
    region,
    dodepo,
    approvereject_status,
    profilePhoto,
  } = user11;
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-green-400 to-blue-500">
      <motion.div
        className="w-full max-w-md p-6 bg-white rounded-lg shadow-lg"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, ease: 'easeOut' }}
      >
        <div className="flex items-center justify-center mb-4">
          {profilePhoto ? (
            <img
              src={profilePhoto}
              alt="Profile"
              className="w-24 h-24 rounded-full border-4 border-gray-200 shadow-md"
            />
          ) : (
            <FaUserCircle className="w-24 h-24 text-gray-300" />
          )}
        </div>
        <h2 className="text-3xl font-bold text-center text-gray-800">
          {name}
        </h2>
        <p className="text-lg text-center text-gray-600">Emp No: {empno}</p>
        <motion.div
          className="mt-6 space-y-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5, ease: 'easeOut' }}
        >
          <div className="flex justify-between">
            <span className="font-semibold text-gray-700">Mobile:</span>
            <span className="text-gray-700">{mobile}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-semibold text-gray-700">Email:</span>
            <span className="text-gray-700">{email}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-semibold text-gray-700">Posted:</span>
            <span className="text-gray-700">{posted}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-semibold text-gray-700">Zone:</span>
            <span className="text-gray-700">{zone}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-semibold text-gray-700">Region:</span>
            <span className="text-gray-700">{region}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-semibold text-gray-700">Joining Date:</span>
            <span className="text-gray-700">{dodepo}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-semibold text-gray-700">Approval Status:</span>
            <span className={`font-semibold ${approvereject_status === 'approved' ? 'text-green-500' : 'text-red-500'}`}>
              {approvereject_status} 
            </span>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default ProfilePage;
