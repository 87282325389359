import React, { useEffect, useState } from 'react';
import axios from 'axios';
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const DonationList = () => {
  const [donations, setDonations] = useState([]);

  useEffect(() => {
    const fetchDonations = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/donations`);
        setDonations(response.data);
      } catch (error) {
        console.error('There was an error fetching the donations:', error);
      }
    };

    fetchDonations();
  }, []);

  return (
    <div className="max-w-4xl mx-auto p-44">
      <h2 className="text-2xl font-bold mb-4 text-white">Donations Received</h2>
      <div className="space-y-4">
        {donations.map((donation) => (
          <div
            key={donation.id}
            className="p-4 bg-white rounded-lg shadow-md"
          >
            <p><strong>Donor:</strong> {donation.donor_name}</p>
            <p><strong>Email:</strong> {donation.email}</p>
            <p><strong>Amount:</strong> Rs.{donation.amount}</p>
            <p><strong>Message:</strong> {donation.message}</p>
            {donation.receipt_url && (
              <p>
                <strong>Receipt:</strong>{' '}
                <a
                  href={donation.receipt_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 underline"
                >
                  View Receipt
                </a>
              </p>
            )}
            <p><strong>Received At:</strong> {new Date(donation.created_at).toLocaleString()}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DonationList;
