import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useUser } from './AuthContext';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const NewsUpload = () => {
  const { user } = useUser();

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [images, setImages] = useState([]);

  const handleImageChange = (e) => {
    setImages(e.target.files);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('title', title);
    formData.append('content', content);
    for (let i = 0; i < images.length; i++) {
      formData.append('images', images[i]);
    }

    axios.post(`${apiBaseUrl}/news`, formData)
      .then(response => {
        alert('News and images uploaded successfully!');
      })
      .catch(error => {
        console.error('Error uploading news:', error);
      });
  };

  return (
    <Form onSubmit={handleSubmit} className='py-44 px-24'>
      <Form.Group controlId="title">
        <Form.Label>Title</Form.Label>
        <Form.Control
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
      </Form.Group>
      <Form.Group controlId="content">
        <Form.Label>Content</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          value={content}
          onChange={(e) => setContent(e.target.value)}
          required
        />
      </Form.Group>
      <Form.Group controlId="images">
        <Form.Label>Images</Form.Label>
        <Form.Control
          type="file"
          multiple
          onChange={handleImageChange}
        />
      </Form.Group>
      <Button variant="primary" type="submit">
        Upload
      </Button>
    </Form>
  );
};

export default NewsUpload;
