import React, { useState, useEffect } from 'react';
import axios from 'axios';

const WeatherCard = () => {
  const [weather, setWeather] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchLocation = async () => {
    try {
      const response = await axios.get('https://ipapi.co/json/'); // or any other IP geolocation API
      return response.data.city;
    } catch (err) {
      console.error('Error fetching location:', err);
      return 'New Delhi'; //  if by chnace fallback city if location fetch fails by default Newdelhi i set any city
    }
  };

  useEffect(() => {
    const fetchWeather = async () => {
      const city = await fetchLocation();
      try {
        const response = await axios.get(
          `https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=f9f40b875f01c61544c034fadf1b4fc3&units=metric`
        );
        setWeather(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchWeather();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="bg-gradient-to-r from-blue-500 via-indigo-500 to-purple-500 text-white p-4 mx-4 rounded-lg shadow-md">
      <h2 className="text-xl font-bold">{weather.name}</h2>
      <p className="text-sm">{weather.weather[0].description}</p>
      <div className="flex justify-between items-center">
        <span className="text-4xl">{Math.round(weather.main.temp)}°C</span>
        <img
          src={`https://openweathermap.org/img/wn/${weather.weather[0].icon}@2x.png`}
          alt="Weather Icon"
        />
      </div>
      <div className="flex justify-between mt-2">
        <span>Humidity: {weather.main.humidity}%</span>
        <span>Wind: {weather.wind.speed} m/s</span>
      </div>
    </div>
  );
};

export default WeatherCard;
