// CustomImageCard.js
import React from 'react';
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const CustomImageCard = ({ imageSrc, title, description, id, length }) => {
    //alert(imageSrc, title);
  return (
    <div className="mx-auto bg-transparent ">
      {/* <div className="bg-transparent rounded-full glass w-auto h-auto">        <img
          src={`${apiBaseUrl.substring(0, apiBaseUrl.length - 4)}/uploads/${imageSrc}`}
          alt={title}
          className="h-48 object-cover glass bg-transparent  mx-auto mt-4"
        />
      </div> */}
       <div className="bg-transparent rounded-full mx-auto w-60 h-60">
        <img
          src={`${apiBaseUrl.substring(0, apiBaseUrl.length - 4)}/uploads/${imageSrc}`}
          alt={title}
          className="w-full h-full object-cover  bg-transparent rounded-full mx-auto mt-0"
        />
      </div>
      <div className="mt-4 text-center " >
        <h2 className="text-sm font-semibold text-blue-700 capitalize ">{title}</h2>
        <p className="text-blue-700 text-sm font-normal capitalize">{description}</p>
        <p className="text-blue-700 text-sm font-normal">{id+1} of {length+1}</p>

      </div>
    </div>
  );
};

export default CustomImageCard;
