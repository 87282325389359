import React from 'react';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const HeaderSlider = () => {
  return (
    <div className="relative">
      <Carousel
        autoPlay
        infiniteLoop
        showThumbs={false}
        showStatus={false}
        interval={5000}
        className="max-h-screen"
      >
        <div className="relative">
          <img src="../banner2a.jpeg" alt="Slide 1" className="object-cover h-screen w-full" />
          <div className="absolute inset-0  flex items-center justify-center">
            <div className="text-center text-white">
              <h2 className="text-4xl font-bold"> Heading 1</h2>
              <p className="mt-4 text-lg"> description for slide 1.</p>
              <Link to="/your-link" className="mt-6 inline-block bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded">
                Learn More
              </Link>
            </div>
          </div>
        </div>

        <div className="relative">
          <img src="../banner3a.jpeg" alt="Slide 2" className="object-cover h-screen w-full" />
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="text-center text-white">
              <h2 className="text-4xl font-bold">Your Heading 2</h2>
              <p className="mt-4 text-lg">Your description for slide 2.</p>
              <Link to="/your-link" className="mt-6 inline-block bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded">
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default HeaderSlider;
