import React from 'react';
import { motion } from 'framer-motion';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import 'tailwindcss/tailwind.css';
import v1 from '../ABOUT.png';

const AboutUs1 = () => {
    return (
        <div className="container mx-auto mt-16 mb-8 px-4 bg-gray-900 text-white">
            <motion.h1
                className="text-4xl font-bold text-center mb-12 underline decoration-indigo-500"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
            >
                About Us
            </motion.h1>

            <div className="flex flex-col items-center">
                <motion.p
                    className="text-lg text-center max-w-3xl leading-relaxed mb-8"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                >
                Welcome to BKNK Sangh, the sole All India recognized union of the Food Corporation of India. Established with the mission of advocating and thus securing rights of its members, BKNK Sangh stands as a pillar of support and representation for the workforce within FCI.                </motion.p>
                <motion.img
            src={v1}
            alt=""
            // className="w-72 h-72 mb-2"
            className="mb-1 w-3/5 h-3/5"
            initial={{ opacity: 1, x: -50, y:0 }}
            whileInView={{ opacity: 1, x:0}}
            transition={{ duration: 1}}
            
          />
                <div className="w-full max-w-4xl ">
                    <Accordion>
                        <AccordionItem header="Our Mission" className="border-b border-gray-700 py-4 ">
                            <div className="text-lg font-semibold cursor-pointer hover:text-indigo-400 ">
                            </div>
                            <div className="mt-2 text-base leading-relaxed text-justify">
                            Our consortium is committed to fostering an equitable work environment for all employees of the Food Corporation of India. We are dedicated to ensuring that every employee's voice is heard and that their contributions are valued. Our primary mission is to protect the rights of our members, advocate for fair wages, and work towards improving working conditions through effective collective bargaining.                            </div>
                        </AccordionItem>

                        <AccordionItem header="Our Vision" className="border-b border-gray-700 py-4">
                            <div className="text-lg font-semibold cursor-pointer hover:text-indigo-400">
                            </div>
                            <div className="mt-2 text-base leading-relaxed text-justify">
                            To create unified and empowered workforce that drives the success of the Food Corporation of India. It aims at building a frictionless future where every employee could feel respected, secured, and motivated to achieve excellence in their roles. Through solidarity and mutual support, it envisages a thriving community where the well-being of each and every member is prioritized.                            </div>
                        </AccordionItem>

                        <AccordionItem header="Fundamentals of BKNKS" className="border-b border-gray-700 py-4">
                            <div className="text-lg font-semibold cursor-pointer hover:text-indigo-400">
                            </div>
                            <div className="mt-2 text-base leading-relaxed text-justify">
                            BKNKS operates with a commitment to represent the working class at every stratum, from Headquarters to Zones, Zones to Regions, and region to Divisional Offices and Depots. Our core principles include:                                <ul className="list-disc list-inside mt-4">
                                    <li>Comprehensive Representation: Ensures a cumulative representation of working class.</li>
                                    <li>Improved Working Conditions: BKNKS is dedicated to overhaul the working conditions of employees, providing a protective shield for their service conditions.</li>
                                    <li>Healthy Industrial Relations: Focusses to balance and improve the industrial relations between management and workers through effective dialogue and negotiation.</li>
                                    <li>Proactive Collective Bargaining: Aims on strong collective bargaining to secure better wages and working conditions for our members.</li>
                                    <li>Awareness and Education: BKNKS conduct workshops and various meetings across the country from our representatives to educate our members about their rights and responsibilities, as well as to enhance their skills and knowledge.</li>
                                    <li>Safeguard Rights and Well-being: BKNKS cooperates with workers to understand their rights, ensuring a safe and healthy work environment which is free from harassment and any kind of Partnership. </li>
                                </ul>
                                <p>In essence, BKNKS is dedicated to protect, maintain and improve the economic and social interests of employees. Our role extends beyond safeguarding socio-economic interests; we also strive to maintain harmonious relations between workers and management. A prime example of our collective bargaining success was demonstrated during the wage revision negotiations from 2017 to 2019, which resulted in the most significant wage hike among all Indian corporations and central public sector units (CPUs).</p>
                            </div>
                        </AccordionItem>

                        

                        <AccordionItem header="Our Leadership" className="border-b border-gray-700 py-4">
                            <div className="text-lg font-semibold cursor-pointer hover:text-indigo-400">
                            </div>
                            <div className="mt-2 text-base leading-relaxed text-justify">
                            Our union is led by a team of experienced and committed leaders who are passionate about labour rights and the welfare of our members. They bring a wealth of knowledge and expertise to the table, ensuring that BKNK Sangh remains a strong and effective advocate for all FCI employees. The current leadership of BKNKS includes Shri Sama Satish Reddy as the National President and Shri Zaheer Ahmad as the General Secretary. Under their guidance, BKNKS remains a powerful advocate for FCI employees, upholding its commitment to secure and advance their rights and interests.                                {/* List leaders and their roles */}
                            </div>
                        </AccordionItem>

                        <AccordionItem header="Join Us" className="border-b border-gray-700 py-4">
                            <div className="text-lg font-semibold cursor-pointer hover:text-indigo-400">
                            </div>
                            <div className="mt-2 text-base leading-relaxed text-justify">
                            If you are an employee of Cat-II, Cat-III & Cat-IV under the Pay Roll of the Food Corporation of India, BKNKS invites you to join and become a part of a dynamic and supportive community as per the section 6 (e) of the Trade Union Act 1926 and Clause 6 (a,b,c & e) of the Bye Laws duly registered by the Registrar, New Delhi. Together, we can achieve greater heights and ensure a better future for all FCI employees.                            </div>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
        </div>
    );
};

export default AboutUs1;
