// Loader.js
import React from 'react';
import { Circles, Oval } from 'react-loader-spinner';

const Loader = () => {
  return (
    <div className="loader-container">
      <Oval 
        height={80}
        width={80}
        color="#ff8c00"
        ariaLabel="loading"
      />
      {/* <Circles
      
      height={80}
      width={60}
      color="#4fa94d"
      ariaLabel="loading"
    /> */}
          </div>

  );
};

export default Loader;
