import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'tailwindcss/tailwind.css';
import { motion } from 'framer-motion';
import bg1_section from '../loginbg.jpg';
import pdfIcon from '../logo.png';
import pdfIcon1 from '../pdf.jfif';

import { Link } from 'react-router-dom';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const Bulletin_final_big = () => {
    const [bulletins, setBulletins] = useState([]);
    const [newBulletin, setNewBulletin] = useState({
      date: '',
      bulletin_name: '',
      pdf_pathtitle: ''
    });
    const [newMinutes, setNewMinutes] = useState(['']);
    const [newPdfPaths, setNewPdfPaths] = useState(['']);
    const [editingBulletinId, setEditingBulletinId] = useState(null);
  
    useEffect(() => {
      fetchBulletins();
    }, []);
  
    const fetchBulletins = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/bulletins`);
        const sortedBulletins = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setBulletins(sortedBulletins);
      } catch (error) {
        console.error('Error fetching bulletins data', error);
      }
    };
  

    // accordian view
    const [expandedIndex, setExpandedIndex] = useState(null);

    // Function to toggle the expanded state
    const toggleExpand = (index) => {
      if (expandedIndex === index) {
        setExpandedIndex(null); // Collapse if already expanded
      } else {
        setExpandedIndex(index); // Expand the selected item
      }
    };
    
    // const PdfIcon = () => (
    //   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-6 w-6 text-red-600">
    //     <path d="M13 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zM5 1a4 4 0 0 0-4 4v14a4 4 0 0 0 4 4h8a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5zm5 4H6v4h4V5zm1 4h4v2h-4V9zM6 13h4v2H6v-2zm0 4h4v2H6v-2zm8-4h-4v2h4v-2zm0 4h-4v2h4v-2z"/>
    //   </svg>
    // );
    
    return (
      <div className="container mx-auto py-24 pb-24 -mt-4 z-8 pr-24 max-w-full h-auto bg-center " style={{ backgroundImage: `url(${bg1_section})`,backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} >
        <br/><br/>
        <br/>
        <motion.h1
          initial={{ opacity: 0, x: -100, y: 0 }}
          whileInView={{ opacity: 1, x: 0 }}
          whileHover={{ x: -10 }}
          transition={{ duration: 1 }}
          className="space-y-2"
        >
          <h1 className="text-xl font-bold font-serif w-4/4 mb-4 text-wrap text-center text-white uppercase">
            Demands and Proposals for the welfare of the employees submitted by BKNK Sangh to the Management of FCI
          </h1>
        </motion.h1>
        
        <caption class="caption-top text-white">
    <Link as={Link} to="/Demands" className='text-white no-underline'>BKNKS&nbsp;/&nbsp;Demands</Link>
    <br/>
  </caption>
  <div className="overflow-x-auto glass overflow-y-auto space-y-8 p-8">
      {bulletins.map((bulletin, index) => (
        <motion.div
          key={bulletin.id}
          className="bg-white shadow-lg rounded-lg overflow-hidden transform transition-all duration-300 hover:scale-105"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <div className="p-4 border-b border-gray-200 flex justify-between items-center">
            <div>
              <h3 className="text-lg font-semibold text-green-600">                      
              <img src={pdfIcon} alt="PDF Icon" className="inline-block h-7 w-7 mr-2" />{bulletin.date}</h3>
              <h4 className="text-sm text-gray-600">             
               <h3 className="text-lg font-semibold text-green-600 underline">    Demands </h3>                  

{bulletin.bulletin_name}</h4>
            </div>
            <button
              className="text-blue-500 focus:outline-none"
              onClick={() => toggleExpand(index)}
            >
              {expandedIndex === index ? 'Hide Details' : 'View Details'}
            </button>
          </div>
          {expandedIndex === index && (
            <div className="p-4">
              <div className="mb-2">
                <a href={bulletin.pdf_pathtitle} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                  <img src={pdfIcon1} alt="PDF Icon" className="inline-block h-6 w-6 mr-2" />
                  View PDF ( Proposals )
                </a>
              </div>
              <h4 className="font-semibold mb-2 text-green-700 underline">Actions</h4>
              <ul className="list-disc hover:list-decimal pl-5 text-green-800">
                {bulletin.minutes_of_bulletin.map((minutes, idx) => (
                  <li key={idx} className="mb-1">
                    <a href={bulletin.pdf_paths[idx]} target="_blank" rel="noopener noreferrer" className="text-blue-800 hover:underline">
                      
                      {minutes}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </motion.div>
      ))}
    </div>

        {/* card view */}
        {/* <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 p-4">
  {bulletins.map((bulletin, index) => (
    <motion.div 
      key={bulletin.id}
      className="bg-white shadow-lg rounded-lg overflow-hidden transform transition-all duration-300 hover:scale-105"
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div className="p-4 border-b border-gray-200">
        <h3 className="text-lg font-semibold mb-2">#{index + 1} {bulletin.bulletin_name}</h3>
        <p className="text-gray-600 mb-4">{bulletin.date}</p>
        <img src={pdfIcon} alt="PDF Icon" className="h-6 w-6 inline-block" />
        <a href={bulletin.pdf_pathtitle} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline ml-2">
          View PDF
        </a>
      </div>
      <div className="p-4">
        <h4 className="font-semibold mb-2">Minutes</h4>
        <ul className="list-disc pl-5 text-green-800">
          {bulletin.minutes_of_bulletin.map((minutes, idx) => (
            <li key={idx} className="mb-1">
              <a href={bulletin.pdf_paths[idx]} target="_blank" rel="noopener noreferrer" className="text-blue-800 hover:underline">
                {minutes}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </motion.div>
  ))}
</div> */}
{/* // card view end */}

{/* accordian views */}

{/* End accordian views */}
{/* tabbed interface */}
{/* <div className="p-4">
  <div className="flex border-b border-gray-300 mb-4">
    <button className="p-2 text-blue-600 border-b-2 border-blue-600 focus:outline-none">Overview</button>
    <button className="p-2 text-gray-600 focus:outline-none">Minutes</button>
    <button className="p-2 text-gray-600 focus:outline-none">Actions</button>
  </div>
  <div>
    {bulletins.map((bulletin) => (
      <div key={bulletin.id} className="p-4 bg-white shadow-md rounded-md mb-4">
        <h3 className="text-lg font-semibold">{bulletin.bulletin_name}</h3>
        <p className="text-sm text-gray-600">{bulletin.date}</p>
        <a href={bulletin.pdf_pathtitle} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline mt-2 block">
          <img src={pdfIcon} alt="PDF Icon" className="inline-block h-6 w-6 mr-2" />
          View PDF
        </a>
      </div>
    ))}
  </div>
</div> */}

{/* Ends tabbed interface */}
{/* Timeline View */}
{/* <div className="p-4">
  <div className="relative">
    <div className="absolute left-2 top-0 bottom-0 w-px bg-gray-200"></div>
    {bulletins.map((bulletin, index) => (
      <div key={bulletin.id} className="mb-8 ml-6 relative">
        <div className="absolute left-[-22px] top-0">
          <div className="bg-blue-500 w-5 h-5 rounded-full"></div>
        </div>
        <div className="p-4 bg-white shadow-md rounded-md">
          <h3 className="text-lg font-semibold">{bulletin.date}</h3>
          <p className="text-sm text-gray-600 mb-2">{bulletin.bulletin_name}</p>
          <a href={bulletin.pdf_pathtitle} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
            <img src={pdfIcon} alt="PDF Icon" className="inline-block h-6 w-6 mr-2" />
            View PDF
          </a>
        </div>
      </div>
    ))}
  </div>
</div> */}

{/* Timeline View */}




      </div>
    );
};

const ExpandableText = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 50; // Set a limit for the text length before truncation

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  
  return (
    <div onClick={handleToggleExpand} className="cursor-pointer text-left text-wrap pr-4 capitalize">
      {isExpanded ? text : `${text.slice(0, maxLength+15)}${text.length > maxLength+15 ? '....' : ''}`}
      {text.length > maxLength+15 && (
        <span className="text-blue-500 ml-2">
          {isExpanded ? ' Less' : ' More'}
        </span>
      )}
    </div>
  );
};
const ExpandableText1 = ({ text }) => {
  const [isExpanded1, setIsExpanded1] = useState(false);

  const maxLength1 = 70; // Set a limit for the text length before truncation

  const handleToggleExpand1 = () => {
    setIsExpanded1(!isExpanded1);
  }; 
  return (
    
    <div onMouseMove={handleToggleExpand1}  className="cursor-pointer text-left text-wrap pr-4 capitalize ">
      {isExpanded1 ? text : `${text.slice(0, maxLength1+15)}${text.length > maxLength1+15 ? '....' : ''}`}
      {text.length > maxLength1+15 && (
        <span className="text-blue-500 ml-2">
          {isExpanded1 ? '' : ''}
        </span>
      )}
    </div>
   
  );
};

export default Bulletin_final_big;
