import React from 'react';
import TeamSlider from './TeamSlider';
const Team = () => {
  return (
    <div className="App container bg-transparent">
    
    <TeamSlider />
  </div>
    );
};

export default Team;
