import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from './AuthContext';

import NewsForm1 from './NewsForm1';
//NewsForm1 to NewsEdit
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const NewsEdit = () => {
  const { user } = useUser();

  const [bulletins, setBulletins] = useState([]);
  const [editingBulletin, setEditingBulletin] = useState(null);

  useEffect(() => {
    fetchBulletins();
  }, []);

  const fetchBulletins = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/news`);
      setBulletins(response.data);
    } catch (error) {
      console.error('Error fetching News:', error);
    }
  };

  const handleEditBulletin = (bulletin) => {
    setEditingBulletin(bulletin);
  };

  const handleFormSubmit = () => {
    setEditingBulletin(null);
    fetchBulletins(); // Refresh bulletin list after form submission
  };

  return (
    <div className="container  mt-20 mb-2 glass">
      <h1 className="text-2xl font-bold mb-4">News List</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-1">
        {bulletins.map((bulletin) => (
          <div key={bulletin.id} className=" shadow-md rounded">
            {/* <h2 className="text-lg font-bold mb-2">{bulletin.title}</h2> */}
            <label className="py-2 px-2">{bulletin.title}</label>
            <input type='textarea' className="text-gray-700 mb-4" value={bulletin.content}/>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
              onClick={() => handleEditBulletin(bulletin)}
            >
              Edit
            </button>
          </div>
        ))}
      </div>
      {editingBulletin && (
        <div className="mt-8">
          <h2 className="text-xl font-bold mb-4">Edit News</h2>
          <NewsForm1 bulletinToEdit={editingBulletin} onFormSubmit={handleFormSubmit} />
        </div>
      )}
    </div>
  );
};

export default NewsEdit;
