import bg1_section from '../loginbg.jpg';
import React, { useState, useEffect ,useRef } from 'react';
import { motion } from 'framer-motion';
import Slider from 'react-slick';
import { WhatsappShareButton, FacebookShareButton, TwitterShareButton, WhatsappIcon, FacebookIcon, TwitterIcon } from 'react-share';
import axios from 'axios';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify'; // For sanitizing HTML content
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Modal from 'react-modal';
import CustomImageCard from './CustomImageCard';
import DateCalendarCard from './DateCalendarCard';
import WeatherCard from './WeatherCard';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import EDMessageCard from './EDMessageCard';
import EDMessageCard1 from './EDMessageCard1';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;


const Latestnews2_accordion = () => {
  const sliderRef = useRef(null);
  const [zoomed, setZoomed] = useState(false);
  const [news, setNews] = useState([]); // Initialize as an empty array
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [displayedNews, setDisplayedNews] = useState([]); // State for displayed news items

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');

  const [members, setMembers] = useState([]);

  //const apiBaseUrl = 'http://localhost:5000';
  var initialLikes = 0, initialUnlikes = 0;
  const [likes, setLikes] = useState(initialLikes);
  const [unlikes, setUnlikes] = useState(initialUnlikes);
  const [hasLiked, setHasLiked] = useState(false);
  const [hasUnliked, setHasUnliked] = useState(false);

  //  [likes, setLikes] = useState(news.likes || 0);
  //  [unlikes, setUnlikes] = useState(news.unlikes || 0);


  useEffect(() => {
    const fetchNews = async () => {
      try {
        const newsResponse = await axios.get(`${apiBaseUrl}/news`);
        const sortedNews = newsResponse.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setNews(sortedNews);
        setDisplayedNews(sortedNews.slice(0, 6)); // Display only top 10 news items initially

      } catch (error) {
        console.error('Error fetching news:', error);
      }
    };

    fetchNews();
  }, []);
  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const res = await axios.get(`${apiBaseUrl}/bknksteam`);
        setMembers(res.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchMembers();
  }, []);
  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  const openModal = (image) => {
    setModalImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage('');
  };




  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    vertical: false,
    verticalSwiping: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ],
  };
  
  const bannerSliderSettings = {
    dots: true,
    infinite: true,
    speed: 2500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ],
  };

  const handleDoubleClick = (e) => {
    e.preventDefault();
    setZoomed(!zoomed);
  };
  

  const mediumSliderSettings = {
    dots: true,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    arrows: true,
    autoplay: true,
    pauseOnHover: true,

    autoplaySpeed: 2000,
      // autoplay: expandedIndex !== null

  };
 
 
  const handleLike = () => {
    setLikes(likes + 1);
    // Make API call to update likes in the database
    //axios.post(`/api/news/${news.id}/like`)
    //  .catch(error => console.error("Error updating likes:", error));
  };

  const handleUnlike = () => {
    setUnlikes(unlikes + 1);
    // Make API call to update unlikes in the database
    //axios.post(`/api/news/${news.id}/unlike`)
     // .catch(error => console.error("Error updating unlikes:", error));
  };

  // const handleLike = () => {
  //   if (hasLiked) {
  //     setLikes(likes - 1);
  //     setHasLiked(false);
  //   } else {
  //     setLikes(likes + 1);
  //     setHasLiked(true);
  //     if (hasUnliked) {
  //       setUnlikes(unlikes - 1);
  //       setHasUnliked(false);
  //     }
  //   }
  // };

  // const handleUnlike = () => {
  //   if (hasUnliked) {
  //     setUnlikes(unlikes - 1);
  //     setHasUnliked(false);
  //   } else {
  //     setUnlikes(unlikes + 1);
  //     setHasUnliked(true);
  //     if (hasLiked) {
  //       setLikes(likes - 1);
  //       setHasLiked(false);
  //     }
  //   }
  // };

  return (
    <>
    <h2 className="text-3xl font-bold mb-2 text-center  mx-8 mr-8 bg-green-700 rounded-xl uppercase text-white">Latest News</h2>
    <div className="  flex mx-8 mr-4 border-b-2">
      {/* <h2 className="text-xl font-bold mb-0 uppercase text-white">Latest News</h2> */}
          <br/>
      <div className="w-9/12 bg-transparent p-0">
        {displayedNews.length === 0 ? (
          <p>Loading news...</p>
        ) : (
          displayedNews.map((newsItem, index) => (
            <motion.div
              key={newsItem.id}
              className="bg-white border-l-green-600 border-b-orange-600 border-b-4 border-l-4 shadow-lg rounded-lg overflow-hidden transform transition-all duration-300 hover:scale-105 mb-4"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <div
                className="p-4 cursor-pointer border-b  border-gray-200 flex justify-between items-center"
                onClick={() => handleToggle(index)}
              >
                <div>
                  <h3 className="text-lg font-semibold text-green-600 text-justify">{newsItem.title}</h3>
                  <p className="text-sm text-gray-600">{newsItem.date}</p>
                </div>
                <div className="flex items-center">
                  <button className="text-blue-500 focus:outline-none">
                    {expandedIndex === index ? 'Hide Details' : 'View Details'}
                  </button>
                  {newsItem.images && newsItem.images.length > 0 && (
                    <div className="w-36 h-auto ml-4 py-2 px-2 ">
                      <Slider {...mediumSliderSettings}>
                        {newsItem.images.map((image, imgIndex) => (
                          <div key={imgIndex} onDoubleClick={() => openModal(`${apiBaseUrl.substring(0, apiBaseUrl.length - 4)}/uploads/${image}`)}>
                            <img
                              // src={`/${image.image_url}`}
                              src={`${apiBaseUrl.substring(0, apiBaseUrl.length - 4)}/uploads/${image}`}
                              //src={bg1_section}
                              alt='img'
                              // alt={`News ${newsItem.title} image ${imgIndex + 1}`}
                              className="w-full h-auto rounded-lg"
                              whileHover={{ scale: 1.05 }}
                            />
                          </div>
                        ))}
                      </Slider>
                    </div>
                  )}
                </div>
              </div>
              {expandedIndex === index && (
                <div className="p-4">
                  {newsItem.images && newsItem.images.length > 0 && (
                    <div className="w-full mb-4">
                      {(newsItem.images.length) > 1 ? (
                        <Slider  ref={sliderRef} {...bannerSliderSettings}>
                          {newsItem.images.map((image, imgIndex) => (
                            <div key={imgIndex} onDoubleClick={() => openModal(`${apiBaseUrl.substring(0, apiBaseUrl.length - 4)}/uploads/${image}`)}>
                             <img
                                // src={`/${image.image_url}`}
                                src={`${apiBaseUrl.substring(0, apiBaseUrl.length - 4)}/uploads/${image}`}
                                // alt={`News ${newsItem.title} image ${imgIndex + 1}`}
                                alt='img'
                                className={`slider-image ${zoomed ? 'double-zoom' : ''} w-full object-cover h-auto py-4 px-2 rounded-2xl`}
                                onDoubleClick={handleDoubleClick}
                              />
                            </div>
                          ))}
                        </Slider>
                      ) : (
                        <img
                          // src={`/${newsItem.images[0].image_url}`}
                          src={`${apiBaseUrl.substring(0, apiBaseUrl.length - 4)}/uploads/${newsItem.images[0]}`}
                          // alt={`News ${newsItem.title} image 1`}
                          alt='images'
                          className="w-4/5 h-auto py-4 px-2 rounded-lg"
                           onDoubleClick={() => openModal(`/${newsItem.images[0]}`)}
                        />
                      )}
                    </div>
                  )}
                  <div
                    className="mb-4 text-justify"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(newsItem.content) }}
                  />
                  <div className="flex space-x-4 mb-4">
                    {/* <WhatsappShareButton url={`https://bknks.org/news/${newsItem.id}`} title={newsItem.title} separator=":: "> */}
                    <WhatsappShareButton url={`https://bknks.org/news`} title={newsItem.title} separator=":: ">

                      <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                    {/* <FacebookShareButton url={`https://bknks.org/news/${newsItem.id}`} quote={newsItem.title}> */}
                    <FacebookShareButton url={`https://bknks.org/news`} quote={newsItem.title}>

                      <FacebookIcon size={32} round />
                    </FacebookShareButton>
                    {/* <TwitterShareButton url={`https://bknks.org/news/${newsItem.id}`} title={newsItem.title}> */}
                    <TwitterShareButton url={`https://bknks.org/news`} title={newsItem.title}>

                      <TwitterIcon size={32} round />
                    </TwitterShareButton>
        {/* <button onClick={handleLike} className="flex items-center space-x-1">
        <span role="img" aria-label="thumbs up">👍</span>
        
        <span>{likes}</span>
      </button>

      <button onClick={handleUnlike} className="flex items-center space-x-1">
        <span role="img" aria-label="thumbs down">👎</span>
        <span>{unlikes}</span>
      </button>

      <button
        onClick={handleLike}
        className={`flex items-center space-x-1 ${hasLiked ? 'text-blue-500' : 'text-gray-500'}`}
      >
        <FontAwesomeIcon icon={faThumbsUp} className="text-2xl" />
        <span>{likes}</span>
      </button>
      <button
        onClick={handleUnlike}
        className={`flex items-center space-x-1 ${hasUnliked ? 'text-red-500' : 'text-gray-500'}`}
      >
        <FontAwesomeIcon icon={faThumbsDown} className="text-2xl" />
        <span>{unlikes}</span>
      </button> */}


                  </div>
                  <p className="text-gray-500 text-sm mt-2">
              Published on: {newsItem.created_at} &nbsp;&nbsp;{`${newsItem.NEC && newsItem.NEC!=='NA' && !newsItem.ZONE ? 'by ' +newsItem.NEC: ''}`} &nbsp;&nbsp;{`${newsItem.ZONE  ? 'by Zone: ' +newsItem.ZONE: ''}`}&nbsp;&nbsp; {`${newsItem.REGION? ' Region: '+newsItem.REGION : ''}`}
            </p>
                </div>
              )}
            </motion.div>
          ))
        )}
       {news.length > 3 && (
          <div className="mt-4 text-center">
            <Link to="/news">
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                View More
              </button>
            </Link>
          </div>
        )}
      </div>
      <div className="w-3/12 mx-auto px-0 -mt-6 bg-transparent">
          <div className='rounded-2xl py-4'>
            <div className='container mx-1'>
          <Slider {...settings}>
            {members.map((member, id) => (
              <motion.div key={member.id} whileHover={{ scale: 1.05 }}>
                <CustomImageCard imageSrc={member.imageUrl} title={member.name} description={member.position} id={id} length={(members.length)-1} />
              </motion.div>
            ))}
            
          </Slider>
          </div>
          </div>
          <div className='bg-gradient-to-r from-blue-500 via-indigo-500 to-purple-500 text-white p-0 object-cover items-center '>
          <EDMessageCard1 className='mx-1'/>
          {/* <DateCalendarCard/> */}
          <WeatherCard className='mx-1'/>
          </div>
        </div>
      {/* Modal for image zoom */}
      <Modal isOpen={isModalOpen} onRequestClose={closeModal} contentLabel="Zoom Image">
        <div className="flex justify-end">
          <button onClick={closeModal} className="text-red-500">
            Close
          </button>
        </div>
        <div className="flex justify-center">
          <img src={modalImage} alt="Zoomed" className="max-w-full max-h-full" />
        </div>
      </Modal>
    </div></>
  );
};

export default Latestnews2_accordion;
