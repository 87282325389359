import React, { useState, useContext } from 'react';
import AdminPanel from './AdminPanel';
import { AuthContext } from './AuthContext';

import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { Link } from 'react-router-dom';

const Login = () => {
  const { login } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(email, password);
      // <NavbarComponent/>
      setError('');
      //alert('successfully login');
     
      const loggedInUser = { email, adminflag: 1 }; // Example user data

      // Simulate API call for login
    setTimeout(() => {
      //localStorage.setItem('user', JSON.stringify(loggedInUser));
      //setEmail(loggedInUser);

      toast.success('Login successful!', {
        // position: toast.POSITION.TOP_RIGHT,
        position: 'top-right', // Corrected position string

        autoClose: 3000,
      });
      // <AdminPanel/>
      navigate('/'); // Redirect to admin menu
    }, 1000);
      
      //window.location.href = '/admin';
    } catch (error) {
      setError('Invalid credentials');
      toast.error("Error Notification !", {
        position: 'bottom-right',
      });
     //window.location.href = '/';
    }
  };

  return (
    <div className="container mx-auto p-32 mb-2 bg-transparent ">
      <h1 className='text-white text-center'>Login</h1>
      <form onSubmit={handleSubmit} className=' text-center bg-transparent border-0 bg-white'><br/>
        <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} required placeholder="Email or Membership ID." />
        <br/><br/><input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required placeholder="Password" />
        <br/><br/><button type="submit" className='px-3 py-1 mx-auto text-white bg-blue-700 rounded transition duration-75 ease-in-out hover:bg-green-400 transform hover:-translate-y-1'>Login</button> &nbsp; &nbsp; 
        {/* <Link  to='/jf' className="text-center  font-semibold text-white hover:text-gray-300 border-l-2 border-r-2 pl-2 pr-2 border-orange-600  animate-pulse">JOIN BKNKS</Link> */}
        <br/><br/>
        {error && <p className='text-white'>{error}</p>}
      </form>
    </div>
  );
};

export default Login;
