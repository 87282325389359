import React from 'react';
import { motion } from 'framer-motion';

const FCITargetsAndProposals = () => {
    const proposals = [
        {
            title: "Resolution of Annual Increment Issue",
            description: "BKNK Sangh is committed to resolving the issue of annual increments being implemented retrospectively, violating existing FCI staff regulations.",
        },
        {
            title: "Hotel Accommodation for Category-III and IV Employees on Tour",
            description: "BKNK Sangh is working towards securing hotel accommodations for Category-III and IV employees while on tour, aiming for a timely resolution.",
        },
        {
            title: "Leased Accommodation for Category-III and IV Employees",
            description: "BKNK Sangh proposed the introduction of leased housing facilities for Category-III and IV employees to address high rental costs.",
        },
        {
            title: "Resolution of Bunching/Pay Anomaly:",
            description: "BKNK Sangh is dedicated to resolving pay anomalies and bunching issues resulting from wage revisions for Category-III and IV employees.",
        },
        {
            title: "Finalization of Own Compassionate Appointment Policy:",
            description: "BKNK Sangh is finalizing a compassionate appointment policy to address the needs of employees' families.",
        },
        {
            title: "Modification of Regulation 22(2):",
            description: "BKNK Sangh is committed on amending Regulation 22(2) of the FCI Staff Regulation Act, 1971, to align it with the unique working conditions of FCI employees, preventing premature retirements.",
        },
        {
            title: "Promotion Ratio and Stagnation Issues:",
            description: "o	BKNK Sangh has managed to form a committee to address promotion ratios and alleviate stagnation among Category-III employees.",
        },
        {
            title: "Amendment of Transfer Policy:",
            description: "BKNK Sangh pushing for amendments to the transfer policy to consider employee welfare and address issues arising from the current policy.",
        },
        {
            title: "Pay Fixation of Ex-Servicemen:",
            description: "BKNK Sangh is pursuing the pay fixation for ex-servicemen, with the management agreeing to resubmit the proposal to the ministry.",
        },
        {
            title: "Increasing Staff Strength in FCI:",
            description: "o	BKNK Sangh is advocating against the reduction of staff strength recommended by CGG, emphasizing the need for adequate staffing.",
        },
        {
            title: "Abolishment of Category-IV Post:",
            description: "BKNK Sangh is addressing issues related to the proposed abolition of Category-IV posts and ensuring pending compassionate appointments are processed.",
        },
        {
            title: "Service Conditions of Hindi Typists:",
            description: "BKNK Sangh is ensuring that the service conditions for Hindi Typists recruited before 2022 remain unchanged, with promotions based on their choices.",
        },
        {
            title: "Keeping the PLI out of the Ceiling of Cafeteria Allowance: ",
            description: "BKNK Sangh has filed a case in the Hon’ble High Court of Delhi to keep the PLI out of the ceiling of Cafeteria Allowance. Upon achieving the feet, BKNK Sangh will manage to keep the PLI out of the ceiling of cafeteria allowance for which MoU was signed accordingly. ",
        }
        

        // Add more proposals here following the same structure
    ];

    const otherPendingIssues = [
        "Fourth Saturday off matter",
        "APS scheme modifications",
        "Compassionate recruitment for Category-IV employees",
        "Inclusion of DA in briefcase allowances",
        "Technical issues with HRMS",
        "Modifications to the 29.10 format",
        "Provision of Mobile Phones/Data Packs",
        "Deletion of Staff Regulation 19(1)"
    ];

    return (
        <div className="bg-gray-100 min-h-screen p-8">
            <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden"
            >
                <div className="bg-blue-700 text-white p-6">
                    <h1 className="text-3xl font-bold text-center">Targets and Proposals Pending for Resolution</h1>
                </div>

                <div className="p-6 space-y-6 text-gray-800">
                    {proposals.map((proposal, index) => (
                        <motion.div
                            key={index}
                            initial={{ opacity: 0, x: -50 }}
                            animate={{ opacity: 1, x: 0 }}
                            // whileInView={{ y:-15}}
                            transition={{ duration: 0.5, delay: index * 1}}
                            className="space-y-2"
                        >
                            <h2 className="text-xl font-semibold">{proposal.title}</h2>
                            <p>{proposal.description}</p>
                        </motion.div>
                    ))}
                </div>

                <div className="bg-blue-700 text-white p-6">
                    <h2 className="text-2xl font-semibold mb-4">Other Pending Issues:</h2>
                    <ul className="list-disc list-inside space-y-2">
                        {otherPendingIssues.map((issue, index) => (
                            <motion.li
                                key={index}
                                initial={{ opacity: 0, x: -50 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ duration: 0.5, delay: index * 0.1 }}
                                className="text-lg"
                            >
                                {issue}
                            </motion.li>
                        ))}
                    </ul>
                </div>
            </motion.div>
        </div>
    );
};

export default FCITargetsAndProposals;
