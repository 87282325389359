import React, { useState, useEffect, useContext  } from 'react';
import { NavLink } from 'react-router-dom';
import NavbarComponent1 from './NavbarComponent1';
import { AuthContext } from './AuthContext';
import Loader from './Loader'; 
import styles from './Navbar.module.css';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';

import { Navbar} from 'react-bootstrap';
import { useUser } from './AuthContext';

const Apass = process.env.Apass;


const Navbar_final3 = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null); // Handle which dropdown is open
  // const [user1, setUser] = useState(null); // Placeholder for user state, replace with actual user state
  // const { user, logout } = useContext(AuthContext);
  const [isSticky, setIsSticky] = useState(false);

  let timeoutId;
  const [visible, setVisible] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  
  
  //const { user: user11 } = useContext(AuthContext);
  const { user } = useUser();

  // Set a default value for adminflag if user11 or adminflag is undefined or null
const adminFlag = user?.adminflag ?? '2';
const names = user?.name ?? 'no name';
const role = user?.role ?? 'normal';
const approveRejectStatus = user?.approvereject_status ?? 'not approved'; // You can set a default status if needed
//  alert('adminflag: '+adminFlag+ ',name: '+ names+ ',password: '+ passwor+ ' ,approvereject_status: '+approveRejectStatus);

  // Set default values using optional chaining

  // alert(adminFlag + ',a '+approveRejectStatus+' , '+role);


  // if(user11.adminflag===1 && user11.approvereject_status==='approved' ){
  //   // alert('Welcome Admin');
  // }else  if(user11.adminflag===2 && user11.approvereject_status==='approved' ){
  //   // alert('Welcome '+user11.name);
  // }

  // if (!user) {
  //   alert(user.name);
  //   return <Navigate to="/" />;
  // }
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const currentScrollPos = window.scrollY;
  //     // alert(user.name);
  //     setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
  //     setPrevScrollPos(currentScrollPos);
  //   };

  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, [prevScrollPos]);


  const handleMouseEnter = (dropdown) => {
    clearTimeout(timeoutId);
    setActiveDropdown(dropdown);
  };

  const handleMouseLeave = () => {
    timeoutId = setTimeout(() => {
    setActiveDropdown(null);
  }, 200); // Delay to allow moving the cursor to the dropdown
};
const [activeIndex, setActiveIndex] = useState(null);

const toggleMenu = (index) => {
  if (activeIndex === index) {
    setActiveIndex(null);
  } else {
    setActiveIndex(index);
  }
};
//loader
  const [loading, setLoading] = useState(false);
  const handleClick = () => {
    setLoading(true);
    setTimeout(() => setLoading(false), 1500); // Simulate loading time
  };
//const [isSticky, setIsSticky] = useState(false);
useEffect(() => {

  const handleScroll = () => {
    setIsSticky(window.scrollY > 0);
  };

  window.addEventListener('scroll', handleScroll);
  return () => window.removeEventListener('scroll', handleScroll);
}, []);



  
  return (
    <>        
      {/* Top Line (Login | Register) */}
      <NavbarComponent1 onClick={handleClick}/>
      {loading && <Loader />}

      {/* <nav className={`w-full border-b border-gray-200  container-fluid sticky top-0 z-50 shadow-2xl ${visible ? 'translate-y-0' : '-translate-y-1'}`} style={{ backgroundColor: '#e0e5ec', padding: '0', zIndex: '999' }}> */}
      <nav
        className={`${
          isSticky ? 'fixed top-0 left-0 w-full z-50 container-fluid shadow-lg' : 'sticky top-0'
        } transition-all  duration-300`
      
      } style={{ backgroundColor: '#e0e5ec', padding: '0', zIndex: '999' }}
      >
        <div className="mx-auto px-4 flex justify-between  bg-transparent items-center h-16" >
          {/* Logo */}
          <NavLink to="/" className="text-2xl font-semibold">
            <img src="/logo.png" alt="Logo" className="h-16" />
          </NavLink>

  {/* Fullscreen Mobile Toggle Menu Icon  */}
  <div className="md:hidden -mx-8 bg-gray-400">
    
     <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setIsOpen(!isOpen)}
          className={styles.toggleButton}
        >
          {isOpen ? (
            <AiOutlineClose className={`${styles.icon} ${styles.iconOpen}`} />
          ) : (
            <AiOutlineMenu className={`${styles.icon} ${styles.iconClosed}`} />
          )}
        </Navbar.Toggle>
      
    </div>
  {/* <div
        className={`fixed inset-0 container transition-transform transform ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        } ease-in-out duration-300 md:hidden z-50`}
      >
        <div className="flex justify-between items-center p-5">
          <span className="text-xl font-bold">Menu</span>
          <button onClick={handleToggle} className="text-gray-500 hover:text-gray-700 focus:outline-none">
            <svg
              className="w-6 h-6"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div></div> */}
          {/* Links */}
          
          <div className={`md:flex ${isOpen ? 'mobile-menu' : ''} md:items-center ${isOpen ? 'block' : 'hidden'}`}>
          <NavLink
              to="/"
              onClick={handleClick}
              className="block md:inline-block  font-semibold font-sans  text-gray-700 text-lg px-3 py-2 no-underline rounded-2xl hover:text-red-600 hover:border-b-2 hover:border-orange-600"
              activeClassName="font-bold text-orange-600 border-b-2 border-orange-600"
            >
              Home
            </NavLink>    
            <NavLink
              onClick={handleClick}
              to="/about"
              className="block md:inline-block text-lg font-sans font-semibold  text-gray-700 px-2  placeholder:px-2 py-2 no-underline rounded-2xl hover:text-red-600 hover:border-b-2 hover:border-orange-600"
              activeClassName="font-bold text-orange-600 border-b-2 border-orange-600"
            >
              About Us
            </NavLink>
            {/* Dropdown Menus */}
            {/* {[ 'FCI', 'Organization', 'Resources', 'Admin'].map((menu, index) => ( */}
            {[ 'FCI', 'Organization', 'Resources', ...(adminFlag === '1' && role === 'Admin' && approveRejectStatus === 'approved' ? ['Admin'] : [])]
  .map((menu, index) => (

              <div
                key={index}
                onClick={handleClick}
                className="relative group"
                onMouseEnter={() => handleMouseEnter(menu)}
                onMouseLeave={handleMouseLeave}
              >
                <NavLink
                  // to={`/${menu}`}
                  className="block md:inline-block text-lg font-semibold font-sans text-gray-700 no-underline px-2 py-2 rounded-2xl hover:text-red-600 hover:border-b-2 hover:border-orange-600"
                  activeClassName="font-bold text-orange-600 border-b-2 border-orange-600"
                >
                  {menu}
                  <svg
                    className="w-4 h-4 inline-block ml-1"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    ></path>
                  </svg>
                </NavLink>
                {activeDropdown === menu && (
                  // <div className="absolute left-0 mt-3 w-48 bg-white rounded-md shadow-lg " onClick={handleClick}> in mobile menu problem so deactive
                  <div className={`${isOpen ? 'static' : 'absolute'} 
                                           left-0 mt-3 w-48 bg-white rounded-md shadow-lg md:absolute md:left-auto md:right-0`} onClick={handleClick}>
                    {/* Render dropdown links based on menu */}
                    {menu === 'FCI' && (
                      <>
                        <NavLink to="/fciglance" className="block px-4 py-2 text-lg text-gray-700 no-underline hover:bg-red-600 hover:text-white hover:shadow-lg hover:shadow-green-300">
                          FCI at a GLANCE
                        </NavLink>
                        <NavLink to="/Roleincovid19" className="block px-4 py-2 text-lg text-gray-700 no-underline hover:bg-red-600 hover:text-white hover:shadow-lg hover:shadow-green-300">
                          Role_in COVID-19
                        </NavLink>
                        {/* <NavLink to="/majorhi" className="block px-4 py-2 text-lg text-gray-700 no-underline hover:bg-red-600 hover:text-white hover:shadow-lg hover:shadow-green-300">
                          Major Highlights
                        </NavLink> */}
                      </>
                    )}
                    {menu === 'Organization' && (
                      <>
                        <NavLink to="/Veteran" className="block px-4 py-2 text-lg text-gray-700 no-underline hover:bg-red-600 hover:text-white hover:shadow-lg hover:shadow-green-300">
                          Our Veterans
                        </NavLink>
                        <NavLink to="/FCIObjectives" className="block px-4 py-2 text-lg text-gray-700 no-underline hover:bg-red-600 hover:text-white hover:shadow-lg hover:shadow-green-300">
                          Our Objectives
                        </NavLink>
                        <NavLink to="/FCIHistory" className="block px-4 py-2 text-lg text-gray-700 no-underline hover:bg-red-600 hover:text-white hover:shadow-lg hover:shadow-green-300">
                          Our History
                        </NavLink>
                        <NavLink to="/OurAchievements" className="block px-4 py-2 text-lg text-gray-700 no-underline hover:bg-red-600 hover:text-white hover:shadow-lg hover:shadow-green-300">
                        Our Achievements

                        </NavLink>
                        <NavLink to="/FCITargets" className="block px-4 py-2 text-lg text-gray-700 no-underline hover:bg-red-600 hover:text-white hover:shadow-lg hover:shadow-green-300">
                          Our Target
                        </NavLink>
                        <NavLink to="/gallery" className="block px-4 py-2 text-lg text-gray-700 no-underline hover:bg-red-600 hover:text-white hover:shadow-lg hover:shadow-green-300">
                          Our Gallery
                        </NavLink>
                        
                        <NavLink to="/Contact" className="block px-4 py-2 text-lg text-gray-700 no-underline hover:bg-red-600 hover:text-white hover:shadow-lg hover:shadow-green-300">
                          Our Team
                        </NavLink>
                        
                      </>
                    )}
                    
                    {menu === 'Resources' && (
                      <>
                        <NavLink to="/news" className="block px-4 py-2 text-lg text-gray-700 no-underline hover:bg-red-600 hover:text-white hover:shadow-lg hover:shadow-green-300">
                        Latest News
                        </NavLink>
                        <NavLink to="/Meetings" className="block px-4 py-2 text-lg text-gray-700 no-underline hover:bg-red-600 hover:text-white hover:shadow-lg hover:shadow-green-300">
                          Meetings
                        </NavLink>
                        <NavLink to="/Demands" className="block px-4 py-2 text-lg text-gray-700 no-underline hover:bg-red-600 hover:text-white hover:shadow-lg hover:shadow-green-300">
                          Demands & Proposals
                        </NavLink>
                        <NavLink to="/BKNKS_UnionImpact" className="block px-4 py-2 text-lg text-gray-700 no-underline hover:bg-red-600 hover:text-white hover:shadow-lg hover:shadow-green-300">
                          Impact of Union
                        </NavLink>
                        <NavLink to="/statusRecognisation" className="block px-4 py-2 text-lg text-gray-700 no-underline hover:bg-red-600 hover:text-white hover:shadow-lg hover:shadow-green-300">
                        Status of Recognition
                        </NavLink>
                      </>
                    )}
                    {menu === 'Admin' && (
                      <>
                        <NavLink to="/ap" className="block px-4 py-2 text-lg text-gray-700 no-underline hover:bg-red-600 hover:text-white hover:shadow-lg hover:shadow-green-300">
                          Members
                        </NavLink>
                        <NavLink to="/nb" className="block px-4 py-2 text-lg text-gray-700 no-underline hover:bg-red-600 hover:text-white hover:shadow-lg hover:shadow-green-300">
                          Latest NewsBoard
                        </NavLink>
                        <NavLink to="/bf" className="block px-4 py-2 text-lg text-gray-700 no-underline hover:bg-red-600 hover:text-white hover:shadow-lg hover:shadow-green-300">
                          Add Demands
                        </NavLink>
                        <NavLink to="/meetingadd" className="block px-4 py-2 text-lg text-gray-700 no-underline hover:bg-red-600 hover:text-white hover:shadow-lg hover:shadow-green-300">
                          Add Meetings
                        </NavLink>
                        <NavLink to="/imageupload" className="block px-4 py-2 text-lg text-gray-700 no-underline hover:bg-red-600 hover:text-white hover:shadow-lg hover:shadow-green-300">
                          Gallery
                        </NavLink>
                        <NavLink to="/youtubevideo" className="block px-4 py-2 text-lg text-gray-700 no-underline hover:bg-red-600 hover:text-white hover:shadow-lg hover:shadow-green-300">
                          Youtube Slider
                        </NavLink>
                        <NavLink to="/Teammemberupload" className="block px-4 py-2 text-lg text-gray-700 no-underline hover:bg-red-600 hover:text-white hover:shadow-lg hover:shadow-green-300">
                          Team Members
                        </NavLink>
                      </>
                    )}
                  </div>
                )}
              </div>
            ))}

            
            <NavLink
              to="/jf"
              className="block md:inline-block text-lg font-semibold font-sans text-gray-700  px-2 py-2 no-underline rounded-2xl hover:text-red-600 hover:border-b-2 hover:border-orange-600"
              activeClassName="font-bold text-orange-600 border-b-2 border-orange-600"
            >
              Join&nbsp;Us&nbsp;
            </NavLink>
          </div>
        </div>



        
      </nav>
    </>
  );
};

export default Navbar_final3;
