import React from 'react';

const GoogleMap = () => {
  return (
    <div>
    &nbsp;
    <div className=" container mx-auto mt-32 mb-2 bg-transparent min-h-screen bg-gray-200 pt-2 ">
      
    <div style={{ width: '100%', height: '700px' }}>
      <iframe
        title="Google Map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d224346.78008917135!2d77.06889606528008!3d28.527578228007587!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce45f21960843%3A0xfed5c5b9c4e3b6e1!2sNew%20Delhi%2C%20Delhi%2C%20India!5e0!3m2!1sen!2sus!4v1623218933692!5m2!1sen!2sus"
            width="100%"
            
        height="100%"
        frameBorder="1"
        style={{ border: 1 }}
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
      ></iframe>
    </div></div></div>
  );
};

export default GoogleMap;
