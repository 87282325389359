import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom'; // Ensure you're using react-router-dom for navigation
import dayjs from 'dayjs';

const Meeting_big1 = ({ meetings, pdfIcon, pdfIcon1 }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  const MeetingComponent = ({ meeting }) => {
    const formattedDate = dayjs(meeting.date).format('DD MMM YYYY');
  
    return (
      <div>
        <p>Meeting Date: {formattedDate}</p>
      </div>
    );
  };
  
  return (
    <div className='-mt-4'>
    
      {/* <caption class="caption-top bg-transparent  text-white">
      <Link as={Link} to="/" className='text-white bg-transparent  no-underline font-medium text-xs'>HOME&nbsp;/&nbsp;</Link>
    </caption>
       */}
      <div className="overflow-x-auto container glass bg-transparent overflow-y-auto space-y-0 p-8">
        {meetings.map((meeting, index) => {
          let agenda = meeting.agenda || [];
          let minutes = meeting.minutes || [];

          if (typeof agenda === 'string') {
            try {
              agenda = JSON.parse(agenda);
            } catch (e) {
              console.error('Error parsing agenda:', e);
              agenda = [];
            }
          }

          if (typeof minutes === 'string') {
            try {
              minutes = JSON.parse(minutes);
            } catch (e) {
              console.error('Error parsing minutes:', e);
              minutes = [];
            }
          }

          return (
            <motion.div
              key={meeting.id}
              className="bg-white shadow-lg rounded-lg overflow-hidden transform transition-all duration-300 hover:scale-105"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <div className="p-4 border-b border-gray-200 flex justify-between items-center">
                <div>
                  <h3 className="text-lg font-semibold text-green-600">
                    <img src={pdfIcon1} alt="BKNKS" className="inline-block h-7 w-7 mr-2" />
                    Dt. {meeting.date}
                  </h3>

<h4 className="text-sm text-gray-600">                  <span className="text-base font-semibold text-green-600 mb-2 uppercase">{index+1}.&nbsp;&nbsp;</span>
{meeting.title}</h4>
                </div>
                <button
                  className="text-blue-500 focus:outline-none"
                  onClick={() => handleToggle(index)}
                >
                  {expandedIndex === index ? 'Hide Details' : 'View Details'}
                </button>
              </div>
              {expandedIndex === index && (
                <div className="p-4">
                  
                  <h4 className="text-base font-semibold text-green-600 mb-2 uppercase">Agenda Points</h4>
                  <ul className="list-disc hover:list-decimal pl-5 text-green-800">
                    {agenda.map((agendaItem, idx) => (
                      <li key={idx} className="mb-1">
                        {agendaItem.agenda_path && (
                          <a href={agendaItem.agenda_path} target="_blank" rel="noopener noreferrer" className="text-blue-800 hover:underline">
                            <ExpandableText text={agendaItem.agenda_point} />

                          </a>
                          
                        )}
                        
                      </li>
                    ))}
                  </ul>
                  <h4 className="text-base font-semibold text-green-600 mb-2 mt-4 uppercase">Minutes of Meeting</h4>
                  <ul className="list-disc hover:list-decimal pl-5 text-green-800">
                    {minutes.map((minutesItem, idx) => (
                      <li key={idx} className="mb-1">
                        {minutesItem.minutes_path && (
                          <a href={minutesItem.minutes_path} target="_blank" rel="noopener noreferrer" className="text-blue-800 hover:underline">
                            <ExpandableText text={minutesItem.minutes_text} />
                          </a>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </motion.div>
          );
        })}
      </div>
    </div>
  );
};
const ExpandableText = ({ text }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const maxLength = 100; // Set a limit for the text length before truncation
  
    const handleToggleExpand = () => {
      setIsExpanded(!isExpanded);
    };
    
    return (
      <div onClick={handleToggleExpand} className="cursor-pointer text-left text-wrap pr-4 capitalize">
        {isExpanded ? text : `${text.slice(0, maxLength+15)}${text.length > maxLength+15 ? '....' : ''}`}
        {text.length > maxLength+15 && (
          <span className="text-blue-500 ml-2">
            {isExpanded ? ' Less' : ' More'}
          </span>
        )}
      </div>
    );
  };
export default Meeting_big1;
