    import React, { useState, useEffect, useRef } from 'react';
  import axios from 'axios';
  import Slider from 'react-slick';
  import YouTube from 'react-youtube';
  import "slick-carousel/slick/slick.css";
  import "slick-carousel/slick/slick-theme.css";
  
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';
  
  const YouTubeSlider = ({ channelId, apiKey }) => {
    const [videoIds, setVideoIds] = useState([]);
  
    useEffect(() => {
      fetchVideoIds();
    }, []);
  
    const fetchVideoIds = () => {
      axios.get(`${apiBaseUrl}/videos`)
        .then(response => {
          setVideoIds(response.data.map(video => video.video_id));
        })
        .catch(error => console.error('Error fetching video IDs:', error));
    };
  
    return (
      <div className="container bg-transparent">
        <YouTubeSlider1 videoIds={videoIds} />
      </div>
    );
  };
  
  const YouTubeSlider1 = ({ videoIds }) => {
    const [activeVideoId, setActiveVideoId] = useState(null);
    const sliderRef = useRef(null);
    const playerRefs = useRef([]);
  
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      pauseOnHover: true,

      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ],
      beforeChange: () => {
        if (activeVideoId) {
          sliderRef.current.slickPause();
        }
      }
    };
  
    const handlePlay = (event, videoId, index) => {
      if (activeVideoId && activeVideoId !== videoId) {
        const activePlayerIndex = videoIds.indexOf(activeVideoId);
        if (playerRefs.current[activePlayerIndex]) {
          playerRefs.current[activePlayerIndex].pauseVideo();
        }
      }
      setActiveVideoId(videoId);
      sliderRef.current.slickPause();
    };
  
    const handlePause = () => {
      setActiveVideoId(null);
      sliderRef.current.slickPlay(); // Automatically resume autoplay when the video is paused
    };
  
    const renderVideos = () => {
      return videoIds.map((videoId, index) => (
        <div key={videoId} className="mx-12 bg-gray-800 glass  border-4 hover:bg-gray-600 rounded-xl">
          <YouTube
            videoId={videoId}
            className="w-full h-48 rounded-md shadow-md"
            opts={{
              width: '100%', // Ensures the video takes the full width of the card
              height: '100%', // Ensures the video takes the full height of the card
              playerVars: {
                autoplay: 0,
              },
            }}
            onPlay={(event) => handlePlay(event, videoId, index)}
            onPause={handlePause}
            onEnd={handlePause}
            ref={(el) => (playerRefs.current[index] = el)}
          />
        </div>
      ));
    };
  
    return (
      <div className="container mx-auto py-8">
        <h2 className="text-3xl font-bold text-center bg-blue-700 text-white mb-6">Video Gallery</h2>
        <div className="px-2 mx-2">
          <Slider ref={sliderRef} {...settings}>
            {renderVideos()}
          </Slider>
        </div>
      </div>
    );
  };
  
  export default YouTubeSlider;
  