import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;


const images2 = [
  // Add  URLs images here
  'https://via.placeholder.com/600x400',
  'https://via.placeholder.com/600x400',
  'https://via.placeholder.com/600x400',
  'https://via.placeholder.com/600x400',
];
const images = [
  '/BKNK_IMG/WIMG(1).jpeg',
'/BKNK_IMG/WIMG(2).jpeg',
'/BKNK_IMG/WIMG(3).jpeg',
'/BKNK_IMG/WIMG(4).jpeg',
'/BKNK_IMG/WIMG(5).jpeg',
'/BKNK_IMG/WIMG(6).jpeg',
'/BKNK_IMG/WIMG(7).jpeg',
'/BKNK_IMG/WIMG(8).jpeg',
'/BKNK_IMG/WIMG(9).jpeg',
'/BKNK_IMG/WIMG(10).jpeg',
'/BKNK_IMG/WIMG(11).jpeg',
'/BKNK_IMG/WIMG(12).jpeg',
'/BKNK_IMG/WIMG(13).jpeg',
'/BKNK_IMG/WIMG(14).jpeg',
'/BKNK_IMG/WIMG(15).jpeg',
'/BKNK_IMG/WIMG(16).jpeg',
'/BKNK_IMG/WIMG(17).jpeg',
'/BKNK_IMG/WIMG(18).jpeg',
'/BKNK_IMG/WIMG(19).jpeg',
'/BKNK_IMG/WIMG(20).jpeg',
'/BKNK_IMG/WIMG(21).jpeg',
'/BKNK_IMG/WIMG(22).jpeg',
'/BKNK_IMG/WIMG(23).jpeg',
'/BKNK_IMG/WIMG(24).jpeg',
'/BKNK_IMG/WIMG(25).jpeg',
'/BKNK_IMG/WIMG(26).jpeg',
'/BKNK_IMG/WIMG(27).jpeg',
'/BKNK_IMG/WIMG(28).jpeg',
'/BKNK_IMG/WIMG(29).jpeg',
'/BKNK_IMG/WIMG(30).jpeg',
'/BKNK_IMG/WIMG(31).jpeg',
'/BKNK_IMG/WIMG(32).jpeg',
'/BKNK_IMG/WIMG(33).jpeg',
'/BKNK_IMG/WIMG(34).jpeg',
'/BKNK_IMG/WIMG(35).jpeg',
'/BKNK_IMG/WIMG(36).jpeg',
'/BKNK_IMG/WIMG(37).jpeg',
'/BKNK_IMG/WIMG(38).jpeg',
'/BKNK_IMG/WIMG(39).jpeg',
'/BKNK_IMG/WIMG(40).jpeg',
'/BKNK_IMG/WIMG(41).jpeg',
'/BKNK_IMG/WIMG(42).jpeg',
'/BKNK_IMG/WIMG(43).jpeg',
'/BKNK_IMG/WIMG(44).jpeg',
'/BKNK_IMG/WIMG(45).jpeg',
'/BKNK_IMG/WIMG(46).jpeg',
'/BKNK_IMG/WIMG(47).jpeg',
'/BKNK_IMG/WIMG(48).jpeg',
'/BKNK_IMG/WIMG(49).jpeg',
'/BKNK_IMG/WIMG(50).jpeg',
'/BKNK_IMG/WIMG(51).jpeg',
'/BKNK_IMG/WIMG(52).jpeg',
'/BKNK_IMG/WIMG(53).jpeg',
'/BKNK_IMG/WIMG(54).jpeg',
'/BKNK_IMG/WIMG(55).jpeg',
'/BKNK_IMG/WIMG(56).jpeg',
'/BKNK_IMG/WIMG(57).jpeg',
'/BKNK_IMG/WIMG(58).jpeg',
'/BKNK_IMG/WIMG(59).jpeg',
'/BKNK_IMG/WIMG(60).jpeg',
'/BKNK_IMG/WIMG(61).jpeg',
'/BKNK_IMG/WIMG(62).jpeg',
'/BKNK_IMG/WIMG(63).jpeg',
'/BKNK_IMG/WIMG(64).jpeg',
'/BKNK_IMG/WIMG(65).jpeg',
'/BKNK_IMG/WIMG(66).jpeg',
'/BKNK_IMG/WIMG(67).jpeg',
'/BKNK_IMG/WIMG(68).jpeg',
'/BKNK_IMG/WIMG(69).jpeg',
'/BKNK_IMG/WIMG(70).jpeg',
'/BKNK_IMG/WIMG(71).jpeg',
'/BKNK_IMG/WIMG(72).jpeg',
'/BKNK_IMG/WIMG(73).jpeg',
'/BKNK_IMG/WIMG(74).jpeg',
'/BKNK_IMG/WIMG(75).jpeg',
'/BKNK_IMG/WIMG(76).jpeg',

];
const GalleryPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);


//IMG FILE AND ID FROM IMG DATABASE & UPLOAD FOLDER

const [images1, setImages1] = useState([]);

useEffect(() => {
  const fetchImages = async () => {
    try{
      // alert('ok');
      const res = await axios.get(`${apiBaseUrl}/images`);
      const sorted = res.data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setImages1(sorted);
      // alert(images1);
    }catch(err){
      alert(err+" check server");
    }
  };
  fetchImages();
}, []);


  return (
    <div className="container mx-auto  mb-2 bg-transparent dark:bg-gray-900 transition-colors duration-500 bg-white" >
    &nbsp;
    <div className=" mt-32 mb-2 glass max-w-4xl mx-auto p-4 bg-white shadow-md rounded-md">
      <h2 className="text-3xl font-bold mb-6">Gallery</h2>
      <div className="mb-4">
        <label htmlFor="category" className="block text-sm font-medium text-gray-700">
          Filter by User
        </label>
        <select
          id="category"
          // value={selectedCategory}
          // onChange={(e) => setSelectedCategory(e.target.value)}
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
        ><option value="">All Categories</option>
          {/* <option selected>All</option> */}
          <option>NEC</option>
          <option>ZEC</option>
          <option>REC</option>
        </select>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {images1.map((img, index) => (
          <div key={index} className="p-2">
            <br/>
            {/* alert({`${apiBaseUrl}/uploads/${img.image}`}); */}
            {/* ({`${apiBaseUrl.substring(0,(apiBaseUrl.length-4))}/uploads/${img.image}`}) */}
            {/* <br/>${apiBaseUrl.substring(0,(apiBaseUrl.length-4))}/uploads/${img.image}<br/> */}
            {/* <img
              src={`${apiBaseUrl.substring(0,(apiBaseUrl.length-4))}/uploads/${img.image}`}
              alt={`${apiBaseUrl}/uploads/${img.image}`}
              className="w-full h-auto cursor-pointer"
              onClick={() => {
                setPhotoIndex(index);
                setIsOpen(true);
              }}
            /> */}
            {/* {`${apiBaseUrl.substring(0,(apiBaseUrl.length-4))}/uploads/${img.image}`} */}
            <img
              src={`${apiBaseUrl.substring(0,(apiBaseUrl.length-4))}/uploads/${img.image}`}
              // src={`https://bknks.org/uploads/${img.image}`}
              // alt={`${img.image}`}
              className="w-full h-auto cursor-pointer"
              onClick={() => {
                setPhotoIndex(index);
                setIsOpen(true);
              }}
            />
           
          </div>
        ))}
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </div></div>
  );
};

export default GalleryPage;
