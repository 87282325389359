// ImageSlider.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const ImageSlider = () => {
  //const [images, setImages] = useState([]);
//   const apiKey = '44518918-502e5766ba5252e2435ae14af'; // Replace with your Pixabay API key pixabay

//   useEffect(() => {
//     const fetchImages = async () => {
//       try {
//         const response = await axios.get(
//           `https://pixabay.com/api/?key=${apiKey}&q=landscape&image_type=photo`
//         );
//         setImages(response.data.hits);
//       } catch (error) {
//         console.error('Error fetching images from Pixabay:', error);
//       }
//     };

//     fetchImages();
//   }, [apiKey]);
const images = [
    '/BKNK_IMG/WIMG(1).jpeg',
'/BKNK_IMG/WIMG(2).jpeg',
'/BKNK_IMG/WIMG(3).jpeg',
'/BKNK_IMG/WIMG(4).jpeg',
'/BKNK_IMG/WIMG(5).jpeg',
'/BKNK_IMG/WIMG(6).jpeg',
'/BKNK_IMG/WIMG(7).jpeg',
'/BKNK_IMG/WIMG(8).jpeg',
'/BKNK_IMG/WIMG(9).jpeg',
'/BKNK_IMG/WIMG(10).jpeg',
'/BKNK_IMG/WIMG(11).jpeg',
'/BKNK_IMG/WIMG(12).jpeg',
'/BKNK_IMG/WIMG(13).jpeg',
'/BKNK_IMG/WIMG(14).jpeg',
'/BKNK_IMG/WIMG(15).jpeg',
'/BKNK_IMG/WIMG(16).jpeg',
'/BKNK_IMG/WIMG(17).jpeg',
'/BKNK_IMG/WIMG(18).jpeg',
'/BKNK_IMG/WIMG(19).jpeg',
'/BKNK_IMG/WIMG(20).jpeg',
'/BKNK_IMG/WIMG(21).jpeg',
'/BKNK_IMG/WIMG(22).jpeg',
'/BKNK_IMG/WIMG(23).jpeg',
'/BKNK_IMG/WIMG(24).jpeg',
'/BKNK_IMG/WIMG(25).jpeg',
'/BKNK_IMG/WIMG(26).jpeg',
'/BKNK_IMG/WIMG(27).jpeg',
'/BKNK_IMG/WIMG(28).jpeg',
'/BKNK_IMG/WIMG(29).jpeg',
'/BKNK_IMG/WIMG(30).jpeg',
'/BKNK_IMG/WIMG(31).jpeg',
'/BKNK_IMG/WIMG(32).jpeg',
'/BKNK_IMG/WIMG(33).jpeg',
'/BKNK_IMG/WIMG(34).jpeg',
'/BKNK_IMG/WIMG(35).jpeg',
'/BKNK_IMG/WIMG(36).jpeg',
'/BKNK_IMG/WIMG(37).jpeg',
'/BKNK_IMG/WIMG(38).jpeg',
'/BKNK_IMG/WIMG(39).jpeg',
'/BKNK_IMG/WIMG(40).jpeg',
'/BKNK_IMG/WIMG(41).jpeg',
'/BKNK_IMG/WIMG(42).jpeg',
'/BKNK_IMG/WIMG(43).jpeg',
'/BKNK_IMG/WIMG(44).jpeg',
'/BKNK_IMG/WIMG(45).jpeg',
'/BKNK_IMG/WIMG(46).jpeg',
'/BKNK_IMG/WIMG(47).jpeg',
'/BKNK_IMG/WIMG(48).jpeg',
'/BKNK_IMG/WIMG(49).jpeg',
'/BKNK_IMG/WIMG(50).jpeg',
'/BKNK_IMG/WIMG(51).jpeg',
'/BKNK_IMG/WIMG(52).jpeg',
'/BKNK_IMG/WIMG(53).jpeg',
'/BKNK_IMG/WIMG(54).jpeg',
'/BKNK_IMG/WIMG(55).jpeg',
'/BKNK_IMG/WIMG(56).jpeg',
'/BKNK_IMG/WIMG(57).jpeg',
'/BKNK_IMG/WIMG(58).jpeg',
'/BKNK_IMG/WIMG(59).jpeg',
'/BKNK_IMG/WIMG(60).jpeg',
'/BKNK_IMG/WIMG(61).jpeg',
'/BKNK_IMG/WIMG(62).jpeg',
'/BKNK_IMG/WIMG(63).jpeg',
'/BKNK_IMG/WIMG(64).jpeg',
'/BKNK_IMG/WIMG(65).jpeg',
'/BKNK_IMG/WIMG(66).jpeg',
'/BKNK_IMG/WIMG(67).jpeg',
'/BKNK_IMG/WIMG(68).jpeg',
'/BKNK_IMG/WIMG(69).jpeg',
'/BKNK_IMG/WIMG(70).jpeg',
'/BKNK_IMG/WIMG(71).jpeg',
'/BKNK_IMG/WIMG(72).jpeg',
'/BKNK_IMG/WIMG(73).jpeg',
'/BKNK_IMG/WIMG(74).jpeg',
'/BKNK_IMG/WIMG(75).jpeg',
'/BKNK_IMG/WIMG(76).jpeg',

  ];


  const [currentSlide, setCurrentSlide] = React.useState(0);


  const CustomPaging = (i, currentSlide) => (
    <button
      style={{
        color: i === currentSlide ? 'blue' : 'black',
        border: 'none',
        background: 'none',
        cursor: 'pointer',
        margin: '0 5px',
      }}
    >
      {i + 1}
    </button>
  );
  
  const settings = {
    customPaging: (i) => <CustomPaging i={i} currentSlide={currentSlide} />,
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    // afterChange: (current) => setCurrentSlide(current),
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  
 
  const [zoomedImage, setZoomedImage] = useState(null);


  const handleDoubleClick = (image) => {
    setZoomedImage(image);
  };

  const handleZoomOut = () => {
    setZoomedImage(null);
  };
// for array random
  const shuffleArray = (array) => {
    return array.sort(() => Math.random() - 0.5);
  };
  const shuffledImages = shuffleArray(images);
//
//for zoom 
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
//


//IMG FILE AND ID FROM IMG DATABASE & UPLOAD FOLDER

const [images1, setImages1] = useState([]);

useEffect(() => {
  const fetchImages = async () => {
    try{
      const res = await axios.get(`${apiBaseUrl}/images`);
      setImages1(res.data);
    //alert('ok')
    }catch(err){alert(err+" check server");}
  };
  fetchImages();
}, []);

// for array random
const shuffleArray1 = (array) => {
  return array.sort(() => Math.random() - 0.5);
};
const shuffledImages1 = shuffleArray1(images1);
//



  return (
    <div className="mx-auto max-w-7xl bg-transparent">
      
      <Slider {...settings}>
        {shuffledImages1.map((image, index) => (
          <div key={image.id} className="px-2">
            {/* <img src={imageSrc} alt={title} className="object-cover w-full h-full" /> */}
            {/* <GalleryItem key={image.id} imageSrc={image.src} title={image.title} /> */}
            <img
              src={`${apiBaseUrl.substring(0, apiBaseUrl.length - 4)}/uploads/${image.image}`}
              alt={image.tags}
              className="overflow-hidden rounded-lg shadow-lg w-80 h-80 mx-4 my-4 hover:shadow-xl object-cover  hover:scale-110 transform transition duration-300"
              onDoubleClick={() => handleDoubleClick(image)}
              
              // onClick={() => {
              //   setPhotoIndex(index);
              //   setIsOpen(true);
              // }}
            />
             {/* <p className="text-center">{image.title}</p> */}
          </div>
        ))}
      </Slider>
      {/* {isOpen && (
        <Lightbox
          mainSrc={shuffledImages[photoIndex]}
          nextSrc={shuffledImages[(photoIndex + 1) % shuffledImages.length]}
          prevSrc={shuffledImages[(photoIndex + shuffledImages.length - 1) % shuffledImages.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + shuffledImages.length - 1) % shuffledImages.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % shuffledImages.length)
          }
        />
      )} */}
    </div>
  );
};

export default ImageSlider;
