import React from 'react';
// import { useUser } from './UserProvider';
import { useUser } from './AuthContext';

function Dashboard() {
  const { user } = useUser();

  if (!user) {
    return <p>Please log in to access this page.</p>;
  }

  return (
    <div>
      <h1>Welcome, {user.email} , {user.role}, {user.password}, {user.name},{user.adminflag},{user.approvereject_status}</h1>
      {user.role === 'admin' && (
        <div>
          <h2>Admin Panel</h2>
          {/* Admin specific components */}
        </div>
      )}
      {/* Common user components */}
    </div>
  );
}

export default Dashboard;
