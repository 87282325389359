import React from 'react';

const Separator = () => {
  return (
    <div className="relative my-12">
      <div className="absolute inset-0 flex items-center">
        <div className="w-full border-t border-blue-500"></div>
      </div>
      <div className="relative flex justify-center">
        <span className="px-3 bg-gray-100 text-blue-500 uppercase text-center">Sangharsh Kabhi Ruka Nahi; Sangh Kabhi Thaka Nahi<br/>BKNK Sangh </span>
      </div>
    </div>
  );
};

export default Separator;
