// import React from 'react';
// import './Footer.css';
// const Footer = () => {
//   return (
//     <footer className="p-4 bg-gray-800 text-white text-center">
//       <p>&copy; 2023 Modern Website. All rights reserved.</p>
//     </footer>
//   );
// }

// export default Footer;

import React from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faContao, faYoutube, faSquareYoutube, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';
import './Footer.css'; // Import the CSS file for additional custom styling
import PDFViewer from './PDFViewer';
import dis from '../Disclaimer.pdf';
import webpol from '../WebsitePolicy.pdf';

const Footer = () => {
  return (
    <footer className="bg-blue-900 text-white p-6 mt-8">
      <div className="max-w-6xl mx-auto">
        <div className="flex justify-between items-center">
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="space-y-2"
          >
            <h2 className="text-xl font-bold">Quick Links</h2>
            <ul className="space-y-1 flex-auto">
              <li><a href="/" className="hover:underline text-white">Home</a></li>
              <li><a href="/news" className="hover:underline text-white">News</a></li>
              <li><a href="/Login" className="hover:underline text-white">Login</a></li>
              <li><a href="/https://fci.gov.in/headquarter/employeecorner-details/VTJGc2RHVmtYMStBNmxmNkN2V2JhWG9KUWRSR3BRRzhxQ1FhQ3k5UnkrND0%3D"  target="_blank" rel="noopener noreferrer"   className="hover:underline text-white">FCI Staff Regulations, 1971</a></li>
              <li><a href="https://documents.doptcirculars.nic.in/D2/D02est/latest%20consolidated%20ruleMsnzh.pdf"  target="_blank" rel="noopener noreferrer"  className="hover:underline text-white">CCS Leave Rules, 1972</a></li>

              <li>
              <a href={dis} target="_blank" rel="noopener noreferrer"  className="hover:underline text-white">Disclaimer</a></li>
              <li><a href={webpol}  target="_blank" rel="noopener noreferrer"  className="hover:underline text-white">Website Policy</a></li>
              
            </ul>
            
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="space-y-2"
          >
            <h2 className="text-xl font-bold">Important Links</h2>
            <ul className="space-y-1 flex-auto text-white">
              <li><a href="https://fci.gov.in/headquarter/" className="hover:underline text-white">FCI Website</a></li>
              <li><a href="https://www.hrmsfci.in/" className="hover:underline text-white">HRMS</a></li>
              <li><a href="https://saccess.nic.in" className="hover:underline text-white">NIC WEBVPN for eOffice</a></li>
              <li><a href="http://fcilekha.in:7778/" className="hover:underline text-white">FAP</a></li>
              <li><a href="https://irrs.iisfm.nic.in/" className="hover:underline text-white">IRRS</a></li>
              <li><a href="http://fcidepotonline.gov.in" className="hover:underline text-white">FCI Depot Online</a></li>
              <li><a href="https://www.fciqms.in/" className="hover:underline capitalize text-white">Quality Management System
</a></li>
<li><a href="https://clc.gov.in/clc/acts-rules/industrial-disputes-act" className="hover:underline capitalize text-white">Industrial Disputes Act 1947
</a></li>
<li><a href="https://labour.delhi.gov.in/it/trade-unions-act-1926" className="hover:underline capitalize text-white">Trade Union Act 1926
</a></li>

            </ul>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="space-y-2"
          >
            <h2 className="text-xl font-bold">Contact Us</h2>
            <ul className="space-y-1 flex-auto">
              <li>Food Corporation Of India<br/> National Commitee Office,<br/> Room no 1103, 11th Floor, <br/>16-20, Barakhamba Lane,<br/> New-Delhi-110001, India.</li>
              <li><a href="mailto:fcibknks@outlook.com" className="hover:underline text-white">Feedback : fcibknks@outlook.com</a></li>
            </ul>
            
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="space-y-2"
          >
            <h2 className="text-xl font-bold"></h2>
            <ul className="space-y-1 flex-auto">
              <li>&nbsp;</li>
              <li>&nbsp;</li>
              <li>&nbsp;</li>
            </ul>
            
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="space-y-2"
          >
            <h2 className="text-xl font-bold">Follow Us</h2>
            <div className="flex space-x-4 text-white">
              <a href="https://www.facebook.com/share/Fu26WKtXwvC6ovYS/?mibextid=qi2Omg" className="hover:text-blue-500">
                <FontAwesomeIcon icon={faFacebook} size="2x" />
              </a>
              <a href="https://x.com/Bknk_sangh" className="hover:text-blue-500">
                <FontAwesomeIcon icon={faTwitter} size="2x" />
              </a>
              <a href="https://www.instagram.com/bknksanghfci?igsh=MXh3dGUwd3RuNjhndg==" className="hover:text-blue-500">
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </a>
              <a href="https://youtube.com/@tradeunionpulse?si=p3UZhvbunv7EsG93" className="hover:text-blue-500">
                <FontAwesomeIcon icon={faYoutube} size="2x" />
              </a>
            </div>
          </motion.div>
        </div>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="text-center mt-6 text-white"
        >
          <p className=' text-white  '>&copy;2024 &nbsp; BHARATIYA KHADYA NIGAM KARAMCHARI SANGH, REGD.NO.2217/ND</p> &nbsp;&nbsp;All Rights Reserved.
        </motion.div>
      </div>
    </footer>
  );
};

export default Footer;
