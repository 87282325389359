import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { motion } from 'framer-motion';
import v2 from '../logo.png';
import v1 from '../2a.jpg';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const JoinForm = () => {
  const [status, setStatus] = useState('');
  const [empno, setEmpno] = useState('');
  const [name, setName] = useState('');
  const [des, setDes] = useState('');
  const [gender, setGender] = useState('');
  const [dob, setDob] = useState('');
  const [region, setRegion] = useState('');
  const [zone, setZone] = useState('');
  const [ro, setRo] = useState('');
  const [dodepo, setDoDepot] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [state, setState] = useState('');
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [otp, setOtp] = useState('');
  const [otpInput, setOtpInput] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpVerify, setIsOtpVerify] = useState(false);
  const [errors, setErrors] = useState({});
  const [postingAt, setPostingAt] = useState('');
  const [failedAttempts, setFailedAttempts] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [zones, setZones] = useState([]);
  const [ros, setRos] = useState([]);
  const [dos, setDos] = useState([]);

  const [category, setCategory] = useState([]);
  
  
  const convertDateFormat = (dateStr) => {
    if (typeof dateStr !== 'string' || !dateStr) {
      //console.error('Invalid date string:', dateStr);
      return ''; // Return empty string or handle as needed
    }
  
    const [day, month, year] = dateStr.split('/');
    
    // Validate that we have all parts of the date
    if (!day || !month || !year) {
      //console.error('Date string does not contain all parts:', dateStr);
      return ''; // Return empty string or handle as needed
    }
  
    // Pad month and day to ensure two digits
    const formattedMonth = month.padStart(2, '0');
    const formattedDay = day.padStart(2, '0');
    
    return `${year}-${formattedMonth}-${formattedDay}`;
  };
  
  const fetchEmployeeData = async (empno) => {
    // Check if empno is exactly 6 digits and contains only numbers
    const isValidEmpno = /^\d{6}$/.test(empno);
  
    if (isValidEmpno) {

      try{
            // Check if empno exists in the member database
            const checkResponse = await axios.get(`${apiBaseUrl}/check-member/${empno}`);
            const memberExists = checkResponse.data.exists; // Assuming the response contains { exists: true/false }
  
            if (memberExists) {
              // Empno already exists, show an error
              setErrors(prevErrors => ({
                ...prevErrors,
                empno: 'Employee number already exists, Kindly contact BKNKS Team.'
              }));
              return; // Exit the function early
            }
      }catch (error) { console.log(error);}

      try {
         // If empno does not exist, proceed to fetch employee data
      const response = await axios.get(`${apiBaseUrl}/empdata/${empno}`);
      const data = response.data;
  
        if (data) {
          // Convert date format from dd-MM-yyyy to yyyy-MM-dd
          const convertedDob = data.DOB ? convertDateFormat(data.DOB) : '';
         // console.log('Converted DOB:', convertedDob); // Debug: Check converted date
  
          // Validate if the converted date format is yyyy-MM-dd
          const isValidDate = /^\d{4}-\d{2}-\d{2}$/.test(convertedDob);
         // console.log('Is Valid Date:', isValidDate); // Debug: Check date validity
  
          if (!isValidDate) {
            // Set error for date format
            setErrors(prevErrors => ({
              ...prevErrors,
              dob: `Date "${data.DOB}" does not conform to the required format, "yyyy-MM-dd".`
            }));
          } else {
            // Clear date format error if valid
            setErrors(prevErrors => ({ ...prevErrors, dob: '' }));
            setDob(convertedDob || '');
          }
       
          const category = data.Category;
          setSelectedCategory(category);
          const foundCategory = categories.find(cat => cat.category === category);
          setCadres(foundCategory ? foundCategory.cadres : []);
          setSelectedCadre('');
          //setPosts([]);
// Set cadre and posts
// const cadre = data.CadreTitle;
// const foundCadre = cadres.find(c => c.cadre === cadre);
// const newPosts = foundCadre ? foundCadre.posts : [];
// setSelectedCadre(cadre);
// setPosts(newPosts);

    
//      const posts1 = data.DesignationName;
//      setPosts1(posts1);
         
          setName(data.EmployeeName || '');
          //setMobile(data.MobileNo || '');
          //setEmail(data.EmailID || '');
          // Clear any previous errors related to empno
          setErrors(prevErrors => ({ ...prevErrors, empno: '' }));
        }
      } catch (error) {
        // Check if the error is a 404 error
        if (error.response && error.response.status === 404) {
          setErrors(prevErrors => ({
            ...prevErrors,
            empno: 'Employee not found. Please check the Employee Number.'
          }));
        } else {
          console.error('Error fetching employee data:', error);
          setErrors(prevErrors => ({
            ...prevErrors,
            empno: 'An error occurred while fetching employee data.'
          }));
        }
      }
    } else {
      setErrors(prevErrors => ({
        ...prevErrors,
        empno: 'Invalid employee number. It must be exactly 6 digits.'
      }));
      setName( '');
          setMobile( '');
          setEmail('');
          //setPosts([]);
          setSelectedCadre('');
          setSelectedCategory('');
          setDob('');

    }
    // empno = empno-1;

    // setEmpno(empno);
    //   //  fetchEmployeeData1(empno);

    // empno = empno+1;
    // setEmpno(empno);
    // fetchEmployeeData1(empno);
  };
  const fetchEmployeeData1 = async (empno) => {
    // Check if empno is exactly 6 digits and contains only numbers
    const isValidEmpno = /^\d{6}$/.test(empno);
  
    if (isValidEmpno) {
      try {
        const response = await axios.get(`${apiBaseUrl}/empdata/${empno}`);
        const data = response.data;
  
        if (data) {
          // Convert date format from dd-MM-yyyy to yyyy-MM-dd
          const convertedDob = data.DOB ? convertDateFormat(data.DOB) : '';
         // console.log('Converted DOB:', convertedDob); // Debug: Check converted date
  
          // Validate if the converted date format is yyyy-MM-dd
          const isValidDate = /^\d{4}-\d{2}-\d{2}$/.test(convertedDob);
         // console.log('Is Valid Date:', isValidDate); // Debug: Check date validity
  
          if (!isValidDate) {
            // Set error for date format
            setErrors(prevErrors => ({
              ...prevErrors,
              dob: `Date "${data.DOB}" does not conform to the required format, "yyyy-MM-dd".`
            }));
          } else {
            // Clear date format error if valid
            setErrors(prevErrors => ({ ...prevErrors, dob: '' }));
            setDob(convertedDob || '');
          }
       
          const category = data.Category;
          setSelectedCategory(category);
          const foundCategory = categories.find(cat => cat.category === category);
          setCadres(foundCategory ? foundCategory.cadres : []);
          setSelectedCadre('');
         // setPosts([]);

          // const cadre = data.CadreTitle;
          // const foundCadre = cadres.find(c => c.cadre === cadre);
          // const newPosts = foundCadre ? foundCadre.posts : [];
          // setSelectedCadre(cadre);
          // setPosts(foundCadre ? foundCadre.posts : []);
          // setPosts1(foundCadre ? foundCadre.posts : []);
          // setPosts(newPosts);
// Set cadre and posts
// const cadre = data.CadreTitle;
// const foundCadre = cadres.find(c => c.cadre === cadre);
// const newPosts = foundCadre ? foundCadre.posts : [];
// setSelectedCadre(cadre);
// setPosts(newPosts);

    
    //  const posts1 = data.DesignationName;
    //  setPosts1(posts1);
         
          setName(data.EmployeeName || '');
          setMobile(data.MobileNo || '');
          setEmail(data.EmailID || '');
          // Clear any previous errors related to empno
          setErrors(prevErrors => ({ ...prevErrors, empno: '' }));
        }
      } catch (error) {
        // Check if the error is a 404 error
        if (error.response && error.response.status === 404) {
          setErrors(prevErrors => ({
            ...prevErrors,
            empno: 'Employee not found. Please check the Employee Number.'
          }));
        } else {
          console.error('Error fetching employee data:', error);
          setErrors(prevErrors => ({
            ...prevErrors,
            empno: 'An error occurred while fetching employee data.'
          }));
        }
      }
    } else {
      setErrors(prevErrors => ({
        ...prevErrors,
        empno: 'Invalid employee number. It must be exactly 6 digits.'
      }));
          setName('');
          setMobile( '');
          setEmail('');
         // setPosts([]);
          setSelectedCadre('');
          setSelectedCategory('');
          setDob('');

    }
   

  };
  
  const navigate = useNavigate();

  const categories = [
    {
      category: 'CAT-II',
      cadres: [
        {
          cadre: 'General',
          posts: ['Manager (General)']
        },
        {
          cadre: 'Depot',
          posts: ['Manager (Depot)', 'Chief Labour Inspector']
        },
        {
          cadre: 'Technical',
          posts: ['Manager (QC)']
        },
        {
          cadre: 'Movement',
          posts: ['Manager (Movt.)']
        },
        {
          cadre: 'Accounts',
          posts: ['Manager (A/Cs.)']
        },
        {
          cadre: 'Engineering',
          posts: ['Manager (Civil)', 'Manager (Elec.)', 'Manager (Mech.)']
        },
        {
          cadre: 'Official Language',
          posts: ['Manager (Rajbhasha)']
        },
        {
          cadre: 'Law',
          posts: ['Manager (Law)']
        },
        {
          cadre: 'Misc. Cadre',
          posts: ['Librarian', 'Manager (Animal Nutrition)', 'Receptionist']
        },
        {
          cadre: 'Data Processing',
          posts: ['Manager (Data Processing)']
        },
        {
          cadre: 'Planning & Research Cadre',
          posts: ['Manager (P&R)', 'Manager (O/R)']
        },
      ]
    },
    {
      category: 'CAT-III',
      cadres: [
        {
          cadre: 'General',
          posts: ['AG-I(Genl.)', 'AG-II(Genl.)', 'AG-III(Genl.)', 'Typist',  'Personal Secretary', 'Steno Grade-I', 'Steno Grade-II', 'Others']
        },
        {
          cadre: 'Depot',
          posts: ['AG-I(Depot)', 'AG-II(Depot)', 'AG-III(Depot)', 'Others']
        },
        {
          cadre: 'Quality Control',
          posts: ['AG-I(Tech.)', 'AG-II(Tech.)', 'AG-III(Tech.)']
        },
        {
          cadre: 'Movement',
          posts: ['AG-I(Movt.)', 'AG-II(Movt.)']
        },
        {
          cadre: 'Accounts',
          posts: ['AG-I(A/Cs.)', 'AG-II(A/Cs.)', 'AG-III(A/Cs.)']
        },
        {
          cadre: 'Engineering',
          posts: ['AG-I', 'AG-II', 'AG-III','JE (Civil)', 'JE (Elec.)', 'JE (Mech.)', 'Jr. Engineer', 'Others']
        },
        {
          cadre: 'Engineering - Civil',
          posts: ['AG-I', 'AG-II', 'AG-III','JE (Civil)', 'JE (Elec.)', 'JE (Mech.)','Jr. Engineer','Others']
        },
        {
          cadre: 'Engineering - Electrical/Mechanical',
          posts: ['AG-I', 'AG-II', 'AG-III','JE (Civil)', 'JE (Elec.)', 'JE (Mech.)','Jr. Engineer', 'Others']
        },
        {
          cadre: 'Rajbhasha',
          posts: ['AG-I (Rajbhasha)', 'AG-II (Rajbhasha)', 'AG-III (Rajbhasha)']
        },
        {
          cadre: 'Miscellaneous',
          posts: ['Assistant Librarian', 'AG-I', 'AG-II', 'AG-III', 'Others']
        },
        {
          cadre: 'Data Processing',
          posts: ['AG-I (P/P)', 'AG-II (P/P)', 'Others']
        },
        {
          cadre: 'Planning & Research Cadre',
          posts: ['Statistical Assistant']
        },
      ]
    },
    {
      category: 'CAT-IV',
      cadres: [
        {
          cadre: 'General',
          posts: ['Gestetner Operator', 'Jamadar', 'Daftary', 'Peon', 'Watchman', 'Picker', 'Head Watchman', 'Dusting Operator', 'Stitcher', 'Watchman(Godown)', 'Sifter', 'Labourer', 'Sweeper', 'Others']
        },
      ]
    }
  ];
  

  const handleSubmit = (e) => {
    e.preventDefault();
  // Show a confirmation dialog
  const userConfirmed = window.confirm('Are you sure you want to submit the form?');

  if (userConfirmed) {
    sendOtp();
  } else {
    console.log('Form submission canceled by the user.');
  }
  };

  const validateEmpno = (value) => {
    if (!/^\d{6}$/.test(value)) {
      setErrors((prev) => ({ ...prev, empno: 'Employee number must be exactly 6 digits.' }));
    } else {
      setErrors((prev) => {
        const { empno, ...rest } = prev;
        return rest;
      });
    }
  };

  const validateMobile = (value) => {
    if (!/^\d{10}$/.test(value)) {
      setErrors((prev) => ({ ...prev, mobile: 'Mobile number must be exactly 10 digits.' }));
    } else {
      setErrors((prev) => {
        const { mobile, ...rest } = prev;
        return rest;
      });
    }
  };

  const sendOtp = async () => {
    const otp = Math.floor(100000 + Math.random() * 900000);
  setOtp(otp);
  const templateParams = {
    to_email: email,
    otp: otp,
  };

  try {
    const response = await axios.post(`${apiBaseUrl}/send-otp`, templateParams);
    if (response.data.success) {
      setIsOtpSent(true);
      setIsModalOpen(true);
      // alert('OTP sent successfully');
    } else {
      alert('Failed to send OTP: ' + response.data.message);
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      alert('Failed to send OTP: ' + error.response.data.message);
    } else {
      alert('Failed to send OTP: Unknown error');
    }
  }
  };

  const verifyOtp = () => {
    //alert(otp+' , '+otpInput);
    if (otp.toString() === otpInput) {
      setIsOtpVerify(true);
      alert('OTP verified successfully');
      handleSubmit1();// Call handleSubmit1 after OTP verification
      setIsModalOpen(false);// Close the OTP verification dialog box
    } else {
      alert('Failed to verify OTP');
      setFailedAttempts(failedAttempts + 1);
    }
  };

  
    useEffect(() => {
      if (ro==='RO BHUBANESWAR') {
        setDos(['DO BALASORE'
  ,'DO BERHAMPUR'
  ,'DO BHUBANESWAR'
  ,'DO CUTTACK'
  ,'DO JEYPORE'
  ,'DO SAMBALPUR'
  ,'DO TITILAGARH'
  ]);
      }
    }, [ro]);


  useEffect(() => {
    // Fetch zones from API or define them statically
    setZones(['ZO (NORTH) - NOIDA', 'ZO (SOUTH) - CHENNAI', 'ZO (EAST) -KOLKATA', 'ZO (NORTH  EAST) - GUWAHATI','ZO (WEST) - MUMBAI', 'IFS, GURGAON']);
  }, []);

  useEffect(() => {
    if (zone==='ZO (NORTH) - NOIDA') {
      // Fetch ROs based on selected zone from API or define them statically
      setRos(['RO PANCHKULA', 'RO JAMMU', 'RO DELHI', 'RO SHIMLA', 'RO CHANDIGARH','RO JAIPUR','RO DEHRADUN','RO LUCKNOW']);
    }
    else if (zone==='ZO (SOUTH) - CHENNAI') {
      // Fetch ROs based on selected zone from API or define them statically
      setRos(['RO CHENNAI','RO TRIVENDRUM','RO HYDERABAD','RO BENGALURU','RO AMARAVATI']);
    }
    else if (zone==='ZO (EAST) -KOLKATA') {
      setRos(['RO BHUBANESWAR','RO RANCHI'  ,'RO WEST BENGAL','RO PATNA']);
    }
else if (zone==='ZO (NORTH  EAST) - GUWAHATI') {
  setRos(['RO ITANAGAR','RO DIMAPUR','RO SHILLONG','RO IMPHAL','RO GUWAHATI']);
    }
    else if (zone==='ZO (WEST) - MUMBAI') {
      setRos(['RO RAIPUR','RO AHMEDABAD','RO MUMBAI','RO BHOPAL']);
    }
    else if (zone==='IFS, GURGAON') {
      setRos([]);
    }}, [zone]);

  useEffect(() => {
    if (ro==='RO CHENNAI') {
      setDos(['DO CHENNAI'
,'DO COIMBATORE'
,'DO CUDDALORE'
,'DO THANJAVUR'
,'DO TUTICORIN'
,'DO VELLORE'
]);
    }
if (ro==='RO TRIVENDRUM') {
  setDos(['DO TRIVENDRUM'
,'DO KOLLAM'
,'DO ALAPPUZHA'
,'DO KOTTAYAM'
,'DO KOCHI'
,'DO THRISSUR'
,'DO PALAKKAD'
,'DO KOZHIKODE'
,'DO KANNUR'

]);
    }
    if (ro==='RO HYDERABAD') {
      setDos(['DO SANATHNAGAR'
    ,'DO TARNAKA'
    ,'DO NALGONDA'
    ,'DO KHAMMAM'
    ,'DO KOCHI'
    ,'DO WARANGAL'
    ,'DO NIZAMABAD'
    ,'DO KARIMNAGAR'
    ]);
        }
        if (ro==='RO BENGALURU') {
          setDos(['DO BENGALURU'
        ,'DO MYSURU'
        ,'DO HUBBALLI'
        ,'DO SHIVAMOGG'
        ,'DO RAICHUR'
        ]);
            }
            if (ro==='RO AMARAVATI') {
              setDos(['DO TADEPALIGUDEM'
            ,'DO GUNTUR'
            ,'DO KAKINADA'
            ,'DO SRIKAKULAM'
            ,'DO PORTBLAIR'
            ,'DO VIZAG'
            ,'DO KURNOOL'
            ,'DO VIJAYAWADA'
            ,'DO NELLORE'
            ]);
                }
    if (ro==='RO BHUBANESWAR') {
      setDos(['DO BALASORE'
,'DO BERHAMPUR'
,'DO BHUBANESWAR'
,'DO CUTTACK'
,'DO JEYPORE'
,'DO SAMBALPUR'
,'DO TITILAGARH'
]);
    }
    if (ro==='RO RANCHI') {
      setDos(['DO RANCHI'
,'DO DHANBAD'
,'DO DEOGHAR'
,'DO DALTONGANJ'
]);
    }
    if (ro==='RO WEST BENGAL') {
      setDos(['DO NPD'
,'DO MIDNAPORE'
,'DO WEST DINAJPUR'
,'DO SILIGURI'
,'DO DURGAPUR'
,'DO MURSHIDABAD'
,'DO NADIA'
,'DO PURULIA'
,'DO GANGTOK'
,'DO BANKURA'
,'DO COOCHBEHAR'
,'DO PD'
,'DO 24 PGS'
,'DO HOOGHLY'
,'DO BIRBHUM'
,'DO MALDA'
,'DO BURDWAN'
]);
    }
    if (ro==='RO PATNA') {
      setDos(['DO PATNA'
,'DO GAYA'
,'DO MUZAFFARPUR'
 , 'DO BHAGALPUR'
,'DO PURNEA'
, 'DO SAHARSA'
,'DO MOTIHAR'
,'DO HAJIPUR'
,'DO SAMASTIPUR'
,'DO DARBHANGA'
,'DO JAMUI'
,'DO ROHTAS'
]);
    }

    if (ro==='RO RAIPUR') {
      setDos(['DO RAIPUR'
,'DO DURG'
,'DO BILASPUR'
]);
    }
    if (ro==='RO AHMEDABAD') {
      setDos(['DO AHMEDABAD'
,'DO BARODA'
,'DO ADIPUR'
,'DO RAJKOT'
]);
    }
    if (ro==='RO MUMBAI') {
      setDos(['DO NAGPUR','DO MANMAD'
,'DO PUNE'
,'DO PANVEL'
,'DO BORIVALI'
,'DO GOA'
,'DO Amravati'
,'DO Aurangabad'

    ]);
    }
    if (ro==='RO BHOPAL') {
      setDos(['DO BHOPAL'
,'DO GWALIOR'
,'DO INDORE'
,'DO UJJAIN',
'DO JABALPUR'
,'DO SAGAR'
,'DO SATNA'
]);
    }
    if (ro==='RO PANCHKULA') {
      setDos(['DO KARNAL'
,'DO KURUKSHETRA'
,'DO HISAR'
,'DO ROHTAK'
,'DO FARIDABAD'
]);
    }
    if (ro==='RO JAMMU') {
      setDos(['DO JAMMU'
,'DO SRINAGAR'
]);
    }
    if (ro==='RO DELHI') {
      setDos(['DO SHAKTINAGAR'
,'DO MAYAPURI'
]);
    }
    if (ro==='RO SHIMLA') {
      setDos(['DO MANDI'
,'DO DHARMSHALA'
]);
    }
    if (ro==='RO CHANDIGARH') {
      setDos(['DO AMRITSAR'
,'DO BHATINDA'
,'DO CHANDIGARH'
,'DO FARIDKOT'
,'DO FEROZEPUR'
,'DO GURDASPUR'
,'DO HOSHIARPUR'
,'DO JALANDHAR'
,'DO KAPURTHALA'
,'DO LUDHIANA'
,'DO MOGA'
,'DO PATIALA'
,'DO SANGRUR'

]);
    }
    if (ro==='RO JAIPUR') {
      setDos(['DO AJMER'
      ,'DO ALWAR','DO BIKANER'
      ,'DO JAIPUR'
      ,'DO JODHPUR','DO KOTA'
      ,'DO SRIGANGANGR'
      ,'DO UDAIPUR'
      ]);
    }
    if (ro==='RO DEHRADUN') {
      setDos(['DO HALDWANI'
      ,'DO DEHRADUN'
      ,'DO Srinagar(UK)'
      ]);
    }
    if (ro==='RO LUCKNOW') {
      setDos(['DO AGRA'
      ,'DO ALIGARH'
      ,'DO PRAYAGRAJ'
      ,'DO AZAMGARH'
      ,'DO BANDA'
      ,'DO BAREILLY',
      ,'DO BULANDSAHAR'
      ,'DO AYODHYA'
      ,'DO GONDA'
      ,'DO GORAKHPUR'
      ,'DO HAPUR'
      ,'DO JHANSI'
      ,'DO KANPUR'
      ,'DO LUCKNOW'
      ,'DO MORADABAD'
      ,'DO SAHARANPUR'
      ,'DO SHAHJAHANPUR'
      ,'DO SITAPUR'
      ,'DO VARANASI']);
    }
    if (ro==='RO ITANAGAR') {
      setDos(['DO BANDERDEWA']);
    }
    if (ro==='RO DIMAPUR') {
      setDos(['DO DIMAPUR']);
    }
    if (ro==='RO SHILLONG') {
      setDos(['DO SHILLONG'
,'DO AIZAWL'
,'DO AGARTALA'
]);
    }
    if (ro==='RO IMPHAL') {
      setDos(['DO IMPHAL']);
    }
    if (ro==='RO GUWAHATI') {
      setDos(['DO DIBRUGARH'
,'DO TEZPUR'
,'DO GUWAHATI'
,'DO NAGAON'
,'DO BONGAIGAON'
,'DO JORHAT'
,'DO SILCHAR'
,'DO NLP'
,'DO KOKRAJHAR'
,]);
    }
  }, [ro]);

  const handleCheckboxChange = (value) => {
    setPostingAt(value);
    setZone('');  // Reset dependent fields
    setRo('');
    setDos([]);
  };


  const handleSubmit1 = async () => {
    const formData1 = new FormData();
    formData1.append('status', status);
    if (status !== 'other' && status !== 'retire') {
      formData1.append('empno', empno);
    }
    formData1.append('name', name);
    // formData1.append('des', des);
    formData1.append('category', selectedCategory);
    formData1.append('cadre', selectedCadre);
    formData1.append('post', posts1);

    formData1.append('gender', gender);
    formData1.append('dob', dob);
        formData1.append('posted', postingAt);

    // if (status !== 'other' && status !== 'retire') {
    //   formData1.append('pop', pop);
    // }
    formData1.append('region', ro);
    formData1.append('zone', zone);
    // formData1.append('ro', ro);
    formData1.append('dodepo', dodepo);
    formData1.append('mobile', mobile);
    formData1.append('email', email);
    // formData1.append('state', state);
    
    // formData1.append('profilePhoto', profilePhoto); off due to client not required
    
    // alert(selectedCategory+","+selectedCadre+","+posts1+","+cadres);
    if (!errors.empno && !errors.mobile) {
      try {
        const response = await axios.post(`${apiBaseUrl}/members`, formData1);
        const result = await response.data;
        if (result.success) {
          alert(`Congratulations on joining BKNK Sangh!! \nYour membership has been provisionally accepted, subject to verification of credentials.\nfor more information visits \nWWW.BKNKS.ORG\n`);
          navigate('/');
        } else {
          alert(result.message);
        }
      } catch (error) {
        if (error.response) {
          alert(error.response.data.message || 'There was an error!');
        } else {
          alert('There was an error!');
        }
      }
    } else {
      alert('Form validation failed');
    }
  };
  useEffect(() => {
    if (failedAttempts >= 5) {
      setTimeout(() => {
        navigate('/');
      }, 1000); // Adjust the delay as needed
    }
  }, [failedAttempts]);

  // Set app element for modal accessibility
  useEffect(() => {
    Modal.setAppElement('#root'); // Assuming your app root element is #root
  }, []);
    
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedCadre, setSelectedCadre] = useState('');
    const [cadres, setCadres] = useState([]);
    const [posts, setPosts] = useState([]);
    const [posts1, setPosts1] = useState('');

    // Handle Category Change
    const handleCategoryChange = (event) => {
      const category = event.target.value;
      setSelectedCategory(category);
      const foundCategory = categories.find(cat => cat.category === category);
      setCadres(foundCategory ? foundCategory.cadres : []);
      setSelectedCadre('');
      setPosts([]);
    };
  
  
    // Handle Cadre Change
    const handleCadreChange = (event) => {
      const cadre = event.target.value;
      setSelectedCadre(cadre);
      const foundCadre = cadres.find(c => c.cadre === cadre);
      setPosts(foundCadre ? foundCadre.posts : []);
      setPosts1(foundCadre ? foundCadre.posts : []);

    };
    const handlePostChange = (event) => {
      const post = event.target.value;
      setPosts1(post);
      setPosts(post);

    };
    const handleStatusChange = (e) => {
      const newStatus = e.target.value;
      setStatus(newStatus);
  
      // Set name to null when status changes
          setEmpno( '');
          setName( '');
          setMobile( '');
          setEmail('');
          setSelectedCadre('');
          setSelectedCategory('');
          setDob('');
    };
    
  return (
    <div className="min-h-screen -mt-12 dark:bg-gray-900 container transition-colors duration-500 bg-white bg-transparent" >
      &nbsp;
      <div className="min-h-screen bg-gray-100 mt-20  pt-4 pb-4 flex items-center justify-center bg-transparent">
        <motion.div
          className="bg-white p-6 rounded-lg shadow-lg w-auto max-w-2xl flex"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
           <div className="w-1/3 pl-2"><br/>
            <img src={v2} alt="Join Form" className="w-44 h-auto" /><br/>
            <h2 className="text-2xl text-center -mx-2 font-bold mr-4 mb-4 caption-top uppercase  text-green-500 shadow-md hover:shadow-green-400 shadow-orange-600">MEMBERSHIP FORM</h2><br/>
            <br/>
            <h5 className="text-sm text-nowrap px-1 -mx-2 mr-4 font-bold mb-1 text-justify text-blue-500"><marquee>All Cat-II, III & IV employees of  FCI are eligible for becoming a Member of BKNK SANGH</marquee></h5>
            <br/>
            <h5 className="text-sm text-wrap px-1 -mx-2 mr-4 font-bold mb-1 text-justify text-gray-500">(As per the Section 6(e) of the Trade Union Act 1926 & Clause 6(a,b,c & e) of the Bye Laws of BKNK SANGH duly approved by the Registrar Trade Union, New Delhi)</h5>

          </div>
          <div className="w-2/3 container pr-0">
            
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-gray-700">Status</label>
                <select
                  className="mt-1 block w-full p-2 border border-gray-300  hover:shadow-orange-600 shadow-green-400  rounded-md shadow-sm"
                  value={status}
                  required
                  // onChange={(e) => setStatus(e.target.value)}
                  onChange={handleStatusChange}

                >
                  <option value="">Select Status</option>
                  <option value="in-service">In Service</option>
                  <option value="retire">Retirees</option>
                  <option value="other">Others</option>
                </select>
              </div>

              {status !== 'other' && status !== 'retire' && (
                <div>
                  <label className="block text-gray-700">Employee Number</label>
                  <input
                    type="text"
                    className="mt-1 block w-full p-2 border border-blue-600 hover:shadow-orange-600 shadow-green-400 rounded-md shadow-md"
                    value={empno}
                    required
                    onChange={(e) => {
                      setEmpno(e.target.value);
                      validateEmpno(e.target.value);
                      if (e.target.value) {
                        fetchEmployeeData(e.target.value);
                      }
                    }}
                  />
                  {errors.empno && <span className="text-red-500 text-sm">{errors.empno}</span>}
                </div>
              )}

              <div>
                <label className="block text-gray-700">Name</label>
                <input
                  type="text"
                  className="mt-1 block w-full p-2 border  border-blue-600 hover:shadow-orange-600 shadow-green-400 rounded-md shadow-md"
                  value={name}
                  required
                  onChange={(e) => setName(e.target.value)}
                  disabled={status === '' || status === 'in-service'} // Disable when status is "in-service" or empty

                />
              </div>





    
    <div class="space-y-4">
  <div class="flex space-x-4">
    <div class="w-1/3">
      <label class="block text-sm font-medium text-gray-700">Select Category:</label>
      <select value={selectedCategory} required onChange={handleCategoryChange} class="mt-1 block w-auto p-2 border border-blue-600 hover:shadow-orange-600 shadow-green-400 rounded-md shadow-md"
        
      >
        <option value="">--Select Category--</option>
        {categories.map(cat => (
          <option key={cat.category} value={cat.category}>{cat.category}</option>
        ))}
                                       

      </select>
    </div>
    <div class="w-2/3">
      <label class="block text-sm font-medium text-gray-700">Select Cadre:</label>
      <select value={selectedCadre} required onChange={handleCadreChange} disabled={!selectedCategory} class="mt-1 block w-auto p-2 border border-blue-600 hover:shadow-orange-600 shadow-green-400 rounded-md shadow-md">
        <option value="">--Select Cadre--</option>
        {cadres.map(cadre => (
          <option key={cadre.cadre} value={cadre.cadre}>{cadre.cadre}</option>
        ))}

      </select>
    </div>
  </div>
  <div>
    <label class="block text-sm font-medium text-gray-700">Select Post:</label>
    <select disabled={!selectedCadre} onChange={(e) => setPosts1(e.target.value)} required class="mt-1 block w-full p-2 border border-blue-600 hover:shadow-orange-600 shadow-green-400 rounded-md shadow-md">
      <option value="">--Select Post--</option>
      {posts.map(post => (
        <option key={post} value={post}>{post}</option>
      ))}

    </select>
  </div>
</div>



     <div>
                <label className="block text-gray-700">Gender</label>
                <select
                  className="mt-1 block w-auto p-2 border border-gray-300 hover:shadow-orange-600 shadow-green-400 rounded-md shadow-md"
                  value={gender}
                  required
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>

                </select>
              </div>

              <div>
                <label className="block text-gray-700">Date of Birth</label>
                <input
                  type="date"
                  className="mt-1 block w-full p-2 border border-gray-300 hover:shadow-orange-600 shadow-green-400 rounded-md shadow-md"
                  value={dob}
                  // value = '1986-07-06'
                  required
                  onChange={(e) => setDob(e.target.value)}
                  disabled={status === '' || status === 'in-service'} // Disable when status is "in-service" or empty

                />
              </div>

              <div>
                <label className="block text-gray-700">Mobile</label>
                <input
                  type="text"
                  className="mt-1 block w-full p-2 border border-gray-300 hover:shadow-orange-600 shadow-green-400 rounded-md shadow-md"
                  value={mobile}
                  required
                  onChange={(e) => {
                    setMobile(e.target.value);
                    validateMobile(e.target.value);
                  }}
                />
                {errors.mobile && <span className="text-red-500 text-sm">{errors.mobile}</span>}
              </div>

              <div>
                <label className="block text-gray-700">Email</label>
                <input
                  type="email"
                  className="mt-1 block w-full p-2 border border-gray-300 hover:shadow-orange-600 shadow-green-400 rounded-md shadow-md"
                  value={email}
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

               <div className="block text-gray-700 mb-4">Posted At:</div>
      <div className="flex items-center mb-4 border-gray-300 hover:shadow-orange-600 shadow-green-400 rounded-md shadow-md">
        <input
          type="radio"
          id="hq"
          name="postingAt"
          value="hq"
          checked={postingAt === 'hq'}
          onChange={() => handleCheckboxChange('hq')}
          className="mr-2"
        />
        <label htmlFor="hq" className="mr-4">HQ</label>

        <input
          type="radio"
          id="zo"
          name="postingAt"
          value="zo"
          checked={postingAt === 'zo'}
          onChange={() => handleCheckboxChange('zo')}
          className="mr-2"
        />
        <label htmlFor="zo" className="mr-4">ZO/IFS</label>

        <input
          type="radio"
          id="ro"
          name="postingAt"
          value="ro"
          checked={postingAt === 'ro'}
          onChange={() => handleCheckboxChange('ro')}
          className="mr-2"
        />
        <label htmlFor="ro" className="mr-4">RO</label>

        <input
          type="radio"
          id="dodepo"
          name="postingAt"
          value="dodepo"
          checked={postingAt === 'dodepo'}
          onChange={() => handleCheckboxChange('dodepo')}
          className="mr-2"
        />
        <label htmlFor="dodepo" className="mr-4">DO/DEPOT</label>
      </div>

      {postingAt === 'zo' && (
        <div>
          <label className="block text-gray-700">Zone</label>
          <select
            className="mt-1 block w-auto p-2 border border-gray-300 rounded-md shadow-sm"
            value={zone}
            onChange={(e) => setZone(e.target.value)}
            required
          >
            <option value="">Select Zone</option>
            {zones.map((zone) => (
              <option key={zone} value={zone}>{zone}</option>
            ))}
          </select>
        </div>
      )}

      {postingAt === 'ro' && (
        <>
          <div>
            <label className="block text-gray-700">Zone</label>
            <select
              className="mt-1 block w-auto p-2 border border-gray-300 rounded-md shadow-sm"
              value={zone}
              onChange={(e) => setZone(e.target.value)}
              required
            >
              <option value="">Select Zone</option>
              {zones.map((zone) => (
                <option key={zone} value={zone}>{zone}</option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-gray-700">RO</label>
            <select
              className="mt-1 block w-auto p-2 border border-gray-300 rounded-md shadow-sm"
              value={ro}
              onChange={(e) => setRo(e.target.value)}
              required
            >
              <option value="">Select RO</option>
              {ros.map((ro) => (
                <option key={ro} value={ro}>{ro}</option>
              ))}
            </select>
          </div>
        </>
      )}

      {postingAt === 'dodepo' && (
        <>
          <div>
            <label className="block text-gray-700">Zone</label>
            <select
              className="mt-1 block w-auto p-2 border border-gray-300 rounded-md shadow-sm"
              value={zone}
              onChange={(e) => setZone(e.target.value)}
              required
            >
              <option value="">Select Zone</option>
              {zones.map((zone) => (
                <option key={zone} value={zone}>{zone}</option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-gray-700">RO</label>
            <select
              className="mt-1 block w-auto p-2 border border-gray-300 rounded-md shadow-sm"
              value={ro}
              onChange={(e) => setRo(e.target.value)}
              required
            >
              <option value="">Select RO</option>
              {ros.map((ro) => (
                <option key={ro} value={ro}>{ro}</option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-gray-700">DO/Depot</label>
            <select
              className="mt-1 block w-auto p-2 border border-gray-300 rounded-md shadow-sm"
              value={dodepo}
              onChange={(e) => setDoDepot(e.target.value)}
              required
            >
              <option value="">Select DO/Depot</option>
              {dos.map((dodepo) => (
                <option key={dodepo} value={dodepo}>{dodepo}</option>
              ))}
            </select>
          </div>
        </>
      )}

              <button
                type="submit"
                className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 transition duration-200"
              >
                Submit
              </button>
            </form>
          </div>
         
        </motion.div>
      </div>
      <div className='py-44 px-44 pb-44 min-w-2 modal-content -z-1'>
        
      <Modal 
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Verify OTP"
        className='py-44 px-44 pb-44 pt-44 text-center'
      >
        <h2 className="modal-title text-lime-700 uppercase ">Verify OTP</h2>
          <p className="modal-description"><div className='px-44 text-green-600 animate-pulse'>Please enter the OTP sent to your email. </div></p>
          
        <input
          type="text"
          value={otpInput}
          onChange={(e) => setOtpInput(e.target.value)}
          placeholder="Enter OTP"
        />
         <div className="modal-actions">
            <button onClick={verifyOtp} className="modal-button verify-button mx-2 my-4 bg-blue-600 text-white p-1 rounded-md ">Verify</button>
            <button onClick={() => setIsModalOpen(false)} className="modal-button cancel-button mx-2 my-4 bg-blue-600 text-white p-1 rounded-md">Cancel</button>
          </div>
      </Modal>
      </div>
    </div>
  );
};

export default JoinForm;
