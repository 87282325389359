import React, {useState } from 'react';
import { motion } from 'framer-motion';

const contactData = [
  { id: 1, name: 'Sama Sathish Reddy', post: 'President', place: 'D.O. Sanathnagar (Telangana)', mobile: '9441786291' },
  { id: 2, name: 'Zaheer Ahmad', post: 'Gen. Secretary', place: 'RO Lucknow UP', mobile: '94540 19555' },
  { id: 3,name:'Anil S Kumbhar ',post:'Sr. Vice President ',place:'Z.O. Mumbai WZ  ',mobile:'98696 01923 '},
{ id: 4,name:'Sukumar Nayak ',post:'Sr.Add. Gen. Secy.     ',place:'D.O. Cuttack (Odisha)   ',mobile:'9437221667 '},
{ id: 5,name:' Anupam Krishna Patel ',post:'Secretary (Fin.) ',place:'R.O. Lucknow  ',mobile:'9451841517 '},
{ id: 6,name:' Ajay Kumar Mishra ',post:'Secretary (Coord.) ',place:'D.O.Patna(Bihar).  ',mobile:'9308742470 '},
{ id: 7,name:'Mukesh Zade ',post:'Secretary (Orgn.) ',place:'D.O. Nagpur (Mah.)  ',mobile:'9890495991 '},
{ id: 8,name:' R.K.Parashar ',post:' Secretary (Welfare) ',place:'D.O.Udaipur(Raj.)  ',mobile:'9414428738 '},
{ id: 9,name:' Manas Kumar Baruah ',post:'Vice President ',place:'Z.O.Guwahati,(NE)  ',mobile:'8752812942 '},
{ id: 10,name:' Suman Ghosh ',post:'Vice President ',place:'Z.O.Kolkata(East)  ',mobile:'9830337662 '},
{ id: 11,name:'Jayesh Kapadia ',post:'Vice President ',place:'RO Ahemdabad, Gujarat WZ  ',mobile:'9998744543 '},
{ id: 12,name:' Nagina Kumar ',post:'Add.Gen.Secretary ',place:'D.O,Durgapur(WB)  ',mobile:'8100254381 '},
{ id: 13,name:'Navin Kumar Shukla ',post:'Add.Gen.Secretary ',place:'RO Bhopal, MP WZ  ',mobile:'9826739057 '},
{ id: 14,name:'Brajen Kumar Das ',post:'Add.Gen.Secretary ',place:'D.O.Guwahati(NE)  ',mobile:'9101039908 '},
{ id: 15,name:'S kartheek ',post:'Add.Gen.Secretary ',place:'RO Amaravathi(AP) SZ  ',mobile:'9704892478 '},
{ id: 16,name:'V.Kamalakar Raju ',post:'JointSecretary ',place:'D.O.Warangal(Telangana)  ',mobile:'8099611652 '},
{ id: 17,name:'Ajit Kumar Dubey ',post:'JointSecretary ',place:'D.O. Ranchi (Jharkhand)  ',mobile:'8340557903 '},
{ id: 18,name:'Yumnam Ronney lal ',post:'JointSecretary ',place:'DO,Imphal(Manipur)  ',mobile:'7628051581 '},
{ id: 19,name:'Parmeshwar Pathak ',post:'JointSecretary ',place:'RO Raipur, Chattisgarh WZ  ',mobile:'8830287516 '},
{ id: 20,name:'Rohit Yadav ',post:'JointSecretary ',place:'D.O.Chandigarh,(Punjab)  ',mobile:'8802396034 '},
{ id: 21,name:'Sunil Dutt Verma ',post:'Secretary(Office) ',place:'FCI,Hqrs.NewDelhi.  ',mobile:'8447421296 '},
{ id: 22,name:'Sanjeev Kumar Kaushik ',post:'Secretary(Office) ',place:'FCI,Hqrs.NewDelhi.  ',mobile:'7988344301 '},

];

const NationalAdv = [
  { id: 1, name: 'Manjit Singh Saini', post: 'Advisor', place: 'DO, Jalandhar, Punjab', mobile: '9417639897' },
  { id: 2, name: 'D.M. Dixit', post: 'Advisor', place: 'DO, Nagpur, Maharastra', mobile: '94221 31921' },
];

const ZORSDP = [
  { id: 1, zone: 'North EAST', name: 'Achut Gogai', post: 'ZONAL PRESIDENT', Desig: 'AG-I(Depot)', mobile: '9854070083' },
  { id: 2, zone: 'North EAST', name: 'Bimal Chaudary', post: 'ZONAL SECRETARY', Desig: 'Manager(Accounts)', mobile: '9435553248' },
  { id: 3,zone:'EAST ',name:'Bivash Biswas ',post:'ZONAL PRESIDENT ',Desig:'AG-I (G)  ',mobile:'9775086897 '},
{ id: 4,zone:'EAST ',name:'Tapan Ghatak ',post:'ZONAL SECRETARY ',Desig:'Manager(General)  ',mobile:'9830964878 '},
{ id: 5,zone:'WEST ',name:'P.R.Datir ',post:'ZONAL PRESIDENT ',Desig:'AG-I(Depot)  ',mobile:'9405030503 '},
{ id: 6,zone:'WEST ',name:'Kanhayya Shirsit ',post:'ZONAL SECRETARY ',Desig:'AG-I(Tech.)  ',mobile:'7666517776 '},
{ id: 7,zone:'NORTH ',name:'Dharmendra Kumar Giri ',post:'ZONAL PRESIDENT ',Desig:'AG-II(Tech.)  ',mobile:'8198933158 '},
{ id: 8,zone:'NORTH ',name:'Deepak Kumar Verma ',post:'ZONAL SECRETRAY  ',Desig:'AG-I(Depot)  ',mobile:'9318414846 '},
{ id: 9,zone:'SOUTH ',name:'Vikram Somanathan ',post:'ZONAL PRESIDENT ',Desig:'AG-I(Tech.)  ',mobile:'9562831296 '},
{ id: 10,zone:'SOUTH ',name:'Mohan  ',post:'ZONAL SECRETARY ',Desig:'AG-II(General)  ',mobile:'97905 07772 '},

];
const ZORSDPNE = [
  { id: 1,Region:'ASSAM ',name:'Tilock Medhi ',post:'Regional President ',Desig:'Manager(accounts)  ',mobile:'9954313252 '},
{ id: 2,Region:'ASSAM ',name:'BANAJIT DAS ',post:'Regional Secretary ',Desig:'AG-I(G)  ',mobile:'9707105465 '},
{ id: 3,Region:'MANIPUR ',name:'TH. BUNTY SINGH ',post:'Regional President ',Desig:'AG-III(Tech.)  ',mobile:'98620 11733 '},
{ id: 4,Region:'MANIPUR ',name:'TH. RAHUL MEITEI ',post:'Regional Secretary ',Desig:'AG-I(G)  ',mobile:'9435707875 '},
{ id: 5,Region:'NAGALAND ',name:'Chumbemo Tuensan ',post:'Regional President ',Desig:'AG-I(Tech.)  ',mobile:'70858 33056 '},
{ id: 6,Region:'NAGALAND ',name:'Longdimen ',post:'Regional Secretary ',Desig:' AG-II (accounts)  ',mobile:'7576958635 '},
{ id: 7,Region:'ARUNACHAL PRADESH ',name:'Om Bhadur Chettry ',post:'Regional President ',Desig:'Ag-II(Accounts)  ',mobile:'89748 54889 '},
{ id: 8,Region:'ARUNACHAL PRADESH ',name:'NA ',post:'Regional Secretary ',Desig:'NA  ',mobile:'BA '},
{ id: 9,Region:'NEF Region ',name:'MEBASHISHA LAITIM ',post:'Regional President ',Desig:'AG-III(Tech.)  ',mobile:'70056 24217 '},
{ id: 10,Region:'NEF Region ',name:'MAYA PYNGROPE ',post:'Regional Secretary ',Desig:'AG-II(Tech.)  ',mobile:'98569 38225 '},

];
const ZORSDPWE = [
  { id: 11,Region:'MAHARASHTRA ',name:'Sh V K SHARMA ',post:'Regional President ',Desig:'AG I (D)  ',mobile:'9022396710 '},
{ id: 12,Region:'MAHARASHTRA ',name:'Sh Ambrit P Mohpatra ',post:'Regional Secretary ',Desig:'AG I (D)  ',mobile:'9702058771 '},
{ id: 13,Region:'MADHYA PRADESH ',name:'Jagdev singh Panwar ',post:'Regional President ',Desig:'AG I (D)  ',mobile:'9926067662 '},
{ id: 14,Region:'MADHYA PRADESH ',name:'Devendra Uraiti ',post:'Regional Secretary ',Desig:'AG I (D)  ',mobile:'7024111141 '},
{ id: 15,Region:'GUJARAT ',name:'Hridayesh Bisht ',post:'Regional President ',Desig:'AG-I (D)  ',mobile:'7383222301 '},
{ id: 16,Region:'GUJARAT ',name:'Jayesh Kapadia ',post:'Regional Secretary ',Desig:'TA-I  ',mobile:'9998744543 '},
{ id: 17,Region:'CHATTISGARH ',name:'Sanyog Tiwari ',post:'Regional President ',Desig:'AG-I(M)  ',mobile:'9630422741 '},
{ id: 18,Region:'CHATTISGARH ',name:'Rajeev kumar ',post:'Regional Secretary ',Desig:'AG-I(D)  ',mobile:'7805078709 '},

];
const ZORSDPNO = [
  { id: 19,Region:'DELHI ',name:'Abhinendra singh ',post:'Regional President ',Desig:'AG-II(D)  ',mobile:'97114 53737 '},
{ id: 20,Region:'DELHI ',name:'Rajan chaudhari ',post:'Regional Secretary ',Desig:'AG-III(Tech.)  ',mobile:'85276 74488 '},
{ id: 21,Region:'HQRS ',name:'Ranjeet Kumar ',post:'Regional President ',Desig:'AG-I(Gen)  ',mobile:'99104 22059 '},
{ id: 22,Region:'HQRS ',name:'Saurabh Sharma ',post:'Regional Secretary ',Desig:'AG-II(Gen)  ',mobile:'99902 16920 '},
{ id: 23,Region:'IFS ',name:'Ranjeet Kumar ',post:'Regional President ',Desig:'AG-I(Gen)  ',mobile:'99104 22059 '},
{ id: 24,Region:'IFS ',name:'Saurabh Sharma ',post:'Regional Secretary ',Desig:'AG-II(Gen)  ',mobile:'99902 16920 '},
{ id: 25,Region:'PUNJAB ',name:'Kulbhushan Singh ',post:'Regional President ',Desig:'AG-I (General)  ',mobile:'7060565613 '},
{ id: 26,Region:'PUNJAB ',name:'Vikas Sharma ',post:'Regional Secretary ',Desig:'AG-I (General)  ',mobile:'9464940036 '},
{ id: 27,Region:'HARYANA ',name:'MANOJ KHUNIDA ',post:'Regional President ',Desig:'AG-I(D)  ',mobile:'9253086259 '},
{ id: 28,Region:'HARYANA ',name:'Himanshu ',post:'Regional Secretary ',Desig:'AG-II(D)  ',mobile:'9315378590 '},
{ id: 29,Region:'HIMACHAL ',name:'NA ',post:'Regional President ',Desig:'NA  ',mobile:'NA '},
{ id: 30,Region:'HIMACHAL ',name:'NA ',post:'Regional Secretary ',Desig:'NA  ',mobile:'NA '},
{ id: 31,Region:'UTTARAKHAND ',name:'Hemlatha ',post:'Regional President ',Desig:'AG-I(D)  ',mobile:'98884 42780 '},
{ id: 32,Region:'UTTARAKHAND ',name:'Nithin ',post:'Regional Secretary ',Desig:'AG-II(G)   ',mobile:'86309 53718 '},
{ id: 33,Region:'RAJASTHAN ',name:'Rajendra Singh Nehra ',post:'Regional President ',Desig:'AG-I(T)   ',mobile:'80940 94272 '},
{ id: 34,Region:'RAJASTHAN ',name:'NA',post:'Regional Secretary ',Desig:'NA ',mobile:'NA '},
{ id: 35,Region:'J&K ',name:'NA',post:'Regional President ',Desig:'NA  ',mobile:'NA '},
{ id: 36,Region:'J&K ',name:'Bhanu Pratap Singh ',post:'Regional Secretary ',Desig:'AG-I(D)   ',mobile:'94191 70099 '},
{ id: 37,Region:'UTTARPRADESH ',name:'Neeraj Sharma ',post:'Regional President ',Desig:'Manager(Depot)  ',mobile:'94124 60525 '},
{ id: 38,Region:'UTTARPRADESH ',name:'Zaheer Ahmad ',post:'Regional Secretary ',Desig:'AG-I(Movement)  ',mobile:'94540 19555 '},

];
const ZORSDPSW = [
  { id: 39,Region:'ANDHRA PRADESH ',name:'G. KANTHI Kiran  ',post:'Regional President ',Desig:'AG-II(Tech.)  ',mobile:'98660 75076 '},
{ id: 40,Region:'ANDHRA PRADESH ',name:'CH. ANIL KRISHNA ',post:'Regional Secretary ',Desig:'AG-II(Tech.)  ',mobile:'94911 18348 '},
{ id: 41,Region:'TELANGANA ',name:'Rajendra Prasad ',post:'Regional President ',Desig:'AG-II(Depot)  ',mobile:'9949951230 '},
{ id: 42,Region:'TELANGANA ',name:'Vishnu Vardhan ',post:'Regional Secretary ',Desig:'AG I - (D)  ',mobile:'7416846632 '},
{ id: 43,Region:'TAMILNADU ',name:'Thomson ',post:'Regional President ',Desig:'AG I - (T)  ',mobile:'98844 17841 '},
{ id: 44,Region:'TAMILNADU ',name:'S Dinesh ',post:'Regional Secretary ',Desig:'AG I - (D)  ',mobile:'88385 16964 '},
{ id: 45,Region:'KERALA ',name:'HAREES K ',post:'Regional President ',Desig:'AG-I(T)   ',mobile:'97468 17458 '},
{ id: 46,Region:'KERALA ',name:'Ajay muralidharan ',post:'Regional Secretary ',Desig:'AG-II(Movt)   ',mobile:'80897 16139 '},
{ id: 47,Region:'KARNATAKA ',name:'Byrappa ',post:'Regional President ',Desig:'AG-1(G)  ',mobile:'9916841013 '},
{ id: 48,Region:'KARNATAKA ',name:'Ramu rayachoti ',post:'Regional Secretary ',Desig:'AG-III(T)  ',mobile:'9704521936 '},

];
const ZORSDPEA = [
  { id: 49,Region:'WEST BENGAL ',name:'Arup Mukherjii ',post:'Regional President ',Desig:'AG-I (G)  ',mobile:'91639 50043 '},
  { id: 50,Region:'WEST BENGAL ',name:'Raja Roy ',post:'Regional Secretary ',Desig:'AG-I (G)  ',mobile:'98313 79474 '},
  { id: 51,Region:'JHARKAND ',name:'Gopal Prasad ',post:'Regional President ',Desig:'Manager  ',mobile:'79923 00251 '},
  { id: 52,Region:'JHARKAND ',name:'Ajit Kumar Dubey ',post:'Regional Secretary ',Desig:'AG II (General)  ',mobile:'83405 57903 '},
  { id: 53,Region:'ODISHA ',name:'Krishna Behera ',post:'Regional President ',Desig:'AG II (General)  ',mobile:'94371 10910 '},
  { id: 54,Region:'ODISHA ',name:'Jitendra Kumar Sahoo ',post:'Regional Secretary ',Desig:'AG -I(Tech.)  ',mobile:' 97773 43472 '},
  { id: 55,Region:'BIHAR ',name:'Shashi Ranjan Kumar ',post:'Regional President ',Desig:'AG -II(GENL.)  ',mobile:'76088 44773 '},
  { id: 56,Region:'BIHAR ',name:'Ajay Kumar Mishra ',post:'Regional Secretary ',Desig:'AG I (General)  ',mobile:'93087 42470 '},
        
];

const tableVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const ContactInfo = () => {
  return (
    <div className="bg-gray-100 min-h-screen p-8">
     
        <div className="bg-blue-700  text-white p-6">
                    <h1 className="text-3xl font-bold text-center">Contact Information</h1>
                </div>
        <ContactAccordion title="National Executive Committee" data={contactData} />
      <ContactAccordion title="National Advisors" data={NationalAdv} />
      <ContactAccordion
        title="Zonal Office Bearers"
        data={ZORSDP}
      />
      {/* <Section title="National Executive Committee">
        <Table data={contactData} />
      </Section>

      <Section title="National Advisors">
        <Table data={NationalAdv} />
      </Section> */}

      {/* <Section title="Zonal Office Bearers">
        <Table data={ZORSDP} isZonal />
      </Section> */}
      <div className="bg-blue-700 text-white p-6">
                    <h1 className="text-3xl font-bold text-center">Regional Secretaries</h1>
                </div>
       <ContactAccordion
        title="North East "
        data={ZORSDPNE}
      />
      
      {/* <Section title="Regional(North-East) Office Bearers">
        <Table1 data={ZORSDPNE} isZonal />
      </Section> */}
      {/* <Section title="West Zone ">
        <Table1 data={ZORSDPWE} isZonal />
      </Section> */}
      <ContactAccordion
        title="North Zone"
        data={ZORSDPNO}
      />
      {/* <Section title="North Zone">
        <Table1 data={ZORSDPNO} isZonal />
      </Section> */}
      <ContactAccordion
        title="South Zone"
        data={ZORSDPSW}
      />
      {/* <Section title="South Zone">
        <Table1 data={ZORSDPSW} isZonal />
      </Section> */}
      {/* <Section title="East Zone">
        <Table1 data={ZORSDPEA} isZonal />
      </Section> */}
      <ContactAccordion
        title="East Zone"
        data={ZORSDPEA}
      />
      <ContactAccordion
        title="West Zone"
        data={ZORSDPWE}
      />
    </div>
  );
};

const Section = ({ title, children }) => (
  <motion.div
    initial="hidden"
    animate="visible"
    variants={tableVariants}
    transition={{ duration: 0.5, ease: 'easeInOut' }}
    className="mb-16"
  >
    <h2 className="text-2xl font-semibold mb-6">{title}</h2>
    {children}
  </motion.div>
);

const Table = ({ data, isZonal = false }) => (
  <div className="overflow-x-auto shadow-md sm:rounded-lg">
    <table className="min-w-full text-sm text-left text-gray-500">
      <thead className="text-xs text-gray-700 uppercase bg-gray-200">
        <tr>
          {isZonal && <th scope="col" className="px-6 py-3">Zone</th>}
          <th scope="col" className="px-6 py-3">Name</th>
          <th scope="col" className="px-6 py-3">Post</th>
          <th scope="col" className="px-6 py-3">{isZonal ? 'Designation' : 'Place'}</th>
          <th scope="col" className="px-6 py-3">Mobile</th>
        </tr>
      </thead>
      <tbody>
        {data.map((contact) => (
          <motion.tr
            key={contact.id}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: contact.id * 0.1 }}
            className="bg-white border-b hover:bg-gray-100"
          >
            {isZonal && <td className="px-6 py-4">{contact.zone}</td>}
            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">{contact.name}</td>
            <td className="px-6 py-4">{contact.post}</td>
            <td className="px-6 py-4">{isZonal ? contact.Desig : contact.place}</td>
            <td className="px-6 py-4">{contact.mobile}</td>
          </motion.tr>
        ))}
      </tbody>
    </table>
  </div>
);
const Table1 = ({ data, isZonal = false }) => (
  <div className="overflow-x-auto shadow-md sm:rounded-lg">
    <table className="min-w-full text-sm text-left text-gray-500">
      <thead className="text-xs text-gray-700 uppercase bg-gray-200">
        <tr>
          {isZonal && <th scope="col" className="px-6 py-3">Region</th>}
          <th scope="col" className="px-6 py-3">Name</th>
          <th scope="col" className="px-6 py-3">Post</th>
          <th scope="col" className="px-6 py-3">Designation</th>
          <th scope="col" className="px-6 py-3">Mobile</th>
        </tr>
      </thead>
      <tbody>
        {data.map((contact) => (
          <motion.tr
            key={contact.id}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: contact.id * 0.1 }}
            className="bg-white border-b hover:bg-gray-100"
          >
            {/* {isZonal && <td className="px-6 py-4">{contact.Region}</td>} */}
            <td className="px-6 py-4">{contact.Region}</td>
            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">{contact.name}</td>
            <td className="px-6 py-4">{contact.post}</td>
            <td className="px-6 py-4">{contact.Desig}</td>
            <td className="px-6 py-4">{contact.mobile}</td>
          </motion.tr>
        ))}
      </tbody>
    </table>
  </div>
);
const ContactAccordion = ({ title, data }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mb-4">
      <motion.div
        initial={false}
        animate={{ backgroundColor: isOpen ? "#4a5568" : "#2d3748" }}
        className="cursor-pointer text-white p-4 rounded-lg"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h2 className="text-lg font-semibold">{title}</h2>
      </motion.div>
      {isOpen && (
        <motion.div
          initial={{ height: 0 }}
          animate={{ height: "auto" }}
          transition={{ duration: 0.5 }}
          className="overflow-hidden bg-white shadow-lg rounded-lg p-4"
        >
          <table className="min-w-full table-auto border-collapse border border-gray-200">
            <thead className="bg-gray-200">
              <tr>
                {Object.keys(data[0]).map((key) => (
                  <th
                    key={key}
                    className="py-2 px-4 border-b border-gray-200 text-left text-sm font-medium text-white"
                  >
                    {key.toUpperCase()}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.id}>
                  {Object.values(item).map((val, idx) => (
                    <td
                      key={idx}
                      className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700"
                    >
                      {val}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </motion.div>
      )}
    </div>
  );
};
export default ContactInfo;
