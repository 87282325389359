import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Collapsible from './Collapsible';
import { Link } from 'react-router-dom';
import DateCalendarCard from './DateCalendarCard';
import WeatherCard from './WeatherCard';
import { motion } from 'framer-motion';
import CustomImageCard from './CustomImageCard';
import Slider from 'react-slick';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;



const NewsItem = ({ news }) => {
  const [open, setOpen] = useState(false);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
        
        <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="mb-3 bg-white shadow-lg rounded-lg overflow-hidden"
    >
      {/* <Card>
        <Slider {...settings}>
          {news.images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Slide ${index}`}
              className="w-full h-64 object-cover"
            />
          ))}
        </Slider>
        <Card.Body>
          <Card.Title onClick={() => setOpen(!open)}>
            {news.title}
          </Card.Title>
        <Collapse in={open}>
          <div>
            <p className="mt-2 text-gray-600">{news.content}</p>
            <table className="table-auto mt-4 w-full">
              <thead>
                <tr>
                  <th className="px-4 py-2">NECZORO</th>
                  <th className="px-4 py-2">ZONE</th>
                  <th className="px-4 py-2">REGION</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border px-4 py-2">{news.NECZORO}</td>
                  <td className="border px-4 py-2">{news.ZONE}</td>
                  <td className="border px-4 py-2">{news.REGION}</td>
                </tr>
              </tbody>
            </table>
            <p className="mt-2 text-gray-600">Published at: {new Date(news.created_at).toLocaleDateString()}</p>
          </div>
        </Collapse>
        </Card.Body>
      </Card> */}
      
    </motion.div>
  );
};

const NewsList = () => {
  const [newsList, setNewsList] = useState([]);

  useEffect(() => {
    axios.get(`${apiBaseUrl}/news`)
      .then(response => setNewsList(response.data))
      .catch(error => console.error('Error fetching news:', error));
  }, []);

  return (
    <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
      {newsList.map(news => (
        <NewsItem key={news.id} news={news} />
      ))}
    </div>
  );
};

const HomePage = () => (
  <div className="container mx-auto px-4">
    <h1 className="text-3xl font-bold my-6">Latest News</h1>
    <NewsList />
  </div>
);

export default HomePage;
