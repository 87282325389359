import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'tailwindcss/tailwind.css';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const MeetingManager = () => {
  const [meetings, setMeetings] = useState([]);
  const [newMeeting, setNewMeeting] = useState({
    date: '',
    title: '',
    agenda: [{ agenda_point: '', agenda_path: '' }],
    minutes: [{ minutes_text: '', minutes_path: '' }]
  });
  const [editingMeetingId, setEditingMeetingId] = useState(null);

  useEffect(() => {
    fetchMeetings();
  }, []);

  const fetchMeetings = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/meetings`);
      const sortedBulletins = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setMeetings(sortedBulletins);
    } catch (error) {
      console.error('Error fetching meetings data', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewMeeting(prevMeeting => ({
      ...prevMeeting,
      [name]: value
    }));
  };

  const handleArrayChange = (arrayName, index, field, value) => {
    const updatedArray = [...newMeeting[arrayName]];
    updatedArray[index][field] = value;
    setNewMeeting(prevMeeting => ({
      ...prevMeeting,
      [arrayName]: updatedArray
    }));
  };

  const addArrayField = (arrayName) => {
    setNewMeeting(prevMeeting => ({
      ...prevMeeting,
      [arrayName]: [...prevMeeting[arrayName], { agenda_point: '', agenda_path: '' }]
    }));
  };

  const removeArrayField = (arrayName, index) => {
    const updatedArray = [...newMeeting[arrayName]];
    updatedArray.splice(index, 1);
    setNewMeeting(prevMeeting => ({
      ...prevMeeting,
      [arrayName]: updatedArray
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!editingMeetingId) {
        await axios.post(`${apiBaseUrl}/meetings`, newMeeting);
      }

      fetchMeetings();
      setNewMeeting({
        date: '',
        title: '',
        agenda: [{ agenda_point: '', agenda_path: '' }],
        minutes: [{ minutes_text: '', minutes_path: '' }]
      });
      setEditingMeetingId(null);
    } catch (error) {
      console.error('Error saving meeting', error);
    }
  };

  const handleEdit = (meeting) => {
    setNewMeeting({
      date: meeting.date,
      title: meeting.title,
      agenda: meeting.agenda || [{ agenda_point: '', agenda_path: '' }],
      minutes: meeting.minutes || [{ minutes_text: '', minutes_path: '' }]
    });
    setEditingMeetingId(meeting.id);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${apiBaseUrl}/meetings/${id}`);
      fetchMeetings();
    } catch (error) {
      console.error('Error deleting meeting', error);
    }
  };


  

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Meetings</h1>
      <form onSubmit={handleFormSubmit} className="mb-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <input
            type="date"
            name="date"
            value={newMeeting.date}
            onChange={handleInputChange}
            className="p-2 border border-gray-300 rounded"
            placeholder="Date"
            required
          />
          <input
            type="text"
            name="title"
            value={newMeeting.title}
            onChange={handleInputChange}
            className="p-2 border border-gray-300 rounded"
            placeholder="Meeting Title"
            required
          />
        </div>

        <div className="mt-4">
          <h3 className="font-bold mb-2">Agenda Points</h3>
          {newMeeting.agenda.map((item, index) => (
            <div key={index} className="flex items-center mb-2">
              <input
                type="text"
                value={item.agenda_point}
                onChange={(e) => handleArrayChange('agenda', index, 'agenda_point', e.target.value)}
                className="flex-1 p-2 border border-gray-300 rounded"
                placeholder="Agenda Point"
                required
              />
              <input
                type="text"
                value={item.agenda_path}
                onChange={(e) => handleArrayChange('agenda', index, 'agenda_path', e.target.value)}
                className="flex-1 p-2 border border-gray-300 rounded ml-2"
                placeholder="Agenda PDF Path"
              />
              {index > 0 && (
                <button
                  type="button"
                  onClick={() => removeArrayField('agenda', index)}
                  className="ml-2 px-2 py-1 bg-red-500 text-white rounded"
                >
                  Remove
                </button>
              )}
            </div>
          ))}
          <button
            type="button"
            onClick={() => addArrayField('agenda')}
            className="px-2 py-1 bg-blue-500 text-white rounded"
          >
            Add More
          </button>
        </div>

        <div className="mt-4">
          <h3 className="font-bold mb-2">Minutes</h3>
          {newMeeting.minutes.map((item, index) => (
            <div key={index} className="flex items-center mb-2">
              <input
                type="text"
                value={item.minutes_text}
                onChange={(e) => handleArrayChange('minutes', index, 'minutes_text', e.target.value)}
                className="flex-1 p-2 border border-gray-300 rounded"
                placeholder="Minutes"
                required
              />
              <input
                type="text"
                value={item.minutes_path}
                onChange={(e) => handleArrayChange('minutes', index, 'minutes_path', e.target.value)}
                className="flex-1 p-2 border border-gray-300 rounded ml-2"
                placeholder="Minutes PDF Path"
              />
              {index > 0 && (
                <button
                  type="button"
                  onClick={() => removeArrayField('minutes', index)}
                  className="ml-2 px-2 py-1 bg-red-500 text-white rounded"
                >
                  Remove
                </button>
              )}
            </div>
          ))}
          <button
            type="button"
            onClick={() => addArrayField('minutes')}
            className="px-2 py-1 bg-blue-500 text-white rounded"
          >
            Add More
          </button>
        </div>

        <button type="submit" className="mt-4 px-4 py-2 bg-green-500 text-white rounded">
          {editingMeetingId ? 'Update Meeting' : 'Add Meeting'}
        </button>
      </form>

      <div className="overflow-x-auto max-h-96 overflow-y-auto">
        <table className="min-w-full bg-white border border-gray-300">
          <thead className="bg-gray-100 sticky top-0">
            <tr>
              <th className="py-2 px-4 border-b">Sl. No</th>
              <th className="py-2 px-4 border-b">Date</th>
              <th className="py-2 px-4 border-b">Title</th>
              <th className="py-2 px-4 border-b">Agenda Points</th>
              <th className="py-2 px-4 border-b">Minutes</th>
              {/* <th className="py-2 px-4 border-b">Agenda PDFs</th>
              <th className="py-2 px-4 border-b">Minutes PDFs</th> */}
              <th className="py-2 px-4 border-b">Actions</th>
            </tr>
          </thead>
          <tbody>
          {/* {meetings.map((meeting, index) => (
  <tr key={meeting.id}>
    <td className="py-2 px-4 border-b">{index + 1}</td>
    <td className="py-2 px-4 border-b">{meeting.date}</td>
    <td className="py-2 px-4 border-b">{meeting.title}</td>
    <td className="py-2 px-4 border-b">
      <ul className="list-disc pl-5">
        {meeting.agenda.map((agendaItem, idx) => (
          <li key={idx}>
            {agendaItem.agenda_point}
            {agendaItem.agenda_path && (
              <a
                href={agendaItem.agenda_path}
                target="_blank"
                rel="noopener noreferrer"
                className="ml-2 text-blue-500"
              >
                (View PDF)
              </a>
            )}
          </li>
        ))}
      </ul>
    </td>
    <td className="py-2 px-4 border-b">
      <ul className="list-disc pl-5">
        {meeting.minutes.map((minutesItem, idx) => (
          <li key={idx}>
            {minutesItem.minutes_text}
            {minutesItem.minutes_path && (
              <a
                href={minutesItem.minutes_path}
                target="_blank"
                rel="noopener noreferrer"
                className="ml-2 text-blue-500"
              >
                (View PDF)
              </a>
            )}
          </li>
        ))}
      </ul>
    </td>
    <td className="py-2 px-4 border-b">
      <button
        onClick={() => handleDelete(meeting.id)}
        className="px-2 py-1 bg-red-500 text-white rounded"
      >
        Delete
      </button>
    </td>
  </tr>
))} */}
{meetings.map((meeting, index) => {
  // Ensure agenda and minutes are parsed as arrays
  let agenda = meeting.agenda || [];
  let minutes = meeting.minutes || [];

  // Parse if necessary (only if they are not arrays yet)
  if (typeof agenda === 'string') {
    try {
      agenda = JSON.parse(agenda);
    } catch (e) {
      console.error('Error parsing agenda:', e);
      agenda = [];
    }
  }

  if (typeof minutes === 'string') {
    try {
      minutes = JSON.parse(minutes);
    } catch (e) {
      console.error('Error parsing minutes:', e);
      minutes = [];
    }
  }

  return (
    <tr key={meeting.id}>
      <td className="py-2 px-4 border-b">{index + 1}</td>
      <td className="py-2 px-4 border-b">{meeting.date}</td>
      <td className="py-2 px-4 border-b">{meeting.title}</td>
      <td className="py-2 px-4 border-b">
        <ul className="list-disc pl-5">
          {agenda.map((agendaItem, idx) => (
            <li key={idx}>
              {agendaItem.agenda_point}
              {agendaItem.agenda_path && (
                <a
                  href={agendaItem.agenda_path}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-2 text-blue-500"
                >
                  (View PDF)
                </a>
              )}
            </li>
          ))}
        </ul>
      </td>
      <td className="py-2 px-4 border-b">
        <ul className="list-disc pl-5">
          {minutes.map((minutesItem, idx) => (
            <li key={idx}>
              {minutesItem.minutes_text}
              {minutesItem.minutes_path && (
                <a
                  href={minutesItem.minutes_path}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-2 text-blue-500"
                >
                  (View PDF)
                </a>
              )}
            </li>
          ))}
        </ul>
      </td>
      <td className="py-2 px-4 border-b">
      <button
        onClick={() => handleDelete(meeting.id)}
        className="px-2 py-1 bg-red-500 text-white rounded"
      >
        Delete
      </button>
    </td>
    </tr>
  );
})}

          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MeetingManager;
