import React from 'react';
import { motion } from 'framer-motion';

const FCIUnionImpactPage = () => {
    const sections = [
        {
            title: "Charter of Demands",
            role: "Union's Role",
            description: "The union acts as the collective voice of the workforce. Through meetings, surveys, and direct communication, union representatives gather the concerns, needs, and desires of the employees. They then consolidate these inputs into a formal document known as the charter of demands.",
            impact: "The union ensures that all workers' issues, from wages to working conditions, are accurately represented. Without the union, individual workers might struggle to communicate their needs effectively to management. The union’s collective approach gives weight to these demands, making it harder for management to ignore or dismiss them. Additionally, the union’s experience in past negotiations helps in drafting realistic yet assertive demands that align with industry standards and legal frameworks."
        },
        {
            title: "Negotiations",
            role: "Union's Role",
            description: "The union enters into negotiations with management, acting as the mediator between workers and employers. These negotiations are formal discussions where both sides present their positions, debate over the demands, and try to reach a compromise. The union representatives are typically experienced negotiators who understand labour laws, market conditions, and the employer's financial situation.",
            impact: "The presence of a union ensures that negotiations are conducted on a more equal footing. While an individual worker may lack the knowledge or power to negotiate effectively, the union's collective bargaining strength gives them a significant advantage. The union can push back against unfair offers, propose counteroffers, and bring expertise to the table, often resulting in better outcomes for the workers. Furthermore, the union can leverage its ability to organize strikes or other collective actions as a bargaining tool, encouraging the employer to offer more favourable terms."
        },
        {
            title: "Collective Bargaining Agreement",
            role: "Union's Role",
            description: "Once an agreement is reached during negotiations, the union plays a crucial role in formalizing this into a collective bargaining agreement (CBA). This document is a legally binding contract that outlines the terms of employment, including wages, hours, benefits, and other working conditions. The union ensures that the language of the CBA is clear and enforceable, and that it accurately reflects the agreed-upon terms.",
            impact: "The union’s involvement in drafting the CBA ensures that the workers' rights and interests are protected. A well-drafted CBA prevents misunderstandings and disputes in the future, as it clearly lays out the obligations of both parties. Moreover, because the CBA is legally binding, the union can take legal action if the employer fails to adhere to its terms, providing workers with a significant level of job security and stability."
        },
        {
            title: "Strikes",
            role: "Union's Role",
            description: "If negotiations reach an impasse and the employer refuses to meet the workers' demands, the union may call for a strike. A strike is a powerful form of protest where workers collectively refuse to work, putting economic pressure on the employer to concede to their demands. The union organizes and coordinates the strike, ensuring that it is conducted legally and effectively.",
            impact: "The ability to organize a strike is one of the most powerful tools at the union’s disposal. It significantly increases the workers' bargaining power, as a well-coordinated strike can disrupt the employer's operations and potentially lead to financial losses. The threat of a strike often encourages employers to return to the negotiation table with more favourable offers. Additionally, the union provides support to striking workers, such as financial assistance or legal protection, which can help sustain the strike and maintain worker solidarity."
        },
        {
            title: "Conciliation",
            role: "Union's Role",
            description: "If a strike is called or a lockout is initiated by the employer, the government may appoint a conciliation officer to mediate the dispute. The union plays a key role in these proceedings, representing the workers’ interests and working towards a settlement. The union may present evidence, argue the workers' case, and propose solutions during conciliation.",
            impact: "The union’s involvement in conciliation ensures that the workers' perspective is effectively communicated to the conciliator and the employer. Since unions are often well-versed in labour laws and industrial relations, they can navigate the conciliation process more effectively than individual workers. This can lead to a quicker and more favourable resolution, potentially avoiding the need for further industrial action or legal proceedings."
        },
        {
            title: "Compulsory Arbitration or Adjudication",
            role: "Union's Role",
            description: "If conciliation fails, the dispute may be referred to compulsory arbitration or adjudication by labor courts, industrial tribunals, or national tribunals. The union represents the workers in these legal processes, presenting their case, submitting evidence, and making legal arguments. The union may also work with lawyers or other experts to strengthen their case.",
            impact: "The union's representation in arbitration or adjudication provides workers with expert advocacy, increasing the likelihood of a favourable outcome. In these proceedings, the union ensures that the workers' legal rights are upheld and that any judgment or award is fair. Moreover, the union’s involvement in these processes can set important precedents that benefit not just their members but the broader workforce in similar industries or regions."
        }
    ];

    return (
        <div className="bg-gray-100 min-h-screen p-8">
            <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="max-w-5xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden"
            >
                <div className="bg-green-700 text-white p-6">
                    <h1 className="text-3xl font-bold text-center">Impact of Unions in Collective Bargaining</h1>
                </div>

                <div className="p-6 space-y-8 text-gray-800">
                    {sections.map((section, index) => (
                        <motion.div
                            key={index}
                            initial={{ opacity: 0, x: -50 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.5, delay: index * 0.1 }}
                            className="space-y-4"
                        >
                            <h2 className="text-2xl font-semibold">{section.title}</h2>
                            <div className="ml-4">
                                <h3 className="text-xl font-semibold text-green-700 text-justify">{section.role}</h3>
                                <p className='text-justify'>{section.description}</p>
                                <h3 className="text-xl font-semibold text-green-700 mt-4 text-justify">Impact</h3>
                                <p className='text-justify'>{section.impact}</p>
                            </div>
                        </motion.div>
                    ))}
                </div>
                <h3 className="p-6 text-xl font-semibold text-green-700 text-justify">Overall Impact of Unions in Collective Bargaining</h3>
            <p className='text-justify p-6 mx-4'>Unions are essential in ensuring that workers' rights and interests are protected throughout the collective bargaining process. Their expertise, organizational power, and ability to mobilize workers give them significant leverage in negotiations with employers. Through collective bargaining, unions help secure better wages, improved working conditions, and greater job security for workers.
<br/>Moreover, unions also play a critical role in enforcing the terms of collective bargaining agreements and in resolving disputes that arise during the employment relationship. By providing a collective voice to workers, unions ensure that the balance of power between employers and employees is more equitable, leading to fairer and more just outcomes in the workplace.
</p>
            </motion.div>
            
        </div>
    );
};

export default FCIUnionImpactPage;
