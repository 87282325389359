import React from 'react';
import { motion } from 'framer-motion';

const EDMessageCard = () => {
  return (
    <motion.div
      className="max-w-lg mx-auto bg-white rounded-xl shadow-lg overflow-hidden transform transition-all hover:scale-105 duration-500"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
    >
      <div className="bg-gradient-to-r from-blue-500 to-purple-600 p-6">
        <h2 className="text-2xl font-bold text-white">Message from the ED</h2>
      </div>
      <div className="p-6">
        <p className="text-gray-700 text-lg leading-relaxed">
          Dear ,
          <br /><br />
fci
          <br /><br />
          Best Regards,
          <br />
          <strong>Executive Director</strong>
        </p>
      </div>
    </motion.div>
  );
};

export default EDMessageCard;
