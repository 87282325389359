import React from 'react';
import Slider from 'react-slick';
import styles from './HeroSection.module.css';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Typical from 'react-typical';
import EDMessageCard from './EDMessageCard';
import LetterCard from './LetterCard';
import v01 from '../IMG_MSG/edmsg_eng.jpeg';

 const HeroSection2 = () => {
//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     cssEase: 'ease-in-out',
//   };
const settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  cssEase: 'ease-in-out',
  appendDots: dots => (
    <ul className={styles.slickDots}> {dots} </ul>
  ),
  customPaging: i => (
    <div className={styles.slickDot}>
      <button className={styles.slickButton}></button>
    </div>
  ),
};
  return (
    <section className="flex h-auto overflow-hidden ">
      {/* Stylish Welcome Union Section */}
      <div className="w-2/5 h-auto flex items-center justify-center bg-gradient-to-r from-blue-900 to-purple-900 relative">

        <div className="text-white text-center items-left">
          {/* <h1 className="text-7xl perspective-3d font-extrabold mb-4 animate-pulse tracking-wide transform transition-transform hover:skew-y-1">
            Welcome Union
          </h1> */}
          <motion.div
        className="text-center text-orange-500 " 
        // text-white
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <br></br>
        <h1 className="text-7xl perspective-3d font-extrabold mb-4 animate-pulse tracking-wide transform transition-transform hover:skew-y-1" >
        <Typical
        className='text-4xl perspective-3d font-extrabold mb-4 text-white tracking-wide transform transition-transform hover:skew-y-1'
        steps={['Welcome to Our Union', 3000, '', 500]}
        loop={Infinity}
        wrapper="p"
      />
        
        </h1>
        <p className="mt-4 text-xl text-white font-extrabold">BHARATIYA KHADYA NIGAM KARAMCHARI SANGH</p>
      </motion.div>
      <p className="text-center  max-w-xl text-wrap  text-lg mb-0 pt-2 text-w text-orange-500 border-x-green-400"><b>The Bharatiya Khadya Nigam Karmachari Sangh (BKNKS),  REGD.NO.2217/ND,  the sole nationally recognized employees' union of the Food Corporation of India since 1977. Our objectives are dedicated to enhance the welfare, rights, and professional growth of all FCI employees. </b></p>  
    
<br/>
          <p className="text-xl opacity-90 transform transition-transform hover:scale-105 text-orange-500 ">
            Join us in making a difference!
          </p>
          {/* <LetterCard image={v01} /> */}
          

      </div>

        {/* Background Animation */}
        <div className="absolute inset-0 bg-cover bg-center opacity-10" style={{ backgroundImage: `url('../logo.png')` }}></div>

      </div>
      {/* 3D Image Slider */}
     
      <div className="w-3/5 h-full perspective-3d space-x-1 space-y-1 ">
        <Slider {...settings}>
          <div className="transform transition-transform hover:scale-100">
            <img
              src="../banner1a.jpeg"
              alt="Slide 1"
              className="h-full w-full object-cover rounded-lg shadow-lg transform hover: hover:"
            />
          </div>
          <div className="transform transition-transform hover:scale-100">
            <img
              src="../banner2a.jpeg"
              alt="Slide 2"
              className="h-full w-full object-cover rounded-lg shadow-lg transform hover: hover:"
            />
          </div>
          <div className="transform transition-transform hover:scale-100">
            <img
              src="../banner3a.jpeg"
              alt="Slide 3"
              className="h-full w-full object-cover rounded-lg shadow-lg transform hover: hover:"
            />
          </div>
          {/* <div className="transform transition-transform hover:scale-100">
            <img
              src="../banner3a.jpeg"
              alt="Slide 4"
              className="h-full w-full object-cover rounded-lg shadow-lg transform hover: hover:"
            />
          </div> */}
        </Slider>
        {/* <br/> */}
       
          <motion.div
        className="text-center text-orange-500 items-center" 
        // text-white
        initial={{ opacity: 1, y: 0 }}
        animate={{ opacity: 1, y: 4, infinite:false}}
        // transition={{ duration: 1 }}
        whileInView={{ opacity: 1, x:0, y:0}}
        transition={{ duration: 2, repeat: Infinity,  type: "smooth",
        repeatType: "mirror",}}
        
      >
        
        {/* <br></br> */}
        {/* <h1 className="text-6xl font-bold" >
        <Typical steps={['Welcome to Our Union', 2000,'Welcome to Our Union',2000]} loop={Infinity} wrapper="p" /></h1>
         */}
        <p className="mt-0 text-2xl text-white font-extrabold">BHARATIYA KHADYA NIGAM KARAMCHARI SANGH</p>
        <p className="text-xl opacity-90 transform animate text-white font-bold items-center text-center uppercase transition-transform hover:scale-105 ">
            {/* Join us in making a difference! */}
            Sangharsh Kabhi Ruka Nahin; Sangh Kabhi Thaka Nahin
          </p>
          {/* <Link  to='/jf' className="text-center items-center text-sm hover:text-gray-300 border-l-2 border-r-2 pl-0 pr-0 text-teal-300 border-green-200 animate-pulse"><big><strong>JOIN BKNKS</strong></big></Link> */}

      </motion.div>
    
         
      </div>

      
    </section>
  );
};

export default HeroSection2;
