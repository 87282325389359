import React, { useState, useEffect } from 'react';
import axios from 'axios';
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const ImageUpload = () => {
  const [files, setFiles] = useState([]);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [NECZORO, setNECZORO] = useState('');
  const [images, setImages] = useState([]);

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      const res = await axios.get(`${apiBaseUrl}/images`);
      setImages(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  const handleFilesChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const validFiles = selectedFiles.filter(file => file.size <= 1 * 1024 * 1024); // 1MB limit

    if (validFiles.length !== selectedFiles.length) {
      setMessage('Some files are too large and were not selected.');
    } else {
      setMessage('');
      setFiles(validFiles);
    }
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    Array.from(files).forEach((file) => {
      formData.append('images', file);
    });
    formData.append('title', title);
    formData.append('NECZORO', NECZORO);

    try {
      const res = await axios.post(`${apiBaseUrl}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setMessage(res.data.message);
      fetchImages(); // Refresh the image list after upload
    } catch (err) {
      console.error(err);
      setMessage('Failed to upload images.');
    }
  };

  const handleDelete = async (id) => {
    try {
      const res = await axios.delete(`${apiBaseUrl}/images/${id}`);
      setMessage(res.data.message);
      fetchImages(); // Refresh the image list after deletion
    } catch (err) {
      console.error(err);
      setMessage('Failed to delete the image.');
    }
  };

  return (
    <div className="App header min-h-screen -mt-12 dark:bg-gray-900 transition-colors duration-500 bg-white" style={{ backgroundImage: `url(${'/OIP2.jfif'})`, backgroundSize: 'cover' }}>
      &nbsp;
      <div className=" mt-32 mb-2 glass max-w-4xl mx-auto p-4 bg-white shadow-md rounded-md">
        <h2>Upload Images</h2>
        <form onSubmit={handleUpload}>
          Image Title
          <input
            type="text"
            placeholder="Enter title"
            value={title}
            onChange={handleTitleChange}
          />
          <br />
          Select image from 
          <select onChange={(e) => setNECZORO(e.target.value)} className="form-input" size='3'>
            <option selected>NEC</option>
            <option>Veteran</option>
            <option>ZO</option>
            <option>RO</option>
          </select>
          <input type="file" multiple onChange={handleFilesChange} /><br /><br />
          <button type="submit" className=' bg-orange-500 py-1 px-1'>Upload</button>
        </form>
        {message && <p>{message}</p>}

        {/* Table for displaying images */}
        {images.length > 0 && (
          <div className="mt-4">
            <h3>Uploaded Images</h3>
            <table className="table-auto w-full border-collapse table-container overflow-y-auto overflow-x-auto max-w-full max-h-full">
              <thead>
                <tr>
                  <th className="border px-4 py-2">Image</th>
                  <th className="border px-4 py-2">Title</th>
                  <th className="border px-4 py-2">NECZORO</th>
                  <th className="border px-4 py-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {images.map((image) => (
                  <tr key={image.id}>
                    <td className="border px-4 py-2">
                      <img src={`${apiBaseUrl.substring(0, apiBaseUrl.length - 4)}/uploads/${image.image}`} alt={image.title} className="w-16 h-16 object-cover" />
                    </td>
                    <td className="border px-4 py-2">{image.title}</td>
                    <td className="border px-4 py-2">{image.NECZORO}</td>
                    <td className="border px-4 py-2">
                      <button onClick={() => handleDelete(image.id)} className="bg-red-500 text-white px-2 py-1 rounded">Delete</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageUpload;
