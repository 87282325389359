import React from 'react';

const ScrollToTopButton = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="fixed bottom-4 right-4">
      <button
        onClick={scrollToTop}
        className="bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded-full shadow-lg"
      >
        Scroll to Top
      </button>
    </div>
  );
};

export default ScrollToTopButton;
