import React from 'react';

import { motion } from 'framer-motion';

const achievements = [
    {
        title: "Negotiation of Wage Revision w.e.f. 01.01.2017",
        points: [
            "BKNK Sangh has successfully negotiated through 13 rounds of meetings to secure a wage revision, ensuring a fitment factor beyond 3 in each scale for Category-III employees and nearly 3 for Category-IV employees.",
            "Additionally, a 35% cafeteria allowance (32% Fringe benefit, 2% PLI, and 1% LTC), in line with those for Category-I and II posts, has been extended to Category-III and IV employees.",
            "BKNK Sangh has effectively advocated for keeping the Over Time Allowance (OTA) excluded from the 35% cap on Perks and Allowances.",
            "The Sangh has been a strong proponent for the reimbursement of medical expenses on an actual basis, aligning the benefits for Category-III and IV employees with those of Category-I and II employees."
        ],
    },
    {
        title: "Pension Scheme Introduction",
        points: [
            "BKNK Sangh has ensured employee superannuation by introducing a Pension Scheme effective from 01.12.2008, with a 2% contribution from employees and a 10% contribution from the employer.",
            "The Sangh also facilitated the transition from the earlier DCPS scheme from LIC to the NPS, ensuring easier accessibility, taxation benefits, and higher returns on investment for employees."
        ],
    },
    {
        title: "PRMS Scheme Implementation",
        points: [
            "BKNK Sangh successfully implemented the Post-Retirement Medical Scheme (PRMS) for all employees starting 01.04.2016, where the management contributes 3.67% of the Basic Pay towards the PRMS fund for each employee."
        ],
    },
    {
        title: "Stagnation Impact Amelioration Scheme (SIAS):",
        points: [
            "The Sangh introduced the SIAS for Category-III and IV employees, granting an additional increment after 6 years in a single post, another increment after an additional 6 years, and a third increment after a further 8 years."
        ],
    },
    {
        title: "Accelerated Promotion Scheme:",
        points: [
            "Through persistent efforts, BKNK Sangh has seen the introduction of departmental examinations for accelerated promotions from Category-III to II and Category-II to I after 3 years of service."
        ],
    },
    {
        title: "Brief Case Reimbursement for Cat-III & IV:",
        points: [
            "The proposal for briefcase reimbursement has been approved, providing a significant yet symbolic benefit for Category-III and IV employees."
        ],
    },
    {
        title: "Additional Transportation Allowance for Divyangjan Employees:",
        points: [
            "BKNK Sangh has secured an additional 6% of Basic Pay as a Transportation Allowance for Divyangjan employees, demonstrating its commitment to inclusivity."
        ],
    },
    {
        title: "Resolution of Recruitment Discrepancies in 2020:",
        points: [
            "Addressing technical issues that hindered the recruitment of OBC/EWS candidates in 2020, BKNK Sangh's intervention ensured the appointment of hundreds of affected candidates."
        ],
    },
    {
        title: "Newly Recruited employees through Waiting List: ",
        points: ["Due to the unprecedented Covid-19 pandemic, waiting list candidates were very much disappointed & were showing great feeling of anxiety. At this time also BKNK Sangh shown deep concern on their issue and taken up the matter for which the matter was resolved."
        ],
    },{
        title: "Freezed Daily Allowances: ",
        points: [
            "BKNK Sangh succeeded in resolving the issue of freezing of DA w.e.f. 01.10.2020 by the management and the freezed daily allowance was released in respect of Cat-III & IV employees. "
        ],
    },{
        title: "Major changes in Transfer Policy: ",
        points: [
            "Denial of posting of Home Town to newly recruitee Cat-III policy has been removed.",
            "Clause of Mutual Transfer has been introduced which is a big achievement.",
"Employees getting inter-zonal permanent/tenure transfer are now entitled for transfer benefit.",
"Qualifying eligibility period for inter-zonal permanent/tenure transfer has been reduced from four years to two years.",
"In case of threat to life, no qualifying eligibility is required.",
"Newly recruited Ex-servicemen employees will be given preferred posting.",
"Widow/Single Parents employees belonging to Cat-III & Cat-II will be exempted from routine transfer.",
"Employees appointed on compassionate ground will be posted to their preferred zonal/regional/Divisional Office subject to availability.",
"Employees, whose children are studying in Class X & XII, will be exempted from routine transfer.",
"Cat-II & III employees will be transfer back to his/her preferred region on completion of normal tenure.",
"The normal tenure that was missing during the incorporation of transfer policy in respect of Cat-III & IV employees has now been inserted so that the employees will be eligible for transfer grants on own request.",
"An employee completing an effective tenure of 24 months in NE Zone has to give three preferences of Zones or Hqrs which was earlier confined to Cat-I officers only."

        ],
    },
    {
        title: "Holding the QC SOP: ",
        points: [
            "BKNK Sangh achieved the major feet by keeping the QC SOP on halt and giving a major relief to the Technical Cadre employees in FCI. "
        ],
    },
    {
        title: "Increase in Composite DA and Daily Allowance: ",
        points: [
            "BKNK Sangh succeeded in increasing the composite DA from Rs. 760 to Rs. 1630 and from Rs. 620 to Rs. 1330 for X & Y and for other cities respectively for Cat-III & Rs. 920 & Rs. 770 for Cat-IV more than the double and daily allowance for AG-I from Rs. 340 & Rs. 260 to Rs. 730 and Rs. 560 respectively, for AG-II & AG-III & Cat-IV from Rs. 240 & Rs. 210 to Rs. 520 and Rs. 450 respectively. "
        ],
    },
    {
        title: "Increase in Memento Amount:  ",
        points: [
            "BKNK Sangh succeeded in increasing the amount by hundred percent increase from Rs. 5000 to Rs. 10,000/-"
        ],
    },
    {
        title: "Improvement in Post-Retirement Medical Scheme: ",
        points: [
            "The ceiling limit of OPD reimbursement was increased from Rs. 18,000 to Rs. 32,000/-",
            "Annual ceiling for IPD under clause 6.6 of Circular dated 30.12.2016 has been increased from Rs. 1,00,000 to Rs. 1,70,000.00.",
            "The Ceiling under Clause 6.8 for major diseases has been increased from Rs. 4,00,000 to Rs. 20,00,000/-",
            "The Ceiling under clause 6.9 for exceptional cases of life-saving indoor treatments has been increased from Rs. 3,00,000 to Rs. 15,00,000/-.",
            "In modification to clause 7.8, it has been decided to allow condonation of delay for a period of 3 (three) months beyond the stipulated six months’ time period for submission of claims under PRMS.",
            "The DoP for approving the Bill has been raised from Rs. 10,000 to Rs. 25,000/-of the Divisional Manager."
            
        ],
    },
    {
        title: "EPS-95 Pension on Actual Salary:",
        points: [
            "BKNK Sangh along with retired federation has managed to give EPS’95 on actual salary contesting in the Supreme Court."
            
        ],
    },
    // Add more achievements here
];

const FCIMajorAchievements = () => {
    return (
        <div className="min-h-screen  py-10 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
                <div className="bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:p-6">
                        <h1 className="text-3xl font-bold text-gray-900 mb-8 uppercase underline decoration-orange-500">Major Achievements of BKNK Sangh in the Preceding Years</h1>
                        <div className="space-y-8">
                            {achievements.map((achievement, index) => (
                                <div key={index} className="bg-green-50 p-6 rounded-lg shadow">
                                    <motion.h2
              key={achievement.title}
              className="text-2xl font-semibold text-blue-700 mb-4 underline decoration-orange-500 bg-transparent rounded-lg overflow-hidden transform transition-all duration-300 hover:scale-105"
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
                                    <span className='no-underline'>{index+1}.&nbsp;</span>{achievement.title}
                                    </motion.h2>
                                    <ul className="list-disc pl-6 space-y-2 text-gray-700">
                                        {achievement.points.map((point, idx) => (
                                            <li key={idx} className="flex items-start">
                                                <span className="mr-2 text-blue-600">•</span>
                                                <motion.span
                                                 initial={{x: 10 }}
                                                 whileHover={{  x: 20 }}
                                                 className='hover:font-semibold'
                                                >{point}</motion.span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                        <h3 className="p-6 text-xl font-semibold text-green-700 text-justify">The above are the some of the achievements made by BKNK Sangh during the past few years. BKNK Sangh remains dedicated to championing the rights and welfare of its members, striving for equitable benefits, and fostering a supportive work environment within the Food Corporation of India.</h3>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FCIMajorAchievements;
