import React, { useState, useEffect, useContext  } from 'react';
import { BrowserRouter, BrowserRouter as Router, Route, Routes, useLocation  } from 'react-router-dom';
import Footer from './components/Footer';
import Home from './components/Home';
import './index.css';

import { AuthProvider } from './components/AuthContext';
import AdminPanel from './components/AdminPanel';
import Login from './components/Login';

import Signup1 from './components/Signup1';

import AboutUs from './components/Aboutus';
import CrudPage from './components/CrudPage';

import MemberJoinForm from './components/MemberJoinForm';
import MemberList from './components/MemberList';
import WeatherCard from './components/WeatherCard';
import NewsForm from './components/NewsForm';
import LatestNews1 from './components/LatestNews1';
import GalleryPage from './components/GalleryPage';
import BulletinForm from './components/BulletinForm';
import BulletinList from './components/BulletinList';
import NewsEdit from './components/NewsEdit';
import NewsForm1 from './components/NewsForm1';
import NavbarComponent from './components/NavbarComponent';
import 'bootstrap/dist/css/bootstrap.min.css';
// import PrivateRoute from './components/PrivateRoute';
// import Header_main from './components/Header_main';
import ImageUpload from './components/ImageUpload';
import TeamUploadForm from './components/TeamUploadForm';
import JoinForm from './components/JoinFrom';
import Layout from './components/Layout';
import DonationForm from './components/DonationForm';
import DonationList from './components/DonationList';
import Profile from './components/Profile';
import PrivateRoute from './components/PrivateRoute';


import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FCIGlance from './components/FCIGlance';
import FCIMission from './components/FCIMission';
import VeteranPage from './components/VeteranPage';
import Meeting from './components/Meeting';
import MeetingAdd from './components/MeetingAdd';
import Bulletin_final_Add from './components/Bulletin_final_Add';
import Bulletin_final from './components/Bulletin_final';
import FCIGlance1 from './components/FCIGlance1';
import ScrollToTop from './ScrollToTop';
import NewsUpload from './components/NewsUpload';
import axios from 'axios';
import MeetingManager from './components/MeetingManager';
import Bulletin_final_big from './components/Bulletin_final_big';
import Meeting_big from './components/Meeting_big';
import Preloader from './Preloader'; // Your custom Preloader component
import HeaderSlider from './components/HeaderSlider';
import ScrollToTopButton from './components/ScrollToTopButton';
import HeroSection from './components/HeroSection';
import SliderToggle from './components/SliderToggle';
import AboutUs1 from './components/Aboutus1';
import Navbar_final2 from './components/Navbar_final12';
import FCIObjectives from './components/FCIObjective';
import FCITargetsAndProposals from './components/FCITargetsAndProposals';
import FCIUnionImpactPage from './components/FCIUnionImpactPage';
import FCIStatusOfRecognition from './components/FCIStatusOfRecognition';
import FCIMajorAchievements from './components/FCIMajorAchievements';
import YoutubeadminPage from './components/Youtubeadminpage';
import Meeting_big_big from './components/Meeting_big_big';
import Latestnews2_accordionbig from './components/Latestnews2_accordianbig';
import ContactInfo from './components/ContactInfo';
import Navbar_final12 from './components/Navbar_final12';
import Navbar_final3 from './components/Navbar_final3';
import FCIHistory from './components/FCIHistory';
import ProfilePage from './components/ProfilePage';
import ProfilePage1 from './components/ProfilePage1';
// import { AuthContext } from './AuthContext';
// import ProfilePage from './components/profilepage';
//  require('dotenv').config();
import { useUser } from './components/AuthContext';


const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;



function App() {
  //for dark mode settings
  const [darkMode, setDarkMode] = useState(false);

  
  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        // Fetch visitor's IP address
        const ipResponse = await axios.get('https://api.ipify.org?format=json');
        const ipAddress = ipResponse.data.ip;
       
        // Send visitor data to the backend
        await axios.post(`${apiBaseUrl}/visit`, { ip_address: ipAddress });
      } catch (error) {
        console.error('Error sending visitor data:', error);
      }
    };

    fetchIpAddress();
  }, []);


  useEffect(() => {
    // alert(apiBaseUrl);
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);
//
// used for right click disable
    // useEffect(() => {
    //   const handleContextMenu = (e) => {
    //     e.preventDefault();
    //   };
    //   document.addEventListener('contextmenu', handleContextMenu);
    //   return () => {
    //     document.removeEventListener('contextmenu', handleContextMenu);
    //   };
    // }, []);
//


axios.get(`${apiBaseUrl}/endpoint`)
  .then(response => {
    // handle success
  })
  .catch(error => {
    // handle error
  });


  // const { user: user11 } = useContext(AuthContext);
  // alert(user11.adminflag+ ',a '+ user11.name+ ',a '+ user11.password+ ' ,a '+user11.approvereject_status);


  // const [loading, setLoading] = useState(true);
  // const [pageLoading, setPageLoading] = useState(false);
  // const location = useLocation();

  // useEffect(() => {
  //   // Hide the initial preloader after the app loads
  //   const initialPreloader = document.getElementById('initial-preloader');
  //   if (initialPreloader) {
  //     initialPreloader.classList.add('hidden');
  //   }
  //   setLoading(false);
  // }, []);

  // useEffect(() => {
  //   if (!loading) {
  //     setPageLoading(true);
  //     const timer = setTimeout(() => {
  //       setPageLoading(false);
  //     }, 1000); // Adjust timing as needed

  //     return () => clearTimeout(timer);
  //   }
  // }, [location]);

  // const { user: user11 } = useContext(AuthContext);
  

// const adminFlag = user11?.adminflag ?? 2;
// const approveRejectStatus = user11?.approvereject_status ?? 'not approved';


  return (
    <>
    
    <Layout>
    <AuthProvider>
    <Router basename={process.env.PUBLIC_URL}>
      {/* <div className="App header min-h-screen -mt-12 dark:bg-gray-900 transition-colors duration-500 bg-white" style={{ backgroundImage: `url(${'/OIP2.jfif'})`, backgroundSize: 'cover' }}> */}
      
        {/* <Navbar darkMode={darkMode} setDarkMode={setDarkMode}/> */}
        {/* <HeaderSlider/> */}
        {/* <PrivateRoute> */}
        {/* <Navbar_final12/> */}

        <Navbar_final3/>

        {/* <HeroSection/> */}


        {/* <NavbarComponent darkMode={darkMode} setDarkMode={setDarkMode}/> */}

        {/* </PrivateRoute> */}


        <div className="flex-grow pt-0  bg-transparent">
          {/* top padding pt use */}
          <Routes>
            
            {/* <Route exact path="/" element={<Home/>} /> */}
            <Route path="/" element={<Home />} />
            <Route path="/Veteran" element={<VeteranPage/>} />
            <Route path="/FCIObjectives" element={<FCIObjectives/>} /> 
            <Route path="/FCIHistory" element={<FCIHistory/>} /> 

            <Route path="/OurAchievements" element={<FCIMajorAchievements/>} /> 
            <Route path="/FCITargets" element={<FCITargetsAndProposals/>} /> 
            <Route path="/Contact" element={<ContactInfo/>} /> 

            <Route path="/gallery" element={<GalleryPage/>} />
            <Route path="/jf" element={<JoinForm/>}/>

            <Route path="/news" element={<Latestnews2_accordionbig className='top-0 mx-0 z-10'/>} />
            <Route path="/Meetings" element={<Meeting_big_big/>}/>
            <Route path="/Demands" element={<Bulletin_final_big/>}/>
            <Route path="/BKNKS_UnionImpact" element={<FCIUnionImpactPage/>}/>
            <Route path="/statusRecognisation" element={<FCIStatusOfRecognition/>}/>

            <Route path="/fciglance" element={<FCIGlance/>} /> {/* Add Auth Route */}
            <Route path="/Roleincovid19" element={<FCIGlance1/>} /> {/* Add Auth Route */}

            <Route path="/about" element={<AboutUs1/>} /> {/* Add Auth Route */}
            <Route path="/donation" element={<DonationForm/>} />
            <Route path="/login" element={<Login/>} />


            {/* {role === 'Admin' && ( */}
              
                          <>
            <Route path="/admin" element={<PrivateRoute><AdminPanel/></PrivateRoute> }/>
            <Route path="/ap" element={<PrivateRoute><CrudPage/></PrivateRoute>}/>
            <Route path="/nb" element={<PrivateRoute><NewsForm1/></PrivateRoute>} />
            <Route path="/nedit" element={<PrivateRoute><NewsEdit/></PrivateRoute>} />
            <Route path="/bf" element={<PrivateRoute><Bulletin_final_Add/></PrivateRoute>} />
            {/* <Route path="/bl" element={<PrivateRoute><BulletinList/></PrivateRoute>} /> */}
            <Route path="/imageupload" element={<PrivateRoute><ImageUpload/></PrivateRoute>} />
            <Route path="/youtubevideo" element={<PrivateRoute><YoutubeadminPage/></PrivateRoute>} />

            <Route path="/newsupload" element={<PrivateRoute><NewsUpload/></PrivateRoute>} />

            <Route path="/meetingadd" element={<PrivateRoute><MeetingManager/></PrivateRoute>} />
            <Route path="/Teammemberupload" element={<PrivateRoute><TeamUploadForm/></PrivateRoute>} />
            </>
            {/* )}  */}

            <Route path="/profile" element={<PrivateRoute><ProfilePage user11/></PrivateRoute>} />
            <Route path="/donationlist" element={<PrivateRoute><DonationList/></PrivateRoute>} />
                  

            {/* <Route path="/darkMode" element={<ThemeToggle darkMode={darkMode} setDarkMode={setDarkMode}/>} /> */}
          </Routes>

          {/* <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Veteran" element={<VeteranPage/>} /> 
            <Route path="/fcimission" element={<FCIMission/>} /> 
            <Route path="/gallery" element={<GalleryPage/>} />
            <Route path="/jf" element={<JoinForm/>}/>

            <Route path="/news" element={<LatestNews1/>} />
            <Route path="/Meetings" element={<Meeting/>}/>
            <Route path="/Demands" element={<Bulletin_final/>}/>

            <Route path="/fciglance" element={<FCIGlance/>} /> 

            <Route path="/about" element={<AboutUs/>} /> 
            <Route path="/donation" element={<DonationForm/>} />
            <Route path="/login" element={<Login/>} />

            <Route path="/admin" element={<PrivateRoute><AdminPanel/></PrivateRoute> }/>
            <Route path="/ap" element={<PrivateRoute><CrudPage/></PrivateRoute>}/>
            <Route path="/nb" element={<PrivateRoute><NewsForm1/></PrivateRoute>} />
            <Route path="/nedit" element={<PrivateRoute><NewsEdit/></PrivateRoute>} />
            <Route path="/bf" element={<PrivateRoute><Bulletin_final_Add/></PrivateRoute>} />
            <Route path="/imageupload" element={<PrivateRoute><ImageUpload/></PrivateRoute>} />
            <Route path="/newsupload" element={<PrivateRoute><NewsUpload/></PrivateRoute>} />

            <Route path="/meetingadd" element={<PrivateRoute><MeetingAdd/></PrivateRoute>} />
            <Route path="/Teammemberupload" element={<PrivateRoute><TeamUploadForm/></PrivateRoute>} />
            <Route path="/donationlist" element={<PrivateRoute><DonationList/></PrivateRoute>} />
                  

          </Routes> */}
        </div>
        {/* <div className="min-h-screen bg-gradient-to-r from-blue-400 to-purple-500 flex items-center justify-center">
      
    </div> */}
    
        
       
      {/* </div> */}
    </Router>
    <ToastContainer />

    </AuthProvider>
   

    <Footer />
    <ScrollToTopButton/>
      </Layout></> 
  );
}

export default App;
