import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'tailwindcss/tailwind.css';
import bg1_section from '../loginbg.jpg';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const BulletinFinalAdd = () => {
  const [bulletins, setBulletins] = useState([]);
  const [newBulletin, setNewBulletin] = useState({
    date: '',
    bulletin_name: '',
    pdf_pathtitle: ''
  });
  const [newMinutes, setNewMinutes] = useState(['']);
  const [newPdfPaths, setNewPdfPaths] = useState(['']);
  const [editingBulletinId, setEditingBulletinId] = useState(null);

  useEffect(() => {
    fetchBulletins();
  }, []);

  const fetchBulletins = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/bulletins`);
      const sortedBulletins = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setBulletins(sortedBulletins);
    } catch (error) {
      console.error('Error fetching bulletins data', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewBulletin(prevBulletin => ({
      ...prevBulletin,
      [name]: value
    }));
  };

  const handleMinutesChange = (index, value) => {
    const updatedMinutes = [...newMinutes];
    updatedMinutes[index] = value;
    setNewMinutes(updatedMinutes);
  };

  const addMinutesField = () => {
    setNewMinutes([...newMinutes, '']);
  };

  const removeMinutesField = (index) => {
    const updatedMinutes = [...newMinutes];
    updatedMinutes.splice(index, 1);
    setNewMinutes(updatedMinutes);
  };

  const handlePdfPathChange = (index, value) => {
    const updatedPdfPaths = [...newPdfPaths];
    updatedPdfPaths[index] = value;
    setNewPdfPaths(updatedPdfPaths);
  };

  const addPdfPathField = () => {
    setNewPdfPaths([...newPdfPaths, '']);
  };

  const removePdfPathField = (index) => {
    const updatedPdfPaths = [...newPdfPaths];
    updatedPdfPaths.splice(index, 1);
    setNewPdfPaths(updatedPdfPaths);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const bulletinData = { 
        ...newBulletin, 
        pdf_pathtitle: newBulletin.pdf_pathtitle, 
        pdf_path: newPdfPaths, 
        minutes: newMinutes 
      };
  
      if (!bulletinData.date || !bulletinData.bulletin_name || !bulletinData.pdf_pathtitle) {
        throw new Error('Date, Bulletin Name, and PDF Path Title are required.');
      }
  
      if (editingBulletinId) {
        await axios.put(`${apiBaseUrl}/bulletins/${editingBulletinId}`, bulletinData);
      } else {
        await axios.post(`${apiBaseUrl}/bulletins`, bulletinData);
      }
  
      fetchBulletins();
      setNewBulletin({
        date: '',
        bulletin_name: '',
        pdf_pathtitle: ''
      });
      setNewMinutes(['']);
      setNewPdfPaths(['']);
      setEditingBulletinId(null);
    } catch (error) {
      console.error('Error saving bulletin', error);
    }
  };

  const handleEdit = (bulletin) => {
    setNewBulletin({
      date: bulletin.date,
      bulletin_name: bulletin.bulletin_name,
      pdf_pathtitle: bulletin.pdf_pathtitle
    });
    setNewMinutes(bulletin.minutes_of_bulletin);
    setNewPdfPaths(bulletin.pdf_paths);
    setEditingBulletinId(bulletin.id);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${apiBaseUrl}/bulletins/${id}`);
      fetchBulletins();
    } catch (error) {
      console.error('Error deleting bulletin', error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Bulletins</h1>
      <form onSubmit={handleFormSubmit} className="mb-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <input
            type="date"
            name="date"
            value={newBulletin.date}
            onChange={handleInputChange}
            className="p-2 border border-gray-300 rounded"
            placeholder="Date"
            required
          />
          <input
            type="text"
            name="bulletin_name"
            value={newBulletin.bulletin_name}
            onChange={handleInputChange}
            className="p-2 border border-gray-300 rounded"
            placeholder="Bulletin Title"
            required
          />
          <input
            type="text"
            name="pdf_pathtitle"
            value={newBulletin.pdf_pathtitle}
            onChange={handleInputChange}
            className="p-2 border border-gray-300 rounded"
            placeholder="PDF Path Title"
            required
          />
        </div>
        <div className="mt-4">
          <h3 className="font-bold mb-2">Minutes</h3>
          {newMinutes.map((minutes, index) => (
            <div key={index} className="flex items-center mb-2">
              <input
                type="text"
                value={minutes}
                onChange={(e) => handleMinutesChange(index, e.target.value)}
                className="flex-1 p-2 border border-gray-300 rounded"
                placeholder="Minutes"
                required
              />
              {index > 0 && (
                <button
                  type="button"
                  onClick={() => removeMinutesField(index)}
                  className="ml-2 px-2 py-1 bg-red-500 text-white rounded"
                >
                  Remove
                </button>
              )}
            </div>
          ))}
          <button
            type="button"
            onClick={addMinutesField}
            className="px-2 py-1 bg-blue-500 text-white rounded"
          >
            Add More
          </button>
        </div>
        <div className="mt-4">
          <h3 className="font-bold mb-2">PDF Paths</h3>
          {newPdfPaths.map((pdfPath, index) => (
            <div key={index} className="flex items-center mb-2">
              <input
                type="text"
                value={pdfPath}
                onChange={(e) => handlePdfPathChange(index, e.target.value)}
                className="flex-1 p-2 border border-gray-300 rounded"
                placeholder="PDF Path"
                required
              />
              {index > 0 && (
                <button
                  type="button"
                  onClick={() => removePdfPathField(index)}
                  className="ml-2 px-2 py-1 bg-red-500 text-white rounded"
                >
                  Remove
                </button>
              )}
            </div>
          ))}
          <button
            type="button"
            onClick={addPdfPathField}
            className="px-2 py-1 bg-blue-500 text-white rounded"
          >
            Add More
          </button>
        </div>
        <button type="submit" className="mt-4 px-4 py-2 bg-green-500 text-white rounded">
          {editingBulletinId ? 'Update Bulletin' : 'Add Bulletin'}
        </button>
      </form>
      <div className="overflow-x-auto max-h-96 overflow-y-auto">
        <table className="min-w-full bg-white border border-gray-300" >
          <thead className="bg-gray-100 sticky top-0" >
            <tr>
              <th className="py-2 px-4 border-b w-16">Sl. No</th>
              <th className="py-2 px-4 border-b w-44">Date</th>
              <th className="py-2 px-4 border-b w-1/2">Demand's Title</th>
              <th className="py-2 px-4 border-b">U.C Note</th>
              {/* minutes */}
              <th className="py-2 px-12 border-b w-1/6">PDFs</th>
              <th className="py-2 px-4 border-b w-1/8">Actions</th>
            </tr>
          </thead>
          <tbody>
            {bulletins.map((bulletin, index) => (
              <tr key={bulletin.id} >
                <td className="py-2 px-4 border-b">{index + 1}</td>
                <td className="py-2 px-4 border-b">{bulletin.date}</td>
                <td className="py-2 px-4 border-b col-span-2 gap-20 capitalize">
                <ExpandableText text={bulletin.bulletin_name} />
                <br/>
                  <a href={bulletin.pdf_pathtitle} target="_blank" rel="noopener noreferrer">
                    
                   PDF's
                    </a>....
                </td>
                <td className="py-2 px-4 border-b">
                  <ul className=" list-disc pl-5">
                    {bulletin.minutes_of_bulletin.map((minutes,pdfPath, idx) => (
                      <li key={idx}>
                        
                        <a href={bulletin.pdf_paths[idx]} target="_blank" rel="noopener noreferrer">
                        {minutes}
                        </a>
                        </li>
                    ))}
                  </ul>
                </td>
                <td className="py-2 px-12 border-b">
                  <ul className="list-disc pl-5">
                    {bulletin.pdf_paths.map((pdfPath, idx) => (
                      <li key={idx}>
                        <a href={pdfPath} target="_blank" rel="noopener noreferrer">
                          Link {idx+1}
                        </a>
                      </li>
                    ))}
                  </ul>
                </td>
                <td className="py-2 px-4 border-b">
                  {/* <button
                    onClick={() => handleEdit(bulletin)}
                    className="px-2 py-1 bg-yellow-500 text-white rounded mr-2"
                  >
                    Edit
                  </button> */}
                  <button
                    onClick={() => handleDelete(bulletin.id)}
                    className="px-2 py-1 bg-red-500 text-white rounded"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};


const ExpandableText = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 50; // Set a limit for the text length before truncation

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div onClick={handleToggleExpand} className="cursor-pointer">
      {isExpanded ? text : `${text.slice(0, maxLength+15)}${text.length > maxLength+15 ? '....' : ''}`}
      {text.length > maxLength+15 && (
        <span className="text-blue-500 ml-2">
          {isExpanded ? ' Less' : ' More'}
        </span>
      )}
    </div>
  );
};

export default BulletinFinalAdd;
