import React from 'react';
import { motion } from 'framer-motion';
//import bg1 from '../23.jpg';
import bg1 from '../OIP2.jfif';


const Layout = ({ children }) => {
  return (
    // <div className="relative min-h-screen">
        <div className="App header context max-h-screen max-w-full -mt-12 z-10  items-center justify-center bg-[#rgb(23,51,35)]" >
          {/* <div className="App header  min-h-screen -mt-12 z-10  items-center justify-center bg-[#rgb(23,51,35)]" style={{ backgroundImage: `url(${bg1})`, width: 'auto',marginLeft: '-0px',backgroundRepeat: 'repeat', height: 'auto', backgroundSize: 'cover' }}></div> */}
      {/* <motion.div
        // className=" inset-0 bg-cover bg-center"
        //  style={{ backgroundImage: "url('/OIP2.jfif')" }}
        // initial={{ opacity: 0 }}
        // animate={{ opacity: 1 }}
        // transition={{ duration: 1 }}
      /> */}
       

      {/* <div className="relative App header -mt-12 z-10 flex flex-col items-center justify-center min-h-screen bg-black bg-opacity-50"> */}
        {children}
      {/* </div> */}
    </div>
  );
};

export default Layout;
