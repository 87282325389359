import React, { useState } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const DonationForm = () => {
  const [donorName, setDonorName] = useState('');
  const [email, setEmail] = useState('');
  const [amount, setAmount] = useState('');
  const [message, setMessage] = useState('');
  const [receiptUrl, setReceiptUrl] = useState('');
  const [empno, setEmpno] = useState('');
  const [mobile, setMobile] = useState('');

  const [errors, setErrors] = useState({});


  const validateEmpno = (value) => {
    const errors = {};
    if (!/^\d{6}$/.test(value)) {
      errors.empno = 'Employee number must be exactly 6 digits.';
    }
    setErrors(errors);
  };

  const validateMobile = (value) => {
    const errors = {};
    if (!/^\d{10}$/.test(value)) {
      errors.mobile = 'Mobile number must be exactly 10 digits.';
    }
    setErrors(errors);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiBaseUrl}/donations`, {
        empno: empno,
        donor_name: donorName,
        mobile: mobile,
        email,
        amount,
        message,
        receipt_url: receiptUrl
      });
      // alert(receiptUrl);
      alert('Donation submitted successfully');
      // Reset form
      setDonorName('');
      setEmail('');
      setEmpno('');
      setMobile('');
      setAmount('');
      setMessage('');
      setReceiptUrl('');
    } catch (error) {
      console.error('There was an error submitting the donation:', error);
      alert('Error submitting donation'+ error);
    }
  };

  return (
    <motion.div
      className="max-w-lg mx-auto p-4  rounded-lg shadow-md mt-0 mb-2 bg-transparent"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <p> <br/><br/><br/><br/></p>
      <h2 className="text-2xl font-bold mb-4 text-white">Donate Now</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-white">Name</label>
          <input
            type="text"
            className="w-full px-3 py-2 border rounded"
            value={donorName}
            onChange={(e) => setDonorName(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-white">EMP No. (if available)</label>
          <input
            type="text"
            className="w-full px-3 py-2 border rounded"
            value={empno}
            onChange={(e) => {
              setEmpno(e.target.value);
              validateEmpno(e.target.value);
            }}
            
          />
           <label className="block text-white">Mobile No.</label>
          <input
            type="text"
            className="w-full px-3 py-2 border rounded"
            value={mobile}
            onChange={(e) => {
                  setMobile(e.target.value);
                  validateMobile(e.target.value);
                }}
            
          />
        </div>
        <div className="mb-4">
          <label className="block text-white">Email</label>
          <input
            type="email"
            className="w-full px-3 py-2 border rounded"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-white">Amount</label>
          <input
            type="number"
            className="w-full px-3 py-2 border rounded"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-white">Message</label>
          <textarea
            className="w-full px-3 py-2 border rounded"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div>
        {/* <div className="mb-4">
          <label className="block text-gray-700">Receipt URL</label>
          <input
            type="text"
            className="w-full px-3 py-2 border rounded"
            value={receiptUrl}
            onChange={(e) => setReceiptUrl(e.target.value)}
          />
        </div> */}
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded"
        >
          Submit Donation
        </button>
      </form>
    </motion.div>
  );
};

export default DonationForm;
