import React, { useState } from 'react';

const FCIHistory = () => {
  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  return (
    <div className="max-w-4xl mx-auto p-4 mt-10">
      <h1 className="text-3xl font-bold text-center mb-8 bg-blue-700 uppercase text-white">History of BKNKS: From Inception to National Recognition</h1>
      <div className="space-y-4">
        
        {/* Section 1 */}
        <div>
          <div 
            className="bg-blue-500 text-white p-4 rounded-lg uppercase text-xl cursor-pointer" 
            onClick={() => toggleSection(1)}>
            Early Beginnings and Formation
          </div>
          {openSection === 1 && (
            <div className="p-4 bg-gray-100 rounded-lg text-justify"> 
              <p>
                The roots of BKNKS trace back to the inception of the Food Corporation of India (FCI), where the initial staff were Food Transferees deputed from the Regional Directorate of Food. Recognizing the need to secure their demands and address their concerns, these pioneering employees formed an association under the leadership of the esteemed Late V. Narayan ji. This early association aimed to represent and advocate for the Food Transferees, setting the foundation for future labour organization within FCI.
              </p>
            </div>
          )}
        </div>

        {/* Section 2 */}
        <div>
          <div 
            className="bg-blue-500 text-white p-4 rounded-lg cursor-pointer uppercase text-xl " 
            onClick={() => toggleSection(2)}>
            Expansion and Emergence of New Unions
          </div>
          {openSection === 2 && (
            <div className="p-4 bg-gray-100 rounded-lg text-justify">
              <p>
                As FCI expanded, new recruitments led to the formation of a separate union specifically for these new employees, known as the FCI Employees Union. The 1970s saw both the original association of Food Transferees and the newly formed FCI Employees Union acknowledging the benefits of unity. Consequently, an alliance was formed between the two entities. During this period, various regional unions emerged across different zones in India. In the North Zone, the union was known as BKNK Sangh; in the East and South Zones, it was referred to as the Employees Staff Union; and in the West Zone, it was called the FCI Employees Association. Despite these different names, all these unions shared a common objective and continued to operate under the banner of BKNK, each working towards the welfare of FCI employees.
              </p>
            </div>
          )}
        </div>

        {/* Section 3 */}
        <div>
          <div 
            className="bg-blue-500 text-white p-4 rounded-lg cursor-pointer uppercase text-xl " 
            onClick={() => toggleSection(3)}>
            Transition to IDA Pattern and National Coordination
          </div>
          {openSection === 3 && (
            <div className="p-4 bg-gray-100 text-justify rounded-lg">
              <p>
                The transition from the CDA (Central Dearness Allowance) pattern to the IDA (Industrial Dearness Allowance) pattern in FCI underscored the necessity for a robust union capable of effective collective bargaining. In response, a National Coordination Committee was formed to represent the BKNK Sangh and its tributaries across India. This joint front played a crucial role in negotiating wages and addressing employee issues at a national level. During this period, the checkoff mode of elections was introduced for union recognition in FCI.
              </p>
            </div>
          )}
        </div>

        {/* Section 4 */}
        <div>
          <div 
            className="bg-blue-500 text-white p-4 rounded-lg cursor-pointer uppercase text-xl " 
            onClick={() => toggleSection(4)}>
            Challenges and Legal Battles
          </div>
          {openSection === 4 && (
            <div className="p-4 bg-gray-100 rounded-lg text-justify">
              <p>
                In 1985, the checkoff mode of elections faced challenges, leading to the introduction of secret ballot elections in FCI by the verdict of the Hon’ble Supreme Court after approximately a decade. Despite BKNK Sangh & its associate Unions securing the maximum votes in the 1995 elections, its recognition at the national level was contested by Other Sister Union and the FCI management. This dispute ultimately ruled against the BKNK Sangh, leading to a shift in recognition to the other Union for the only time in FCI history.
              </p>
            </div>
          )}
        </div>

        {/* Section 5 */}
        <div>
          <div 
            className="bg-blue-500 text-white p-4 rounded-lg cursor-pointer uppercase text-xl " 
            onClick={() => toggleSection(5)}>
            Reconstitution and National Recognition
          </div>
          {openSection === 5 && (
            <div className="p-4 bg-gray-100 text-justify rounded-lg">
              <p>
                Following the dispute arises in the year 1995, BKNKS underwent a significant reorganization. By amalgamating all the tributaries of BKNK Sangh, the newly formed BKNKS contested the next Secret Ballot Election for the recognition and won the 2004 staff union elections, emerging as the undisputed and nationally recognized union within FCI. This victory solidified BKNKS's position and influence within the organization.
              </p>
            </div>
          )}
        </div>

        {/* Section 6 */}
        <div>
          <div 
            className="bg-blue-500 text-white p-4 uppercase text-xl  rounded-lg cursor-pointer" 
            onClick={() => toggleSection(6)}>
            Recent Achievements and Leadership
          </div>
          {openSection === 6 && (
            <div className="p-4 text-justify bg-gray-100 rounded-lg">
              <p>
                BKNKS has continued to strengthen its position and influence over the years. In 2019, the union achieved national recognition for the fourth time, reaffirming its status and effectiveness. The historical Wage Revision w.e.f. 01.01.2017 commended by BKNK Sangh is a major achievement in comparison to its contemporaries.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FCIHistory;
