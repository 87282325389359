import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Link } from 'react-router-dom';
import { Form, Button, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useUser } from './AuthContext';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

const NewsForm1 = ({ bulletinToEdit, onFormSubmit }) => {
  const { user } = useUser();

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [date, setDate] = useState('');
  const [NECZORO, setNECZORO] = useState('NA');
  const [NEC, setNEC] = useState(false);
  const [NECValue, setNECValue] = useState('');
  const [ZONE, setZONE] = useState('');
  const [REGION, setREGION] = useState('');
  const [url, setUrl] = useState('https://bknks.org');
  const [place, setPlace] = useState('');
  
  const [images, setImages] = useState([]);
  const [newsItems, setNewsItems] = useState([]);
  
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (bulletinToEdit) {
      setTitle(bulletinToEdit.title);
      setContent(bulletinToEdit.content);
      setDate(bulletinToEdit.date);
      setNECZORO(bulletinToEdit.NECZORO);
      setNEC(bulletinToEdit.NEC === 'HQ');
      setNECValue(bulletinToEdit.NEC);
      setZONE(bulletinToEdit.ZONE);
      setREGION(bulletinToEdit.REGION);
      setUrl(bulletinToEdit.url);
      setPlace(bulletinToEdit.place);
    }
    fetchNewsItems();
  }, [bulletinToEdit]);

  const fetchNewsItems = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/news`);
      setNewsItems(response.data);
    } catch (error) {
      console.error('Error fetching news items:', error);
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const validFiles = files.filter(file => file.size < 1048576).slice(0, 10);
    setImages(validFiles);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('content', content);
    formData.append('date', date);
    formData.append('NECZORO', NECZORO? 'HQ':'NA');
    formData.append('NEC', NEC ? 'HQ' : 'NA');
    formData.append('ZONE', ZONE);
    formData.append('REGION', REGION);
    formData.append('url', url);
    formData.append('place', place);
    if(images){
    for (let i = 0; i < images.length; i++) {
      formData.append('images', images[i]);
    }
  }

    try {
      await axios.post(`${apiBaseUrl}/news`, formData);
      alert('Successfully added to the news database!');
      setTitle('');
      setContent('');
      setDate('');
      setNECZORO('NA');
      setNEC(false);
      setNECValue('');
      setZONE('');
      setREGION('');
      setUrl('https://bknks.org');
      setPlace('');
      setImages([]);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      fetchNewsItems();
      onFormSubmit(); // Optional: Trigger a callback to update the bulletin list after form submission
    } catch (error) {
      console.error('Error submitting bulletin:', error);
    }
  };

  const handleDelete = async (id) => {
    alert(apiBaseUrl);
    try {
      await axios.delete(`${apiBaseUrl}/news/${id}`);
      alert('News deleted successfully!');
      fetchNewsItems();
    } catch (error) {
      console.error('Error deleting news:', error);
    }
  };

  return (
    <div className="App header min-h-screen -mt-12 dark:bg-gray-900 transition-colors duration-500 bg-white" style={{ backgroundImage: `url(${'/OIP2.jfif'})`, backgroundSize: 'cover' }}>
      &nbsp;
      <div className="container mt-32 mb-2 glass mx-auto bg-white shadow-md rounded px-8 pt-6 pb-8">
        <form onSubmit={handleFormSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="date">
              Date
            </label>
            <input
              type="date"
              id="date"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
              Title
            </label>
            <input
              type="text"
              id="title"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="content">
              Content
            </label>
            <ReactQuill
              id="content"
              value={content}
              onChange={setContent}
              className="bg-white"
              modules={{ toolbar: [['bold', 'italic', 'underline'], [{ list: 'bullet' }]] }}
              placeholder="Write your bulletin content..."
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="NECZORO">
              NECZORO
            </label>
            <input
              type="text"
              id="NECZORO"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value='NA'
              onChange={(e) => setNECZORO(e.target.value)}
              required
              disabled
            />
          </div>
          <div className="mb-4">
            <Form.Check
              type="checkbox"
              id="NEC"
              label="NEC"
              checked={NEC}
              onChange={(e) => setNEC(e.target.checked)}
              className="mb-2"
            />
          </div>
          {!NEC && (
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="NECValue">
                NEWS FOR NEC & ZONE & REGION
              </label>
              <input
                type="text"
                id="NECValue"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={NECValue}
                onChange={(e) => setNECValue(e.target.value)}
                disable
              />
            </div>
          )}
          {!NEC && (
            <>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="ZONE">
                  NEWS FOR ZONE NAME
                </label>
                <input
                  type="text"
                  id="ZONE"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={ZONE}
                  onChange={(e) => setZONE(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="REGION">
                  NEWS FOR REGION NAME
                </label>
                <input
                  type="text"
                  id="REGION"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={REGION}
                  onChange={(e) => setREGION(e.target.value)}
                  required
                />
              </div>
            </>
          )}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="url">
              URL
            </label>
            <input
              type="text"
              id="url"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value='https://bknks.org'
              onChange={(e) => setUrl(e.target.value)}
              disabled
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="place">
              Place
            </label>
            <input
              type="text"
              id="place"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={place}
              onChange={(e) => setPlace(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <Form.Group controlId="images">
              <Form.Label>Images (max. 10 files, each below 1 MB)</Form.Label>
              <Form.Control
                type="file"
                multiple
                onChange={handleImageChange}
                ref={fileInputRef}
              />
            </Form.Group>
          </div>
          <div className="flex items-center justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Add News
            </button>
          </div>
        </form>

        <Table striped bordered hover className="mt-4">
          <thead>
            <tr>
              <th>#</th>
              <th>Date</th>
              <th>Title</th>
              <th>NECZORO</th>
              <th>NEC</th>
              <th>ZONE</th>
              <th>REGION</th>
              <th>URL</th>
              <th>Place</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {newsItems.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{item.date}</td>
                <td>{item.title}</td>
                <td>{item.NECZORO}</td>
                <td>{item.NEC}</td>
                <td>{item.ZONE}</td>
                <td>{item.REGION}</td>
                <td>{item.url}</td>
                <td>{item.place}</td>
                <td>
                  <Button variant="danger" onClick={() => handleDelete(item.id)}>
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default NewsForm1;
