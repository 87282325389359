import React from 'react';

const StatusOfRecognition = () => {
    return (
        <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
                <h1 className="text-4xl font-extrabold text-gray-900 mb-12 text-center">Status of Recognition</h1>

                {/* Section 1: Legal Status and Recognition */}
                <div className="mb-16">
                    <h2 className="text-3xl font-bold text-indigo-600 mb-6">Legal Status and Recognition</h2>
                    <div className="space-y-8">
                        <div>
                            <h3 className="text-2xl font-semibold text-gray-800">Recognized Union</h3>
                            <ul className="list-disc list-inside ml-6 text-justify mt-2 text-gray-700">
                                <li><span className="font-semibold">Legal Status:</span> A recognized union is officially acknowledged by the employer and/or the government as the legitimate representative of the workers in a particular organization or industry.</li>
                                <li><span className="font-semibold">Recognition Process:</span> Recognition is usually granted based on specific legal criteria, such as the union representing a majority of the workforce or being elected through a formal process. In India, for example, recognition is often governed by statutes like the Industrial Disputes Act, 1947, and the Trade Unions Act, 1926.</li>
                                <li><span className="font-semibold">Implications:</span> Once recognized, the union has the legal authority to represent the workers in negotiations, sign collective bargaining agreements, and participate in grievance redressal mechanisms.</li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-2xl font-semibold text-gray-800">Non-Recognized Union</h3>
                            <ul className="list-disc list-inside ml-6 text-justify mt-2 text-gray-700">
                                <li><span className="font-semibold">Legal Status:</span> A non-recognized union does not have formal recognition by the employer or the government. It may still represent the interests of a group of workers, but it does not have the same legal standing as a recognized union.</li>
                                <li><span className="font-semibold">Implications:</span> Non-recognized unions do not have the legal authority to negotiate on behalf of the entire workforce or to enter into binding agreements with the employer. Their role is often limited to representing the interests of their members on an informal basis.</li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* Section 2: Role in Collective Bargaining */}
                <div className="mb-16">
                    <h2 className="text-3xl font-bold text-indigo-600 mb-6">Role in Collective Bargaining</h2>
                    <div className="space-y-8">
                        <div>
                            <h3 className="text-2xl font-semibold text-gray-800">Recognized Union</h3>
                            <ul className="list-disc list-inside ml-6 text-justify mt-2 text-gray-700">
                                <li><span className="font-semibold">Role:</span> The recognized union is the primary body for collective bargaining with the employer. It is involved in drafting the charter of demands, negotiating terms and conditions of employment, and finalizing collective bargaining agreements.</li>
                                <li><span className="font-semibold">Authority:</span> The recognized union’s agreements are binding on all workers covered by the union, including non-members. It can represent workers in legal disputes and in negotiations over wages, working conditions, and other employment terms.</li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-2xl font-semibold text-gray-800">Non-Recognized Union</h3>
                            <ul className="list-disc list-inside ml-6 mt-2 text-justify text-gray-700">
                                <li><span className="font-semibold">Role:</span> Non-recognized unions may still engage in discussions with the employer, but these interactions are informal. They might present their own demands or grievances, but they do not have the same authority to negotiate on behalf of the workforce.</li>
                                <li><span className="font-semibold">Authority:</span> Any agreements or understandings reached by a non-recognized union may not be binding on the employer or the broader workforce.</li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* Section 3: Participation in Dispute Resolution */}
                <div className="mb-16">
                    <h2 className="text-3xl font-bold text-indigo-600 mb-6">Participation in Dispute Resolution</h2>
                    <div className="space-y-8">
                        <div>
                            <h3 className="text-2xl font-semibold text-gray-800">Recognized Union</h3>
                            <ul className="list-disc list-inside ml-6 mt-2 text-justify text-gray-700">
                                <li><span className="font-semibold">Participation:</span> Recognized unions have the right to participate in formal dispute resolution processes, such as conciliation, arbitration, and adjudication. They are often the primary representative body in legal disputes involving labour relations.</li>
                                <li><span className="font-semibold">Impact:</span> The recognized union’s involvement in dispute resolution ensures that workers' rights are defended in a legal setting. They can bring cases to labour courts, participate in industrial tribunals, and seek enforcement of collective agreements.</li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-2xl font-semibold text-gray-800">Non-Recognized Union</h3>
                            <ul className="list-disc list-inside ml-6 mt-2 text-justify text-gray-700">
                                <li><span className="font-semibold">Participation:</span> Non-recognized unions may participate in dispute resolution, but their role is typically more limited. They may be involved in informal negotiations or act as a support group for their members, but they generally do not have the same standing in formal legal proceedings.</li>
                                <li><span className="font-semibold">Impact:</span> The impact of non-recognized unions in dispute resolution is often less significant. They may struggle to have their concerns heard in a formal setting and may rely on informal channels or public pressure to achieve their goals.</li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* Section 4: Influence and Power */}
                <div className="mb-16">
                    <h2 className="text-3xl font-bold text-indigo-600 mb-6">Influence and Power</h2>
                    <div className="space-y-8">
                        <div>
                            <h3 className="text-2xl font-semibold text-gray-800">Recognized Union</h3>
                            <ul className="list-disc list-inside ml-6 mt-2 text-justify text-gray-700">
                                <li><span className="font-semibold text-justify">Influence:</span> Recognized unions wield significant influence within the workplace. Their official status gives them a seat at the table in decision-making processes, and they are often consulted on matters affecting the workforce.</li>
                                <li><span className="font-semibold text-justify">Power:</span> The power of a recognized union comes from its legal recognition and its ability to mobilize the workforce. This includes the power to call strikes, negotiate legally binding agreements, and represent workers in disputes.</li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-2xl font-semibold text-gray-800">Non-Recognized Union</h3>
                            <ul className="list-disc list-inside ml-6 text-justify mt-2 text-gray-700">
                                <li><span className="font-semibold text-justify">Influence:</span> Non-recognized unions have less formal influence, but they may still be able to exert pressure through informal channels, such as organizing protests or lobbying for support from external organizations or the public.</li>
                                <li><span className="font-semibold text-justify">Power:</span> The power of a non-recognized union is often limited to its ability to organize and advocate for its members. Without formal recognition, it may lack the ability to enforce its demands or to compel the employer to negotiate.</li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* Section 5: Relationship with the Employer */}
                <div className="mb-16">
                    <h2 className="text-3xl font-bold text-indigo-600 mb-6">Relationship with the Employer</h2>
                    <div className="space-y-8">
                        <div>
                            <h3 className="text-2xl font-semibold text-gray-800">Recognized Union</h3>
                            <ul className="list-disc list-inside ml-6 mt-2 text-justify text-gray-700">
                                <li><span className="font-semibold">Relationship:</span> Recognized unions typically have a formal and structured relationship with the employer. This relationship is often governed by collective agreements that outline the terms of engagement, including dispute resolution mechanisms, negotiation procedures, and channels of communication.</li>
                                <li><span className="font-semibold">Collaborative Efforts:</span> Recognized unions often collaborate with employers on various initiatives, such as workplace safety programs, employee development, and policy formulation, to ensure the welfare of the workforce.</li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-2xl font-semibold text-gray-800">Non-Recognized Union</h3>
                            <ul className="list-disc list-inside ml-6 text-justify mt-2 text-gray-700">
                                <li><span className="font-semibold text-justify">Relationship:</span> Non-recognized unions may have an adversarial or distant relationship with the employer. Without formal recognition, their interactions may be limited to informal meetings or public actions, and they may struggle to gain the same level of attention from management.</li>
                                <li><span className="font-semibold text-justify">Challenges:</span> The lack of formal recognition can make it difficult for non-recognized unions to establish a productive relationship with the employer. They may have to rely on external pressure or legal action to achieve their objectives.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <h2 className="text-3xl font-bold text-indigo-600 mb-1 text-justify">Conclusion</h2>

            <p className='text-justify p-6 mx-4'>Recognized unions are officially acknowledged representatives of the workforce, with legal authority to negotiate, sign binding agreements, and participate in dispute resolution processes. They play a central role in labour relations and have significant influence over workplace conditions. Non-recognized unions, while they may still represent a portion of the workforce, lack formal recognition and the corresponding legal rights and powers. Their influence is more limited, and they often operate in an informal or adversarial capacity.
</p>


            <p className='text-center text-xl p-6 mx-4'>Currently, BKNK SANGH is the nationally recognised union who represents the workforce of FCI. Except for a single occasion, on each occasion, BKNK Sangh has remained the nationally recognised union of FCI since 1977.
</p>
            </div>
        </div>
    );
};

export default StatusOfRecognition;
