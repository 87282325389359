import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';

import 'tailwindcss/tailwind.css';
import bg1_section from '../R.jpeg';
import pdfIcon from '../pdf.jfif';
import Meeting_big1 from './Meeting_big1';
import pdfIcon1 from '../logo.png';
import Meeting from './Meeting';


const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const Meeting_big_big = () => {
  const [meetings, setMeetings] = useState([]);
  
  useEffect(() => {
    fetchMeetings();
  }, []);

  const fetchMeetings = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/meetings`);
      const sortedBulletins = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setMeetings(sortedBulletins);
    } catch (error) {
      console.error('Error fetching meetings data', error);
    }
  };
 return (
  <>      
  <Meeting/>
      {/* <div className="container mx-auto  bg-transparent  py-4 pt-6 pb-6 pr-4 border"  >
      
        <div className="  bg-green-800 mx-auto text-center">
    <motion.h1
            initial={{ opacity: 0, x: -150, y: 0 }}
            whileInView={{ opacity: 1, x: 0 }}
            whileHover={{ x: -20 }}
            transition={{ duration: 1 }}
            className= "text-5xl   font-extrabold font-serif mb-1 pt-6 text-white text-opacity-1 uppercase tracking-widest shadow-lg"
            >

        Meetings

      </motion.h1>


      <div className="flex  bg-transparent justify-center mt-1">
        
        <svg className="w-24 h-8 text-emerald-500" fill="none" stroke="currentColor" viewBox="0 0 100 24" xmlns="http://www.w3.org/2000/svg">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 12h98M50 1l7 7-7 7-7-7 7-7" />
  </svg>
      </div>
    </div>
    <section className="relative py-20 bg-gradient-to-r ">
    <br/><br/>
    <div className="absolute  inset-0 bg-cover  bg-center  " style={{ backgroundImage: `url('${bg1_section}')` }}></div>
    
    
  </section>
        <div>
        <Meeting_big1 meetings={meetings} pdfIcon={pdfIcon} pdfIcon1={pdfIcon1} />
    </div>
    </div> */}
  </>
  );
};

export default Meeting_big_big;
