// src/components/TeamSlider.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import { motion } from 'framer-motion';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const TeamSlider = () => {
  const [members, setMembers] = useState([]);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const res = await axios.get(`${apiBaseUrl}/bknksteam`);
        setMembers(res.data);
        //alert('ok')
      } catch (err) {
        console.error(err);
      }
    };
    fetchMembers();
  }, []);

  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,

    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className="container mx-auto bg-transparent p-8">
      <h2 className="text-3xl bg-transparent font-bold mb-4 bg-gradient-to-r from-blue-500 via-indigo-500 to-purple-500  text-center text-white uppercase">Meet Our Team</h2>
      <Slider {...settings} className='bg-transparent'>
        {members.map((member) => (
          <motion.div key={member.id} whileHover={{ scale: 1.05 }} className="p-4 bg-transparent">
            <div className="bg-white bg-transparent rounded-lg  overflow-hidden ">
              <img
                src={`${apiBaseUrl.substring(0, apiBaseUrl.length - 4)}/uploads/${member.imageUrl}`}
                alt={member.name}
                className=" h-48 object-cover bg-transparent rounded-full mx-auto mt-4"
              />
              <div className="p-4 text-center bg-transparent">
                <h2 className="text-xl text-white font-normal mb-2 uppercase">{member.name}</h2>
                <p className="text-white capitalize">{member.position}</p>
              </div>
            </div>
          </motion.div>
        ))}
      </Slider>
    </div>
  );
};

export default TeamSlider;
