import React from 'react';

const PDFViewer = ({ url }) => {
  return (
    <div className="pdf-container" style={{ height: '100vh' }}>
      <embed src={url} type="application/pdf" width="100%" height="100%" />
    </div>
  );
};

export default PDFViewer;
