// src/components/UploadForm.js
import React, { useState } from 'react';
import axios from 'axios';
import TeamMemberTable from './TeamMemberTable';
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const TeamUploadForm = () => {
  const [name, setName] = useState('');
  const [position, setPosition] = useState('');
  const [image, setImage] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', name);
    formData.append('position', position);
    formData.append('image', image);
    
    try {
      const res = await axios.post(`${apiBaseUrl}/bknksteam`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      alert('ok2')
      console.log(res.data);
      
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="App header min-h-screen -mt-12 dark:bg-gray-900 transition-colors duration-500 bg-white" style={{ backgroundImage: `url(${'/OIP2.jfif'})`, backgroundSize: 'cover' }}>
    &nbsp;
    <div className=" mt-32 mb-2 glass max-w-4xl mx-auto p-4 bg-white shadow-md rounded-md">
   <h1 align='center'>Team Member Upload Form</h1>
    <form onSubmit={handleSubmit} className="max-w-md mx-auto p-4 shadow-md rounded-lg">
      <div className="mb-4">
        <label className="block text-gray-700">Name</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Position</label>
        <input
          type="text"
          value={position}
          onChange={(e) => setPosition(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Image</label>
        <input
          type="file"
          onChange={(e) => setImage(e.target.files[0])}
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>
      <button type="submit" className="w-full p-2 bg-blue-500 text-white rounded">
        Upload
      </button>
    </form></div>
    
    <TeamMemberTable/>
    
    </div>
  );
};

export default TeamUploadForm;
