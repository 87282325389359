import React, { useContext } from 'react';
import { AuthContext } from './AuthContext';

const AdminPanel = () => {
  const { user } = useContext(AuthContext);

  if (!user || user.adminflag !== 1) {
    return <div>Access Denied</div>;
  }

  return (
    <div>
      {/* <h1>Admin Panel</h1>
      <p>Welcome, Admin : {user.name}</p> */}
    </div>
  );
};

export default AdminPanel;
