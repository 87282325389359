import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

import { toast } from 'react-toastify';
import Login from './Login';
import Home from './Home';
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export const AuthContext = createContext();



export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/current-user`);
        setUser(response.data);
      } catch (error) {
        setUser(null);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  useEffect(() => {
    // Get user data from localStorage
    const savedUser = localStorage.getItem('user');
  
    // Check if savedUser is not null, undefined, or the string "undefined" before parsing
    if (savedUser && savedUser !== "undefined") {
      try {
        setUser(JSON.parse(savedUser));
      } catch (error) {
        console.error("Failed to parse user data from localStorage", error);
        localStorage.removeItem('user'); // Remove invalid data to avoid future errors
      }
    }
  }, []);

  const login = async (email, password) => {
    try {
      const response = await axios.post(`${apiBaseUrl}/login`, { email, password },
     { withCredentials: true }
      
      );
          //const token1 = response.data.token;

      //setUser(response.data);
      setUser(response.data.user); // Update the state with the logged-in user
      localStorage.setItem('user', JSON.stringify(response.data.user)); // Optionally save to localStorage for persistence
      //localStorage.setItem('token1', token1);

      //alert(response.data.message);
    } catch (error) {
      throw error;
    }
  };

  const logout = () => {

    setUser(null);
    axios.post(`${apiBaseUrl}/logout`);
    localStorage.clear();

    toast.info('Logged out successfully!', {
      position: 'top-right', // Corrected position string
      
      autoClose: 3000,
    });
    
    <Home/>

  };

  return (
    <AuthContext.Provider value={{ user, loading, login, logout }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};


// Custom Hook to Use the User Context
export function useUser() {
  return useContext(AuthContext);
}