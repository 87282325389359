import React, { useState, useEffect } from 'react';
import axios from 'axios';
import YouTubeSlider from './YouTubeSlider';
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

const YoutubeadminPage = () => {
  const [videoIds, setVideoIds] = useState([]);
  const [newVideoId, setNewVideoId] = useState('');

  useEffect(() => {
    fetchVideoIds();
  }, []);

  const fetchVideoIds = () => {
    axios.get(`${apiBaseUrl}/videos`)
      .then(response => {
        setVideoIds(response.data.map(video => video.video_id));
      })
      .catch(error => console.error('Error fetching video IDs:', error));
  };

  const addVideoId = () => {
    if (newVideoId) {
      axios.post(`${apiBaseUrl}/videos`, { videoId: newVideoId })
        .then(() => {
          fetchVideoIds(); // Refresh the video IDs list
          fetchVideoIds(); // Refresh the video IDs list
          setNewVideoId(''); // Clear the input field
        })
        .catch(error => console.error('Error adding video ID:', error));
    }
  };

  const deleteVideoId = (id) => {
    axios.delete(`${apiBaseUrl}/videos`, { data: { videoId: id } }) // Video ID sent in the request body
      .then(() => {
        fetchVideoIds(); // Refresh the video IDs list
          setNewVideoId(''); // Clear the input field
      })
      .catch(error => console.error('Error deleting video ID:', error));
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Video Management</h1>

      <div className="mb-4">
        <input
          type="text"
          className="border p-2 mr-2"
          placeholder="Enter YouTube Video ID"
          value={newVideoId}
          onChange={(e) => setNewVideoId(e.target.value)}
        />
        <button
          onClick={addVideoId}
          className="bg-blue-500 text-white px-4 py-2 rounded">
          Add Video
        </button>
      </div>

      <table className="min-w-full bg-white border">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">YouTube Video ID</th>
            <th className="py-2 px-4 border-b">Actions</th>
          </tr>
        </thead>
        <tbody>
          {videoIds.map((videoId) => (
            <tr key={videoId}>
              <td className="py-2 px-4 border-b">{videoId}</td>
              <td className="py-2 px-4 border-b">
                <button
                  onClick={() => deleteVideoId(videoId)}
                  className="bg-red-500 text-white px-4 py-1 rounded">
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <YouTubeSlider videoIds={videoIds} />
    </div>
  );
};

export default YoutubeadminPage;
