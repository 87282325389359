// src/components/MemberTable.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;


const TeamMemberTable = () => {
  const [members, setMembers] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [editingMember, setEditingMember] = useState(null);
  const [name, setName] = useState('');
  const [position, setPosition] = useState('');
  const [image, setImage] = useState(null);

  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [team, setTeam] = useState('');
  
  useEffect(() => {
    fetchMembers();
  }, []);

  const fetchMembers = async () => {
    try {
      const res = await axios.get(`${apiBaseUrl}/bknksteam`);
      setMembers(res.data);
      if (res.data.length > 0) {
        setHeaders(Object.keys(res.data[0]));
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleEditClick = (member) => {
    setEditingMember(member);
    setName(member.name);
    setPosition(member.position);
  };

  const handleDeleteClick = async (id) => {
    try {
      await axios.delete(`${apiBaseUrl}/bknksteam/${id}`);
      fetchMembers();
    } catch (err) {
      console.error(err);
    }
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', name);
    formData.append('position', position);
    formData.append('mobile', mobile);
    formData.append('email', email);
    if (image) formData.append('image', image);

    try {
      await axios.put(`${apiBaseUrl}/bknksteam/${editingMember.id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setEditingMember(null);
      setName('');
      setPosition('');
      setMobile('');
      setEmail('');
      setImage(null);
      fetchMembers();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Manage Team Members</h2>
      <table className="min-w-full bg-white">
        <thead>
        <tr>
            {headers.map((header) => (
              <th key={header} className="py-2 px-4 border-b border-gray-200 capitalize">
                {header}
              </th>
            ))}
            <th className="py-2 px-4 border-b border-gray-200">Actions</th>
          </tr>
        </thead>
        <tbody>
          {members.map((member) => (
            <tr key={member.id}>
                 {headers.map((header) => (
                    <td key={header} className="py-2 px-4 border-b border-gray-200">
                        <input
              type="text"
              value={member[header]}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
            />
                    
                  </td>))}
              {/* <td className="py-2 px-4 border-b border-gray-200">{member.name}</td>
              <td className="py-2 px-4 border-b border-gray-200">{member.position}</td> */}
              <td className="py-2 px-4 border-b border-gray-200">
                <button
                  onClick={() => handleEditClick(member)}
                  className="bg-blue-500 text-white px-2 py-1 rounded mr-2"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDeleteClick(member.id)}
                  className="bg-red-500 text-white px-2 py-1 rounded"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {editingMember && (
        <form onSubmit={handleUpdateSubmit} className="mt-4 p-4 shadow-md rounded-lg">
          <div className="mb-4">
            <label className="block text-gray-700">Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Position</label>
            <input
              type="text"
              value={position}
              onChange={(e) => setPosition(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Mobile</label>
            <input
              type="text"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Email</label>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Image</label>
            <input
              type="file"
              onChange={(e) => setImage(e.target.files[0])}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <button type="submit" className="w-full p-2 bg-green-500 text-white rounded">
            Update
          </button>
        </form>
      )}
    </div>
  );
};

export default TeamMemberTable;
