import React from 'react';
import VeteranSlider from './VeteranSlider';

const VeteranPage = () => {
  return (
    <div className="veteran-page container mx-auto  mb-2 bg-transparent ">
        <br/><br/><br/><br/><br/>
      <VeteranSlider />
      <div className="container mx-auto p-4">
        <h1 className="text-4xl font-bold mb-4 text-blue-700 uppercase underline">Veterans</h1>
        <p className="text-lg mb-6 text-gray-800 text-justify">
          Welcome to our veteran page. Here, We honor the brave men and women who have served our BKNK Sangh with courage and dedication.
        </p>
        {/* Add more content about veterans here */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div className="bg-white p-6 shadow-md rounded-lg">
            <h2 className="text-2xl font-semibold mb-2"> </h2>
            <p className="text-gray-700"></p>
          </div>
          <div className="bg-white p-6 shadow-md rounded-lg">
            <h2 className="text-2xl font-semibold mb-2"></h2>
            <p className="text-gray-700"></p>
          </div>
          <div className="bg-white p-6 shadow-md rounded-lg">
            <h2 className="text-2xl font-semibold mb-2"> </h2>
            <p className="text-gray-700">in progress...</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VeteranPage;
