import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'tailwindcss/tailwind.css';
import { motion } from 'framer-motion';
import bg1_section from '../R.jpeg';
import { Link } from 'react-router-dom';


const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const Meeting = () => {
  const [meetings, setMeetings] = useState([]);
  
  useEffect(() => {
    fetchMeetings();
  }, []);

  const fetchMeetings = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/meetings`);
      const sortedBulletins = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setMeetings(sortedBulletins);
    } catch (error) {
      console.error('Error fetching meetings data', error);
    }
  };

  
 

  return (
     <div className="container mx-auto  glass py-4 pt-6 pb-6 pr-4"  >
      {/* <h4 className='text-white text-sm'>Table View</h4> */}
      {/* <div className="container mx-auto   bg-center glass"  > */}
      {/* <motion.h1
          initial={{ opacity: 0, x: -100, y: 0 }}
          whileInView={{ opacity: 1, x: 0 }}
          whileHover={{ x: -10 }}
          transition={{ duration: 1 }}
          className="space-y-2"
        >
         <h1 className="text-4xl font-extrabold font-serif mb-2 top-1 pt-1 py-2 text-center text-white uppercase"  >Meetings </h1>

       </motion.h1> */}

<section className="relative py-8 bg-gradient-to-r ">
  <div className="absolute bg-transparent inset-0 bg-cover  bg-center opacity-20" style={{ backgroundImage: `url('${bg1_section}')` }}></div>
  
  <div className="relative bg-transparent  mx-auto text-center">
  <motion.h1
          initial={{ opacity: 0, x: -150, y: 0 }}
          whileInView={{ opacity: 1, x: 0 }}
          whileHover={{ x: -20 }}
          transition={{ duration: 1 }}
          className= "text-5xl bg-transparent  font-extrabold font-serif mb-4 pt-6 text-white uppercase tracking-widest shadow-lg"
          >

      Meetings

    </motion.h1>
    <div className="flex  bg-transparent justify-center mt-6">
      
      <svg className="w-24 h-8 text-emerald-500" fill="none" stroke="currentColor" viewBox="0 0 100 24" xmlns="http://www.w3.org/2000/svg">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 12h98M50 1l7 7-7 7-7-7 7-7" />
</svg>
{/* 

<svg className="w-20 h-20 text-emerald-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 5v.01M5.05 6.05a7.007 7.007 0 010 9.9M3.515 10.1a9.97 9.97 0 000 3.8M6.05 18.95a7.007 7.007 0 009.9 0M10.1 20.485a9.97 9.97 0 003.8 0M18.95 6.05a7.007 7.007 0 010 9.9M20.485 10.1a9.97 9.97 0 000 3.8"></path>
      </svg>

<svg className="w-20 h-20 rotate-1 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 17l9-14 9 14H3z" />
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 17V10h6v7" />
</svg>

<svg className="w-20 animate-spin rotate-1 h-20 text-white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <circle cx="12" cy="12" r="10" fill="none" stroke="currentColor" strokeWidth="2" />
  <circle cx="12" cy="12" r="6" fill="none" stroke="currentColor" strokeWidth="2" />
  <circle cx="12" cy="12" r="2" fill="currentColor" />
</svg>

<svg className="w-24 h-24 text-white rotate-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 2C13.6569 2 15 3.34315 15 5C15 6.65685 13.6569 8 12 8C10.3431 8 9 6.65685 9 5C9 3.34315 10.3431 2 12 2zM4 8C5.65685 8 7 9.34315 7 11C7 12.6569 5.65685 14 4 14C2.34315 14 1 12.6569 1 11C1 9.34315 2.34315 8 4 8zM12 16C13.6569 16 15 17.3431 15 19C15 20.6569 13.6569 22 12 22C10.3431 22 9 20.6569 9 19C9 17.3431 10.3431 16 12 16zM20 8C21.6569 8 23 9.34315 23 11C23 12.6569 21.6569 14 20 14C18.3431 14 17 12.6569 17 11C17 9.34315 18.3431 8 20 8z"/>
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8z"/>
</svg>
<svg className="w-20 h-20 text-white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path fill="currentColor" d="M2 12c0-4.418 3.582-8 8-8s8 3.582 8 8-3.582 8-8 8-8-3.582-8-8zm14 0c0 3.313-2.687 6-6 6s-6-2.687-6-6 2.687-6 6-6 6 2.687 6 6z"/>
  <path fill="currentColor" d="M16 12c0 2.209-1.791 4-4 4s-4-1.791-4-4 1.791-4 4-4 4 1.791 4 4z"/>
</svg>
<svg className="w-16 h-16 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 2l9 4.5v9L12 22l-9-4.5v-9L12 2z" />
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 7.5l9-4.5" />
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 7.5v9" />
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 16.5l9-4.5" />
</svg> */}
    </div>
  </div>
</section>
        {/* <caption class="caption-top text-white">
    <Link as={Link} to="/Meetings" className='text-white no-underline font-medium text-xs'>BKNKS&nbsp;/&nbsp;MEETINGS</Link>
  </caption> */}

      <div className="overflow-x-auto max-h-110 overflow-y-auto">
      <table className="min-w-full divide-y divide-gray-200  bg-white border text-balance table-auto ">
        <thead className=" sticky top-0 z-1 border-x-4 border-b-2 border-y-orange-400">
          <tr>
            <th scope="col" className="sticky top-0 px-6 py-3 text-left text-xs font-medium text-gray-800 uppercase tracking-wider bg-gray-50 w-10">Sl. No</th>
            <th scope="col" className="sticky top-0 px-6 py-3 text-left text-xs font-medium text-gray-800  bg-gray-50 w-32">Date</th>
            <th scope="col" className="sticky top-0 px-6 py-3 text-left text-xs font-medium text-gray-800 uppercase tracking-wider bg-gray-50 w-7/12">Meeting Name</th>
            <th scope="col" className="sticky top-0 px-6 py-3 text-left text-xs font-medium text-gray-800 uppercase tracking-wider bg-gray-50 w-3/12">Agenda Points</th>
            <th scope="col" className="sticky top-0 px-6 py-3 text-left text-xs font-medium text-gray-800 uppercase tracking-wider bg-gray-50 w-2/12">Minutes of Meeting</th>
          </tr>
        </thead>
        
        <tbody>
          {/* {meetings.map((meeting, index) => (
            // <tr  className='border-b-2 border-orange-500'>
                 <motion.tr key={meeting.id} className='border-b-2 border-x-4 border-y-green-400 border-orange-500' initial={{ opacity: 0, x: 0, y:0 }} whileInView={{ opacity: 1, x: 5 }}  whileHover={{x:7, y:-2}} transition={{ duration: 0.1 }} >
                 
                  <td className="-mx-2  py-2 px-4 border-b text-center ">{index + 1}</td>
              <td className="py-2 px-2 border-b text-center ">{meeting.date}</td>
              <td className="py-2 px-2 border-b text-left capitalize">{meeting.title}</td>
              <td className="py-2 px-2 border-b text-center">
                <a href={`${meeting.pdf_path}`} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                  View PDF
                </a>
              </td>
           </motion.tr>
          ))} */}



{meetings.map((meeting, index) => {
  // Ensure agenda and minutes are parsed as arrays
  let agenda = meeting.agenda || [];
  let minutes = meeting.minutes || [];

  // Parse if necessary (only if they are not arrays yet)
  if (typeof agenda === 'string') {
    try {
      agenda = JSON.parse(agenda);
    } catch (e) {
      console.error('Error parsing agenda:', e);
      agenda = [];
    }
  }

  if (typeof minutes === 'string') {
    try {
      minutes = JSON.parse(minutes);
    } catch (e) {
      console.error('Error parsing minutes:', e);
      minutes = [];
    }
  }

  return (
    <motion.tr key={meeting.id} className='border-x-4 border-y-green-400 border-orange-500 bg-gradient-to-br hover:bg-green-100 ' initial={{ opacity: 0, x: 0, y:0 }} whileInView={{ opacity: 1, x: 5 }}  whileHover={{x:7, y:-2}} transition={{ duration: 0.1 }} >
    <td className="py-2 px-4 border-b">{index + 1}</td>
      <td className="py-2 px-2 border-b w-44">{meeting.date}</td>
      <td className="py-2 px-4 border-b">              
      <ExpandableText text= {meeting.title}/></td>
      <td className="py-2 px-4 border-b">
        <ul className="list-disc hover:list-decimal text-green-800  hover:text-green-500 pl-5">
          {agenda.map((agendaItem, idx) => (
            <li key={idx}>
              {agendaItem.agenda_path && (
                <a
                  href={agendaItem.agenda_path}
                  target="_blank"
                  rel="noopener noreferrer"
                  className=" text-blue-500"
                >
              
              <ExpandableText text= {agendaItem.agenda_point}/>
                </a>
              )}
            </li>
          ))}
        </ul>
      </td>
      <td className="py-2 px-4 border-b">
        <ul className="list-disc hover:list-decimal text-green-800  hover:text-green-500 pl-5">
          {minutes.map((minutesItem, idx) => (
            <li key={idx}>
              {minutesItem.minutes_path && (
                <a
                  href={minutesItem.minutes_path}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500"
                >
                  <ExpandableText text= {minutesItem.minutes_text}/>
                 
                </a>
              )}
            </li>
          ))}
        </ul>
      </td>
      </motion.tr>
  );
})}










        </tbody>
      </table>
      <br/>
      <div className="text-center mt-4 mb-4">
          <Link to="/Meetings" className="bg-blue-500 text-white py-2 px-4 pb-2 pt-2 rounded">More</Link>
        </div>
      </div>
    </div>
  );
};
const ExpandableText = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 100; // Set a limit for the text length before truncation

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  
  return (
    <div onClick={handleToggleExpand} className="cursor-pointer text-left text-wrap pr-4 capitalize">
      {isExpanded ? text : `${text.slice(0, maxLength+15)}${text.length > maxLength+15 ? '....' : ''}`}
      {text.length > maxLength+15 && (
        <span className="text-blue-500 ml-2">
          {isExpanded ? ' Less' : ' More'}
        </span>
      )}
    </div>
  );
};
export default Meeting;
