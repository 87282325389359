import React, { useState } from 'react';
import { motion } from 'framer-motion';

const Accordion = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="max-w-4xl mx-auto p-6 border ">
            <h2 className="text-3xl font-bold text-center mb-8 text-white mt-4 pt-4 pb-4 bg-blue-700">FCI At a Glance</h2>
            
            <div className="space-y-4">
                {accordionData.map((item, index) => (
                    <motion.div 
                        key={index}
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3, delay: index * 0.1 }}
                        className="border-b border-gray-200"
                    >
                        <button 
                            onClick={() => toggleAccordion(index)}
                            className="w-full text-left p-4 text-xl font-semibold bg-gray-100 hover:bg-gray-200 rounded-md"
                        >
                            {item.title}
                        </button>
                        <div 
                            className={`overflow-hidden overflow-x-auto overflow-y-auto transition-all duration-500 ${activeIndex === index ? 'max-h-screen' : 'max-h-0'}`}
                        >
                            <div className="p-4 text-justify container font-serif">
                                {item.content}
                            </div>
                        </div>
                    </motion.div>
                ))}
            </div>
        </div>
    );
};

const accordionData = [
    {
        title: "FCI At a Glance",
        content: (
            <p>
                The Food Corporation of India stands as a cornerstone of India's food security, playing a vital role in ensuring the availability, accessibility, and affordability of food grains across the nation. Established under the Food Corporations Act of 1964, FCI's primary mandate has been to execute the objectives of the National Food Policy, which include the efficient procurement, storage, and distribution of food grains, thus safeguarding the interests of farmers while ensuring food stability for consumers.
                <br/><br/>
                Currently, as of August-2024, Shri Ashok K K Meena holds the post of Chairman & Managing Director of the Food Corporation of India. He is a 1993 batch IAS Officer of the Odisha Cadre.
            </p>
        ),
    },
    {
        title: "STRUCTURAL ORGANISATION OF FCI",
        content: (
            <div>
                
                <p>The structural organization of the Food Corporation of India (FCI) is designed to manage its extensive operations across the country efficiently. The FCI, headquartered in New Delhi, is divided into the following 5 zones:</p>
                <ul className="list-disc list-inside mt-4">
                    <li>North</li>
                    <li>South</li>
                    <li>East</li>
                    <li>West</li>
                    <li>North-East</li>
                </ul>
                <p className="mt-2">
                    Each region has various divisions under which there are storage Godowns/Depots. FCI depots play a pivotal role in maintaining the food security chain. FCI has a vast network of own depots as well as hired depots operating throughout the country. There are 2,196 FCI Godowns in the country.
                </p>
                <p className="mt-2">
                The workforce strength at the Food Corporation of India (FCI) is a key component of its operational effectiveness. As of recent figures, FCI employs thousands of personnel across various levels, including administrative, technical, and operational roles. This diverse workforce is instrumental in managing the vast network of warehouses, depots, and procurement centers.
                
                </p>
                <p className="mt-2">
                The workforce strength at the Food Corporation of India (FCI) is a key component of its operational effectiveness. As of recent figures, FCI employs thousands of personnel across various levels, including administrative, technical, and operational roles. This diverse workforce is instrumental in managing the vast network of warehouses, depots, and procurement centers.
                The following table shows the category wise SS/MIP of FCI:<br/>Category wise SS/MIP as on 30.06.2024 in FCI:-
                <table className="table-auto w-full mt-1 text-green-700 font-bold text-center place-items-center border-collapse border border-gray-200">
                
                <thead>
                    <tr className="bg-green-200">
                        <th className="border border-gray-200 px-1 py-2 text-center">SlNo.</th>
                        <th className="border border-gray-200 px-2 py-2">Category</th>
                        <th className="border border-gray-200 px-2 py-2">Sanction Strength</th>
                        <th className="border border-gray-200 px-2 py-2">MIP</th>
                        <th className="border border-gray-200 px-2 py-2">Vacancy</th>
                    </tr>
                </thead>
                
                <tbody>
                    <tr>
                        <td className="border border-gray-200 px-1 py-2">1</td>
                        <td className="border border-gray-200 px-2 py-2">Cat-I</td>
                        <td className="border border-gray-200 px-2 py-2">1111</td>
                        <td className="border border-gray-200 px-2 py-2">980</td>
                        <td className="border border-gray-200 px-2 py-2">131</td>
                    </tr>
                    <tr>
                        <td className="border border-gray-200 px-1 py-2">2</td>
                        <td className="border border-gray-200 px-2 py-2">Cat-II</td>
                        <td className="border border-gray-200 px-2 py-2">6206</td>
                        <td className="border border-gray-200 px-2 py-2">5557</td>
                        <td className="border border-gray-200 px-2 py-2">649</td>
                    </tr>
                    <tr>
                        <td className="border border-gray-200 px-1 py-2">3</td>
                        <td className="border border-gray-200 px-2 py-2">Cat-III</td>
                        <td className="border border-gray-200 px-2 py-2">27330</td>
                        <td className="border border-gray-200 px-2 py-2">18877</td>
                        <td className="border border-gray-200 px-2 py-2">8453</td>
                    </tr>
                    <tr>
                        <td className="border border-gray-200 px-1 py-2">4</td>
                        <td className="border border-gray-200 px-2 py-2">Cat-IV</td>
                        <td className="border border-gray-200 px-2 py-2">7361</td>
                        <td className="border border-gray-200 px-2 py-2">1129</td>
                        <td className="border border-gray-200 px-2 py-2">6232</td>
                    </tr>
                    
                </tbody>
                <tfoot>
                   
                <tr>
                        <td className="border border-gray-200 px-2 py-2" colspan='2'>Total</td>
                        <td className="border border-gray-200 text-left py-2">42008</td>
                        <td className="border border-gray-200 text-left py-2">26543</td>
                        <td className="border border-gray-200 text-left py-2">15465</td>
                    </tr>
                </tfoot>
            </table>
                </p>
            </div>
        ),
    },
    {
        title: "Key Functions and Responsibilities",
        content: (
            <div>
                <p>FCI is responsible for:</p>
                <ul className="list-decimal list-inside mt-4">
                    <li>Procurement</li>
                    <li>Storage</li>
                    <li>Distribution</li>
                    <li>Buffer Stock Maintenance</li>
                    <li>Price Support Operations</li>
                </ul>
                <p className="mt-4">Each of these functions plays a critical role in ensuring India's food security.</p>
            </div>
        ),
    },
    {
        title: "Achievements and Contributions",
        content: (
            <div>
                
                <p className="mt-4">Over the decades, FCI has made significant contributions to India's self-sufficiency in food production. By bridging the gap between surplus and deficit regions, FCI has ensured equitable distribution of food grains, thereby playing a critical role in reducing hunger and poverty. Its interventions in the market have also been instrumental in maintaining price stability, benefiting both farmers and consumers.</p>
                <p className='mt-4'>FCI's initiatives in modernizing its storage facilities, improving logistics, and enhancing procurement processes have further strengthened India's food security framework. The organization's continuous efforts to adopt technology and innovation have led to increased efficiency and transparency in its operations, thereby contributing to the overall development of the agricultural sector.</p>
            </div>
        ),
    },
    {
        title: "Types of Procurement",
        content: (
            <div>
                
                <ul className="list-decimal list-inside mt-4">
                    <li>Centralized Procurement System
                    <p className='mt-4'>Under Centralized Procurement System, the procurement of foodgrains in Central Pool are undertaken either by FCI directly or State Government agencies procures the foodgrains and handover the stocks to FCI for storage and subsequently issue against GOI allocations in the same State or movement of surplus stocks to other States. The cost of the foodgrains procured by State agencies is reimbursed by FCI as soon as the stocks are delivered to FCI as per cost-sheets issued by GOI. States procuring under this type of procurement system include Chandigarh, Delhi, Haryana, J&K, Rajasthan, Uttar Pradesh for both Paddy /Rice and Wheat while Assam, Puducherry, Punjab procures Paddy/Rice under this system.</p></li>
                    <li>Decentralized Procurement System (DCP)
                    <p className='mt-2'>The scheme of Decentralized Procurement of foodgrains was introduced by the Government in 1997-98 with a view to enhancing the efficiency of procurement and PDS and encouraging local procurement to the maximum extent thereby extending the benefits of MSP to local farmers as well as to save on transit costs. This also enables procurement of foodgrains more suited to the local taste.</p>
                    <p className='mt-2'>Under this scheme, the State Government itself undertakes direct purchase of paddy/rice and wheat and also stores and distributes these foodgrains under NFSA and other welfare schemes. The Central Government undertakes to meet the entire expenditure incurred by the State Governments on the  procurement operations as per the approved costing. The Central Government also monitors the quality of foodgrains procured under the scheme and reviews the arrangements made to ensure that the procurement operations are carried smoothly.</p>
                    <br/><br/>
                    <p>Following states procure under DCP System of procurement.</p>
                    
                    <table className="table-auto w-full mt-1 text-green-700 font-bold text-center place-items-center border-collapse border border-gray-200">
                
                <thead>
                    <tr className="bg-green-200">
                        <th className="border border-gray-200 px-1 py-1 text-center">SlNo.</th>
                        <th className="border border-gray-200 px-4 py-2">DCP (Paddy/Rice)</th>
                        <th className="border border-gray-200 px-6 py-2">DCP(Wheat)</th>
                    </tr>
                </thead>
                
                <tbody>
                    <tr>
                        <td className="border border-gray-200 px-1 py-2">1</td>
                        <td className="border border-gray-200 px-2 py-2">A&N Islands</td>
                        <td className="border border-gray-200 px-2 py-2">Bihar</td>
                    </tr>
                    <tr>
                        <td className="border border-gray-200 px-1 py-2">2</td>
                        <td className="border border-gray-200 px-2 py-2">Andhra Pradesh</td>
                        <td className="border border-gray-200 px-2 py-2">Chhattisgarh</td>
                    </tr>
                    <tr>
                        <td className="border border-gray-200 px-1 py-2">3</td>
                        <td className="border border-gray-200 px-2 py-2">Bihar</td>
                        <td className="border border-gray-200 px-2 py-2">Gujarat</td>
                    </tr>
                    <tr>
                        <td className="border border-gray-200 px-1 py-2">4</td>
                        <td className="border border-gray-200 px-2 py-2">Chhattisgarh</td>
                        <td className="border border-gray-200 px-2 py-2">Himachal Pradesh</td>
                    </tr>
                    <tr>
                        <td className="border border-gray-200 px-1 py-2">5</td>
                        <td className="border border-gray-200 px-2 py-2">Gujarat</td>
                        <td className="border border-gray-200 px-2 py-2">Madhya Pradesh</td>
                    </tr>
                    <tr>
                        <td className="border border-gray-200 px-1 py-2">6</td>
                        <td className="border border-gray-200 px-2 py-2">Himachal Pradesh</td>
                        <td className="border border-gray-200 px-2 py-2">Maharashtra</td>
                    </tr>
                    <tr>
                        <td className="border border-gray-200 px-1 py-2">07</td>
                        <td className="border border-gray-200 px-2 py-2">Jharkhand</td>
                        <td className="border border-gray-200 px-2 py-2">Punjab</td>
                    </tr>
                    <tr>
                        <td className="border border-gray-200 px-1 py-2">08</td>
                        <td className="border border-gray-200 px-2 py-2">Karnataka</td>
                        <td className="border border-gray-200 px-2 py-2">Uttarakhand</td>
                    </tr>
                    <tr>
                        <td className="border border-gray-200 px-1 py-2">09</td>
                        <td className="border border-gray-200 px-2 py-2">Kerala </td>
                        <td className="border border-gray-200 px-2 py-2">West Bengal</td>
                    </tr>
                    
                    <tr>
                        <td className="border border-gray-200 px-1 py-2">10</td>
                        <td className="border border-gray-200 px-2 py-2">Madhya Pradesh</td>
                        <td className="border border-gray-200 px-2 py-2"></td>
                    </tr>

                    <tr>
                        <td className="border border-gray-200 px-1 py-2">11</td>
                        <td className="border border-gray-200 px-2 py-2">Maharashtra </td>
                        <td className="border border-gray-200 px-2 py-2"></td>
                    </tr>
                    <tr>
                        <td className="border border-gray-200 px-1 py-2">12</td>
                        <td className="border border-gray-200 px-2 py-2">Odisha </td>
                        <td className="border border-gray-200 px-2 py-2"></td>
                    </tr>

                    <tr>
                        <td className="border border-gray-200 px-1 py-2">13</td>
                        <td className="border border-gray-200 px-2 py-2">Tamil Nadu </td>
                        <td className="border border-gray-200 px-2 py-2"></td>
                    </tr>
                    <tr>
                        <td className="border border-gray-200 px-1 py-2">14</td>
                        <td className="border border-gray-200 px-2 py-2">Telangana </td>
                        <td className="border border-gray-200 px-2 py-2"></td>
                    </tr>
                    <tr>
                        <td className="border border-gray-200 px-1 py-2">15</td>
                        <td className="border border-gray-200 px-2 py-2">Tripura </td>
                        <td className="border border-gray-200 px-2 py-2"></td>
                    </tr>
                    <tr>
                        <td className="border border-gray-200 px-1 py-2">16</td>
                        <td className="border border-gray-200 px-2 py-2">Uttarakhand </td>
                        <td className="border border-gray-200 px-2 py-2"></td>
                    </tr>
                    
                    <tr>
                        <td className="border border-gray-200 px-1 py-2">17</td>
                        <td className="border border-gray-200 px-2 py-2">West Bengal </td>
                        <td className="border border-gray-200 px-2 py-2"></td>
                    </tr>
                </tbody>
               
            </table>
                    
                    
                    
                    
                    
                    
                    
                    
                    </li>
                </ul>
                <p className="mt-4">Each of these functions plays a critical role in ensuring India's food security.</p>
            </div>
        ),
        
    },
    {
        title: "Storage",
        content: (
            <div>
                
                <p className="mt-4">In organizations like the Food Corporation of India, the storage function is crucial due to the need to maintain a large inventory of food grains over extended periods.</p>
                <p className='mt-2'>Besides its own storage depots, FCI has hired storage capacities from Central Warehousing Corporation, State Warehousing Corporations, State Agencies and Private Parties for short term as well as for guaranteed period under Private Entrepreneurs Guarantee Scheme.</p>
                <p className='mt-2'>As of 01.04.2024 , FCI has storage capacity of 371.37 Lakh Metric Tonne(LMT) & 426.62 LMT with state agencies , thus summing upto a total of 797.77 LMT.</p>
            </div>
        ),
    },
    {
        title: "ALLOCATION OF FOOD GRAINS BY GOI",
        content: (
            <div>
                
                <p className="mt-4">GOI allocate food grains to States/UTs under Targeted Public Distribution System (TPDS) and Other Welfare Schemes (OWS) and maintains their offtake record. A brief of various schemes handled by BP Division is as under: </p>
                <ul className='list-decimal list-inside mt-4'>
                    <li><span className=''>Allocation Under NFSA(National Food Security Act)</span>                   

                    <p className='mt-2'>As on 1st November, 2016, NFSA has been implemented in all the 36 States/UTs and they are receiving monthly allocation of foodgrains as per their entitlement under NFSA.</p>
                    <p className='mt-2 text-justify'>With a view to make receipt of foodgrains under TPDS a legal right; Government of India enacted National Food Security Act which came into force w.e.f. 5-7-2013. The Act provides for coverage of upto 75% of the rural population and upto 50% of the urban population for receiving subsidized foodgrains under Targeted Public Distribution System (TPDS), thus covering about two-third population of the country. The eligible persons identified by the States/UTs under Priority House Holds (PHH) Category are entitled to receive 5 kgs of foodgrains per person per month. The existing Antyodaya Anna Yojana (AAY) households, which constitute the poorest of the poor, receives 35 kgs of foodgrains per household per month.</p>
                    <p className='mt-2 text-justify'>The Central Government, to remove the financial burden of the poor beneficiaries and to ensure nationwide uniformity and effective implementation of NFSA, had decided to provide food grains free of cost to the beneficiaries under AAY households (@35 Kg of food grains per family per month) and PHH beneficiaries (@5Kg of food grains per person per month) covered under NFSA, 2013, beginning from 1st Jan 2023.</p>
                    <p className='mt-2 text-justify'>Keeping in view welfare of the beneficiaries in terms of accessibility, affordability and availability of food grains for the poor and to maintain uniformity across the States, the Government has decided to continue to provide free food grains under the PMGKAY for a period of five years with effect from 1st January, 2024.</p>

                    </li>
                    <li>Allocation Under Other Welfare Scheme (OWS)
                        <p className='mt-2 underline'>PM POSHAN (erstwhile Mid-Day Meal Scheme)</p>
                        <p className='mt-2'>PM POSHAN is implemented by Department of School Education & Literacy(DoSE&L), Ministry of Education. Department of Food and Public Distribution allocates foodgrains under PM POSHAN to DoSE&L on the basis of consolidated requirement received from various states. The state-wise sub-allocation is made by DoSE&L. The Central Issue Price for the scheme w.e.f. 1st April, 2016 is at NFSA rates i.e. Rs.3/2/1 per kg for rice/wheat/Coarse grain respectively. This Scheme covers students of Pre-School or Bal Vatika, Primary and Upper Primary classes aided by the Govt. and the Schools run by local bodies.</p>

                    </li>
                    <li>Wheat Based Nutrition Programme (WBNP)
                        <p className='mt-2 '>This Scheme is implemented by the Ministry of Women & Child Development (MoWCD). Department of Food and Public Distribution allocates foodgrains under Based Nutrition Programme to MoWCD on the basis of consolidated requirement received from various states. The state-wise sub-allocation is made by MoWCD. The Central Issue Price for the scheme w.e.f. 1st April, 2016 is at NFSA rates i.e. Rs.3/2/1 per kg for rice/wheat/Coarse grain respectively. The scheme intends to provide nutritious/ energy food to children in the age group of 0-6 years and expectant/lactating women. The food grains allotted under this Scheme are utilized by the States/UTs under the Umbrella Scheme - Integrated Child Development Scheme (ICDS).</p>

                    </li>
                    <li>Welfare Institutions and Hostels Scheme
                        <p className='mt-2 '>The Department of Food & Public Distribution administers the Welfare Institutions and Hostels Scheme and this Scheme was introduced on 1st September, 2017 by clubbing two schemes namely Welfare Institutions Scheme and SC/ST/OBC Hostels Scheme into a single scheme. Under the scheme, foodgrains are allocated to States/UTs at BPL rate to meet the requirement of Government owned and aided/sponsored welfare institutions such as beggar homes, Nariniketans and other similar welfare institutions and the students of the Government owned and aided/sponsored hostels having 2/3rd residents belonging to SC/ST/OBC category. The scale of issue of wheat and rice is as per the requirements of the residents, subject to a maximum of 15 kg per resident per month.</p>

                    </li>
                    <li>Annapurna Scheme
                        <p className='mt-2 '>This Scheme is implemented by the Ministry of Rural Development. Indigent senior citizens of 65 years of age or above who are not getting pension under the National Old Age Pension Scheme (NOAPS) are provided 10 kilograms of food grains per person per month free of cost under the scheme. Foodgrains under the Scheme are provided by Department of Food & Public Distribution at BPL rate.</p>

                    </li>
                    <li>Scheme for Adolescent Girls
                        <p className='mt-2 '>The Ministry of Women and Child Development (MoW&CD) launched SABLA scheme on 19.11.2010 by merging two schemes namely Nutrition Programme for Adolescent Girls (NPAG) & Kishori Shakti Yojana (KSY) into a single scheme. The Scheme aims at empowering adolescent girls of 11-18 years by improvement of their nutritional and health status and upgrading various skills like home skills, life skills and vocational skills. It also aims at equipping the girls on family welfare, health hygiene etc. and information and guidance on existing public services along with aiming to mainstream out of school girls into formal or non- formal education. Foodgrains for the scheme are allocated by Department of Food & Public Distribution at BPL rate to MoW&CD.</p>

                    </li>
                    <li>Allocation Under PMGKAY
                        <p className='mt-2 '>The Pradhan Mantri Garib Kalyan Anna Yojana (PMGKAY) had been launched with the specific purpose of ameliorating the hardships faced by the poor and needy due to economic disruptions caused by the COVID-19 outbreak in the country. The allocation of free foodgrains, under PMGKAY was in addition to normal allocation done under the National Food Security Act (NFSA), 2013. Phase VII of PMGKAY was in operation upto 31.12.2022. Total food grains allocation under PMGKAY-I to VII was about 1118 LMT & financial outlay planned was about Rs 3.91 lakh crore. </p>

                    </li>
                    <li>Allocation Under ANB
                        <p className='mt-2 '>Under Atma Nirbhar Bharat (ANB) Scheme, the GOI had allocated a total of about 8 LMT of additional food-grain covering all the States/UTs for migrants/stranded migrants, who are neither covered under National Food Security Act (NFSA) nor State Scheme PDS Cards, @ 5 kg per person per month free of cost for a period of two months (May & June, 2020). </p>

                    </li>
                </ul>
                
                <p className='mt-2'>As of 01.04.2024 , FCI has storage capacity of 371.37 Lakh Metric Tonne(LMT) & 426.62 LMT with state agencies , thus summing upto a total of 797.77 LMT.</p>
            </div>
        ),
    },
    {
        title: "Conclusion",
        content: (
            <div>
                
                <p className="mt-4 text-justify">The Food Corporation of India is more than just an organization; it is a lifeline that sustains the country’s food security. With its vast network, extensive experience, and unwavering commitment, FCI continues to play a pivotal role in ensuring that every Indian has access to safe, nutritious, and affordable food.</p>
            </div>
        ),
    },
];

export default Accordion;
