import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import v01 from '../veteran/1.jpeg';
import v2 from '../veteran/1 (2).jpeg';
import v3 from '../veteran/1 (3).jpeg';
import v4 from '../veteran/1 (4).jpeg';
import v5 from '../veteran/1 (5).jpeg';
import v6 from '../veteran/1 (6).jpeg';
// import v7 from '../veteran/1 (7).jpeg';

import v8 from '../veteran/1 (8).jpeg';

import v9 from '../veteran/1 (9).jpeg';
import v10 from '../veteran/1a.jpeg';
import v11 from '../veteran/2a.jpeg';

import v12 from '../veteran/3a.jpeg';
import v13 from '../veteran/4a.jpeg';
import v14 from '../veteran/5a.jpeg';
import v15 from '../veteran/6a.jpeg';


const VeteranSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };
  
  

  const veteranImages = [v01, v2, v3,v4,v5,v6,v8,v9,v10,v11,v12,v13,v14,v15];
  
  
  // const veteranImages = [
  //   '/public//veteran/1.jpeg',
  //   '/veteran/1 (2).jpeg',
  //   '/veteran/1 (3).jpeg',
  //   '/veteran/1 (4).jpeg',
  //   '/veteran/1 (5).jpeg',
  //   '/veteran/1 (6).jpeg',
  //   '/veteran/1 (7).jpeg',
  //   '/veteran/1 (8).jpeg',
  //   '/veteran/1 (9).jpeg',
  //   '/veteran/1 (11).jpeg',
  
  // ];
  return (
    <div className="container veteran-slider text-gray-800   mt-14">
      <Slider {...settings}>
        {veteranImages.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Veteran ${index + 1}`} className=" w-110 h-72 items-center justify-center content-center object-cover bg-transparent rounded-lg px-4"/>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default VeteranSlider;
